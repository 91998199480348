import { apmBase } from "@elastic/apm-rum";
import Toolkit from "@/utils/Toolkit";

/**
 * Ein Tool zur Typüberprüfung.
 */
const typeGuard = Toolkit.tool("typeGuard");

/**
 * Die verschiedenen Ausgabearten des Loggers.
 */
type LoggerOutputType = "error" | "info" | "log" | "table" | "warn";

/**
 * Ein Typ, der die Parameter für eine Fehlerausgabe definiert.
 */
type LoggerErrorParams = { message: unknown, error: unknown };

/**
 * Ein Typ, der die Parameter für eine Infoausgabe definiert.
 */
type LoggerInfoParams = { message: unknown, style?: string };

/**
 * Ein Typ, der die Parameter für eine Logausgabe definiert.
 */
type LoggerLogParams = { message: unknown, style?: string };

/**
 * Ein Typ, der die Parameter für eine Tabellenausgabe definiert.
 */
type LoggerTableParams = { message: unknown, tableData: object, style?: string };

/**
 * Ein Typ, der die Parameter für eine Warnausgabe definiert.
 */
type LoggerWarnParams = { message: unknown, style?: string };

/**
 * Ein Conditional Type, der die Parameter für eine Ausgabe definiert.
 */
type LoggerParams<T extends LoggerOutputType> =
    T extends "error" ? LoggerErrorParams :
    T extends "info" ? LoggerInfoParams :
    T extends "log" ? LoggerLogParams :
    T extends "table" ? LoggerTableParams :
    T extends "warn" ? LoggerWarnParams :
    never;

/**
 * Ein Type-Guard für den Ausgabetyp "error".
 *
 * @param log Der zu prüfende Parameter.
 */
const isErrorType = (log: LoggerParams<LoggerOutputType>): log is LoggerParams<"error"> => "error" in log;

/**
 * Ein Type-Guard für den Ausgabetyp "table".
 *
 * @param log Der zu prüfende Parameter.
 */
const isTableType = (log: LoggerParams<LoggerOutputType>): log is LoggerParams<"table"> => "tableData" in log;

/**
 * Ein Singleton-Logge zur Ausgabe von Nachrichten an die Konsole.
 *
 * HINWEIS: Fehler werden an den APM-Server gesendet, wenn die Anwendung nicht im Entwicklungsmodus läuft.
 */
class Logger {
    /**
     * Die Singleton-Instanz des Loggers.
     */
    private static _Instance: Logger;

    /**
     * Der Standard-Stil für die Ausgabe von Nachrichten an die Konsole.
     */
    private readonly _DefaultStyle: string = "color: #00aaff; font-weight: bold";

    /**
     * Die Instanz der @elastic/apm-rum Bibliothek.
     */
    private _Apm: typeof apmBase | undefined;

    /**
     * C'Tor
     *
     * @returns Die Singleton-Instanz des Loggers.
     */
    constructor() {
        if (Logger._Instance)
            return Logger._Instance;

        Logger._Instance = this;

        return Logger._Instance;
    }

    /**
     * Eine Methode zur Ausgabe eines Fehlers
     *
     * HINWEIS: Fehler werden nur an den APM-Server gesendet, wenn die Anwendung nicht im Entwicklungsmodus läuft.
     *
     * @param message Eine Entwicklerfreundliche Fehler-Nachricht.
     * @param error Ein Fehlerobjekt (geworfen durch eine try-catch-Anweisung).
     */
    public error(message: string, error?: unknown) {
        if (process.env.NODE_ENV !== "development" && (typeGuard.isError(error) || typeGuard.isString(error)))
            this._Apm?.captureError(error);

        this.flush("error", { message, error });
    }

    /**
     * Eine Methode zur Ausgabe Infos.
     *
     * @param message Eine Entwicklerfreundliche Info-Nachricht.
     * @param style Ein optionaler Stil der Info-Nachricht.
     */
    public info(message: unknown, style?: string) {
        this.flush("info", { message, style });
    }

    /**
     * Eine Methode zur Ausgabe einer einfachen Log-Nachricht.
     *
     * @param message Eine Entwicklerfreundliche Log-Nachricht.
     * @param style Ein optionaler Stil der Log-Nachricht.
     */
    public log(message: unknown, style?: string) {
        this.flush("log", { message, style });
    }

    /**
     * Eine Methode zur Ausgabe einer Tabelle.
     *
     * @param  message Eine Entwicklerfreundliche Tabellen-Nachricht.
     * @param tableData Ein Objekt oder Array der abzubildenden den Tabellen-Daten.
     * @param style Ein optionaler Stil der Tabellen-Nachricht.
     */
    public table(message: unknown, tableData: object, style?: string) {
        this.flush("table", { message, tableData, style });
    }

    /**
     * Eine Methode zum festhalten von Logger-Warnings.
     *
     * @param message Eine Entwicklerfreundliche Warn-Nachricht.
     * @param style Ein optionaler Stil der Warn-Nachricht.
     */
    public warn(message: unknown, style?: string) {
        this.flush("warn", { message, style })
    }

    /**
     * Setzt die Instanz der @elastic/apm-rum Bibliothek.
     *
     * @param apm Die Instanz der @elastic/apm-rum Bibliothek.
     */
    public setApm(apm: typeof apmBase) {
        this._Apm = apm;
    }

    /**
     * Delegiert die Nachricht an die Methode {@linkcode flushToConsole} oder {@linkcode flushToStack}.
     *
     * HINWEIS: Ausgaben jeglicher Art werden nur im Entwicklungsmodus ausgegeben.
     *          Fehler jedoch werden immer an den APM-Server gesendet.
     *
     * @param outputType Typ der auszugebenden Nachricht
     * @param log Die auszugebende Nachricht.
     */
    private flush<T extends LoggerOutputType>(outputType: T, log: LoggerParams<T>) {
        if (!isErrorType(log) && process.env.NODE_ENV !== "development")
            return;

        this.flushToConsole(outputType, log);
    }

    /**
     * Sendet die Nachricht an die Konsole.
     *
     * @param outputType Typ der auszugebenden Nachricht
     * @param log Die auszugebende Nachricht.
     */
    private flushToConsole<T extends LoggerOutputType>(outputType: T, log: LoggerParams<T>) {
        if (isErrorType(log))
            return console.error(log.message, log.error);

        if (isTableType(log)) {
            console.info(`%c${log.message}`, log.style ?? this._DefaultStyle);
            console.table(log.tableData);
        } else
            console[outputType](`%c${log.message}`, log.style ?? this._DefaultStyle);

        // Hier wird der Stack-Trace ohne die interne Implementierung angezeigt.
        try {
            throw new Error();
        } catch (error) {
            console.log((error as Error).stack?.split('\n')[4]);
        }
    }
};

/**
 * Die Singleton-Instanz des Loggers.
 */
const Instance = new Logger();

export default Instance;
