import Navigation from "./js/navigation";
import Scroller from "@/utils/scroll";
import Toolkit from "@/utils/Toolkit";

/**
 * Ein Tool zur Typüberprüfung.
 */
const typeGuard = Toolkit.tool("typeGuard");

type TActionFactoryParameters<T extends TActionName> = {
    /**
     * Die Referenzen auf die verfügbaren HTML-Elemente der DataView
     */
    $refs: KeyValuePair<{ $el: HTMLInputElement }[]>

    /**
     * Die auszuführende Aktion
     */
    action: TAction<T>

    /**
     * Der gemeinsame Cache für die Aktion
     */
    cache?: KeyValuePair<unknown>

    /**
     * Die Komponente, die den Ereignisbehandler verwendet
     */
    component?: Record<string, unknown>

    /**
     * Das Ereignis, das die Aktion ausgelöst hat
     */
    eventName: TEventName

    /**
     * Die Hooks, die von Aktionen verwendet werden können
     */
    hooks: TActionHooks

    /**
     * Die zuletzt ausgeführte Aktion
     */
    lastAction: TAction<TActionName>

    /**
     * Das Ergebnis der zuletzt ausgeführten Aktion
     */
    lastResult: unknown

    /**
     * Gibt an, ob die Aktionen protokolliert werden sollen
     */
    log: boolean

    /**
     * Die Navigations-Instanz der Tabelle
     */
    navigation: Navigation

    /**
     * Die Parameter der Aktion
     */
    params: KeyValuePair<unknown>

    /**
     * Die Daten der Zeilen der Tabelle
     */
    rows: Record<string, unknown>[]

    /**
     * Die Scroller-Instanz der Tabelle
     */
    scroller: Scroller

    /**
     * Ein Objekt, welches Informationen über die aktuelle Zeilenauwahl enthält
     */
    selected: TDataViewSelection

    /**
     * Der Store der DataView
     */
    store: KeyValuePair<unknown>

    /**
     * Die Plugins die von Aktionen verwendet werden können
     */
    plugins: TPlugins

    /**
     * Das Steuerelement, das die Aktion ausgelöst hat
     */
    trigger: TControl

    /**
     * Eine Funktion, die einen Mitgliedsnamen als Parameter erhält und einen unbekannten Wert zur Prüfung der Bedingungen zurückgibt
     */
    conditionValueResolver: TConditionValueResolver
}

/**
 * Eine Klasse zur Erzeugung von Aktionen.
 */
class ActionFactory {
    /**
     * Versucht die Aktion zu importieren und auszuführen.
     *
     * @param options.$refs Die Referenzen auf die verfügbaren HTML-Elemente der DataView
     * @param options.action Die auszuführende Aktion
     * @param options.cache Der gemeinsame Cache für die Aktion
     * @param options.conditionValueResolver Eine Funktion, die einen Mitgliedsnamen als Parameter erhält und einen unbekannten Wert zur Prüfung der Bedingungen zurückgibt
     * @param options.eventName Der Name des Ereignisses, das die Aktion ausgelöst hat
     * @param options.hooks Die Hooks, die von Aktionen verwendet werden können
     * @param options.lastAction Die zuletzt ausgeführte Aktion
     * @param options.lastResult Das Ergebnis der zuletzt ausgeführten Aktion
     * @param options.log Gibt an, ob die Aktionen protokolliert werden sollen
     * @param options.navigation Die Navigations-Instanz der Tabelle
     * @param options.params Die Parameter der Aktion
     * @param options.plugins Die Plugins die von Aktionen verwendet werden können
     * @param options.rows Die Daten der Zeilen der Tabelle
     * @param options.scroller Die Scroller-Instanz der Tabelle
     * @param options.selected Ein Objekt, welches Informationen über die aktuelle Zeilenauwahl enthält
     * @param options.store Der Store der DataView
     * @param options.trigger Das Steuerelement, das die Aktion ausgelöst hat
     *
     * @returns Das Ergebnis der Aktion
     */
    async execute<T extends TActionName>(options: TActionFactoryParameters<T>): Promise<unknown> {
        const actionName = `${options.action.$type.slice(0, 1).toUpperCase()}${options.action.$type.slice(1)}`;
        const ImportedObject = (await import(`./Actions/${actionName}.ts`)).default;
        const actionInstance = new ImportedObject(options);

        return typeGuard.isPromiseMethod(actionInstance, "execute")
            ? await actionInstance.execute()
            : actionInstance.execute();
    }
}

export default ActionFactory;
