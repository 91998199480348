import LinkedList from "@/utils/structures/LinkedList";
import Logger from "@/utils/Logger";
import MarkerUtils from "./utils/MarkerUtils";
import RemovalUtil from "./utils/RemovalUtil";
import ToolkitTool from "../../ToolkitTool";
import Toolkit from "../..";

export type SelectionDirection = "RIGHT_TO_LEFT" | "LEFT_TO_RIGHT";
export type CaretPosition = "BEFORE_ANCHOR" | "AFTER_ANCHOR" | null;

type SelectionLikeObject = {
    focusNode: Node
    focusOffset: number
    anchorNode: Node
    anchorOffset: number,
    commonAncestorContainer: Node
}

/**
 * Ein Type Guard, der angibt, ob die aktuelle {@link Selection} gültig ist.
 *
 * @param selection Die zu prüfende {@link Selection}
 *
 * @returns Der Wert, ob die {@link Selection} gültig ist
 */
const isValidSelection = (selection: Selection | null): selection is Selection & {
    anchorNode: Node
    focusNode: Node
    isCollapsed: false
    rangeCount: number
} => {
    return selection !== null
        && selection.rangeCount > 0
        && !selection.isCollapsed
        && selection.anchorNode !== null
        && selection.focusNode !== null;
};

class SelectionTool extends ToolkitTool {
    /**
     * Ermittelt die Cursorposition der {@link Selection} ausgehend vom Ankerknoten.
     *
     * @returns Die Cursorposition der {@link Selection} ausgehend vom Ankerknoten.
     */
    public get caretPositionFromAnchor(): CaretPosition | null {
        const selection = getSelection();
        if (!isValidSelection(selection)) return null;

        const direction = this.getSelectionDirection(selection);

        return direction === "LEFT_TO_RIGHT" ? "AFTER_ANCHOR" : "BEFORE_ANCHOR";
    }

    /**
     * Ändert die Auswahlrichtung.
     *
     * @param direction Die Auswahlrichtung.
     */
    public changeSelectionDirection(direction: "LEFT_TO_RIGHT" | "RIGHT_TO_LEFT") {
        const selection = getSelection();

        if (!isValidSelection(selection))
            return;

        // Prüfen, ob die Auswahl rückwärts ist
        const isBefore = this.caretPositionFromAnchor === "BEFORE_ANCHOR";

        // Die Auswahl ist bereits in der gewünschten Richtung
        if (direction === "LEFT_TO_RIGHT" && !isBefore)
            return;

        // Die Auswahl ist bereits in der gewünschten Richtung
        if (direction === "RIGHT_TO_LEFT" && isBefore)
            return;

        this.reverseSelection();
    }

    /**
     * Ermittelt den gemeinsamen Vorfahrenknoten der Anker- und Fokusknoten
     *
     * @param anchorNode Der Ankerknoten
     * @param focusNode Der Fokusknoten
     *
     * @returns Der gemeinsame Vorfahrenknoten
     */
    public commonAncestorContainer(anchorNode: Node, focusNode: Node): Node {
        // Spezialfall 1: Identische Knoten
        if (anchorNode === focusNode)
            return anchorNode;

        // Spezialfall 2: Ein Knoten enthält den anderen
        if (anchorNode.contains(focusNode)) return anchorNode;
        if (focusNode.contains(anchorNode)) return focusNode;

        let parent = anchorNode.parentNode;

        if (!parent) return document.body;

        // Solange der Vorfahrenknoten nicht den Fokusknoten enthält, wird der Vorfahrenknoten des Ankerknotens gesucht
        while (parent && !parent.contains(focusNode))
            parent = parent.parentNode;

        if (parent) return parent;

        return document.body;
    }

    /**
     * Ermittelt den Knoten und den Offset an der Koordinate {@link x} / {@link y}.
     *
     * @param x Die X-Koordinate
     * @param y Die Y-Koordinate
     * @param root Der Wurzelknoten, in dem sich das Element befinden soll
     *
     * @returns Der Knoten und Offset an der Koordinate {@link x} / {@link y} oder null, wenn kein Knoten ermittelt werden konnte
     */
    public getCaretPositionFromPoint(x: number, y: number, root: Node): { node: Node, offset: number } | null {
        let node: Node | null = document.elementFromPoint(x, y);

        if (!node) return null;
        if (!root.contains(node) && root !== node) return null;

        const domTool = Toolkit.tool("dom");
        // Ungültiger Knoten. Null zurückgeben
        if (!domTool.isValidNode(node)) return null;

        const typeGuard = Toolkit.tool("typeGuard");

        // Wenn der Knoten ein Void-Element ist, dann den Elternknoten und den Offset des Void-Elements zurückgeben
        if (domTool.isVoidElement(node) && node !== root)
            return {
                node: node.parentElement!,
                offset: Array.from(node.parentElement!.childNodes).indexOf(node as ChildNode)
            };

        // Wenn der Knoten das Wurzelelement ist, dann den Wurzelknoten und Offset 0 zurückgeben
        if (node === root)
            return {
                node: root,
                offset: 0
            };

            // Wenn der Knote ein HTML-Element ist und nicht das Wurzelelement ist, dann den Knoten finden, der in der X/Y-Koordinate liegt
        if (typeGuard.isHtmlElement(node) && node !== root) {
            const childNodeInfos = domTool
                .getNodes(node, NodeFilter.SHOW_TEXT)
                .toArray()
                .map(childNode => ({
                    node: childNode,
                    rect: domTool.nodeBounds(childNode)
                }));

            const targetNodeInfo = childNodeInfos.find(nodeInfo => {
                const validTop = nodeInfo.rect.top <= y && nodeInfo.rect.bottom >= y;
                const validLeft = nodeInfo.rect.left <= x && nodeInfo.rect.right >= x;

                return validTop && validLeft;
            });

            if (!targetNodeInfo) return null;

            node = targetNodeInfo.node;
        }

        // Wenn der Knoten ein Textknoten ist und der Textknoten leer ist, dann den Textknoten und Offset 0 zurückgeben
        if (typeGuard.isText(node) && (node.textContent?.length ?? 0) === 0)
            return {
                node: node,
                offset: 0
            };

        // Wenn der Knoten ein Textknoten ist und der Textknoten nicht leer ist, dann den Textknoten und den Offset der X-Koordinate zurückgeben
        if (typeGuard.isText(node) && (node.textContent?.length ?? 0) > 0) {
            const rect = domTool.nodeBounds(node);
            const relativeX = (x - rect.left) / rect.width;

            return {
                node,
                offset: Math.floor(relativeX * node.textContent!.length)
            };
        }

        return null;
    }

    /**
     * Liefert die Bounds aller Zeilen des editierbaren Elements.
     *
     * @param element Das Element, dessen Zeilenbounds ermittelt werden sollen
     *
     * @returns Die Bounds aller Zeilen
     */
    public getLineBounds(element: Element): DOMRect[] {
        if (!element.firstChild) return [];

        const selection = getSelection();
        if (!selection || selection.rangeCount === 0) return [];

        const clonedRange = selection.getRangeAt(0).cloneRange();
        const range = selection.getRangeAt(0);
        const lineBounds: DOMRect[] = [];

        range.setStart(element, 0);
        range.setEnd(element, 0);

        selection.removeAllRanges();
        selection.addRange(range);

        const boundsOfLine = (selection: Selection | null) => {
            if (!selection || selection.rangeCount === 0)
                return new DOMRect();

            selection.modify("extend", "forward", "lineboundary");
            const currentRange = selection.getRangeAt(0);

            let bounds = currentRange.getBoundingClientRect();

            if (bounds.width === 0 && bounds.height === 0) {
                const span = document.createElement("span");
                span.textContent = '\u200c';

                currentRange.startContainer.insertBefore(span, currentRange.startContainer.childNodes[currentRange.startOffset]);

                bounds = span.getBoundingClientRect();

                span.remove();
            }

            selection.collapseToEnd();
            selection.modify("move", "forward", "character");

            return bounds;
        }

        while (true) {
            const bounds = boundsOfLine(getSelection());

            if (lineBounds.length > 0 && bounds.top === lineBounds.at(-1)?.top)
                break;

            if (bounds.width === 0 && bounds.height === 0)
                break;

            lineBounds.push(bounds);
        }

        selection.removeAllRanges();
        selection.addRange(clonedRange);

        return lineBounds;
    }

    /**
     * Ermittelt den Endknoten der aktuellen Zeile
     *
     * @returns Der Endknoten der aktuellen Zeile oder null, wenn kein Endknoten ermittelt werden konnte
     */
    public getLineEnd() {
        const selection = window.getSelection();

        if (!selection || selection.rangeCount === 0) return null;

        const range = selection.getRangeAt(0);

        if (!range.startContainer || !range.endContainer) return null;

        // Range klonen, um die Selektion nach der Ermittlung des Zeilenanfangs wiederherzustellen
        const clonedRange = range.cloneRange();

        range.setEnd(range.endContainer, range.endOffset);
        selection.modify("move", "forward", "lineboundary");

        const endNode = selection.getRangeAt(0).endContainer;

        selection.removeAllRanges();
        selection.addRange(clonedRange);

        return endNode;
    }

    /**
     * Ermittelt den Startknoten der aktuellen Zeile
     *
     * @returns Der Startknoten der aktuellen Zeile oder null, wenn kein Startknoten ermittelt werden konnte
     */
    public getLineStart() {
        const selection = window.getSelection();

        if (!selection || selection.rangeCount === 0) return null;

        const range = selection.getRangeAt(0);

        if (!range.startContainer || !range.endContainer) return null;

        // Range klonen, um die Selektion nach der Ermittlung des Zeilenanfangs wiederherzustellen
        const clonedRange = range.cloneRange();

        range.setEnd(range.startContainer, range.startOffset);
        selection.modify("move", "backward", "lineboundary");

        const startNode = selection.getRangeAt(0).startContainer;

        selection.removeAllRanges();
        selection.addRange(clonedRange);

        return startNode;
    }

    /**
     * Ermittelt alle Knoten (tief) innerhalb einer Range, ohne sie zu klonen oder zu extrahieren
     *
     * @param range Die Range, deren Knoten ermittelt werden sollen
     * @param whatToShow Der NodeFilter, der angibt, welche Knoten ermittelt werden sollen (standardmäßig Elemente und Textknoten)
     *
     * @returns Array mit allen Knoten innerhalb der Range
     */
    public getNodesInRange(range: Range, whatToShow: number = NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT): LinkedList<Node> {
        const nodes = new LinkedList<Node>();
        const domTool = Toolkit.tool("dom");
        const commonAncestor = range.commonAncestorContainer;

        if (commonAncestor.nodeType === Node.TEXT_NODE)
            return nodes.append(commonAncestor);

        const nodesInRange = domTool.getNodes(commonAncestor, whatToShow, {
            acceptNode: (node) => range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT
        });

        return nodesInRange;
    }

    /**
     * Liefert die Richtung der übergebenen Selektion.
     *
     * @param selection Die Selektion, dessen Richtung ermittelt werden soll.
     *
     * @returns LEFT_TO_RIGHT, wenn die Selektion von links nach rechts verläuft, ansonsten RIGHT_TO_LEFT.
     */
    public getSelectionDirection(selection: Selection | SelectionLikeObject): SelectionDirection {
        if (!selection.anchorNode || !selection.focusNode) return "LEFT_TO_RIGHT";

        const { node: anchorNode, offset: anchorOffset } = this.getTargetAndOffset(selection.anchorNode, selection.anchorOffset);
        if (!anchorNode) return "LEFT_TO_RIGHT";

        const { node: focusNode, offset: focusOffset } = this.getTargetAndOffset(selection.focusNode, selection.focusOffset);
        if (!focusNode) return "LEFT_TO_RIGHT";

        if (anchorNode === focusNode)
            return anchorOffset > focusOffset ? "RIGHT_TO_LEFT" : "LEFT_TO_RIGHT";

        const commonAncestorContainer = selection.hasOwnProperty("commonAncestorContainer")
            ? (selection as SelectionLikeObject).commonAncestorContainer
            : (selection as Selection).getRangeAt(0).commonAncestorContainer;

        const domTool = Toolkit.tool("dom");
        const nodes = domTool.getNodes(commonAncestorContainer);

        return nodes.indexOf(anchorNode) <= nodes.indexOf(focusNode) ? "LEFT_TO_RIGHT" : "RIGHT_TO_LEFT";
    }

    /**
     * Ermittelt den letzten Offset eines Knotens
     *
     * @param node Der Knoten
     *
     * @returns Der letzte Offset des Knotens
     */
    private getLastOffsetOfNode(node: Node | ChildNode): number {
        if (node.nodeType === Node.TEXT_NODE)
            return node.textContent?.length ?? 0;

        return node.childNodes.length;
    }

    /**
     * Ermittelt den Knoten, der dem übergebenen Knoten und Offset entspricht.
     *
     * @param node Der Knoten
     * @param offset Der Offset
     *
     * @returns Der Knoten, der dem übergebenen Knoten und Offset entspricht
     */
    public getTargetAndOffset(node: Node, offset: number, constrain: Node = document.body): { node: Node | null, offset: number } {
        const domTool = Toolkit.tool("dom");

        let matchedNode: Node | null = node;
        let matchedOffset = offset;

        // Solange den nächsten Knoten ermitteln, bis wir einen Knoten gefunden haben, der ein valides DOM-Rechteck hat
        while (!domTool.isValidNode(matchedNode)) {
            matchedNode = matchedNode.nextSibling ?? matchedNode.parentElement?.nextSibling ?? null;
            matchedOffset = 0;

            if (matchedNode && matchedNode !== constrain)
                continue;

            return {
                node: null,
                offset: 0
            };
        }

        // Wenn der Knoten ein Textknoten ist, dann den Textknoten und den Offset zurückgeben
        if (matchedNode.nodeType === Node.TEXT_NODE)
            return {
                node: matchedNode,
                offset: matchedOffset
            };

        // Wenn der Knoten ein Elementknoten ist und Kindknoten hat, aber der Offset nicht existiert, dann den letzten Kindknoten und seinen letzen Offset zurückgeben
        if (matchedNode.nodeType === Node.ELEMENT_NODE && matchedNode.childNodes.length > 0 && !matchedNode.childNodes[matchedOffset])
            return this.getTargetAndOffset(matchedNode.lastChild!, this.getLastOffsetOfNode(matchedNode.lastChild!), constrain);

        // Wenn wir in einem Elementknoten sind und am Offset ein Kindknoten existiert, dann ist das der Zielknoten (Element oder Textknoten)
        if (matchedNode.nodeType === Node.ELEMENT_NODE && matchedNode.childNodes.length > 0 && matchedNode.childNodes[matchedOffset])
            return this.getTargetAndOffset(matchedNode.childNodes[matchedOffset], 0, constrain);

        return {
            node: matchedNode,
            offset: matchedOffset
        };
    }

    /**
     * Ermittelt den Knoten und Offset, der einem Knoten rechts von der aktuellen Position entspricht,
     * ähnlich wie selection.modify("move", "forward", "character"), aber durch DOM-Traversierung.
     *
     * @param node Der aktuelle Knoten
     * @param offset Der aktuelle Offset im Knoten
     * @param constrain Das Wurzelelement, über das nicht hinausgegangen werden soll
     *
     * @returns Der ermittelte Knoten und Offset
     */
    public nextFocus(node: Node, offset: number, constrain: Node = document.body): { node: Node | null, offset: number } {
        const domTool = Toolkit.tool("dom");

        // Wir sind in einem gültigen Textknoten und nicht am Ende - einfach Offset erhöhen
        if (domTool.isValidNode(node) && node.nodeType === Node.TEXT_NODE && offset < (node.textContent?.length ?? 0))
            return { node, offset: offset + 1 };

        // Alle Knoten im Wurzelelement ermitteln
        const nodes = domTool.getAllNodes(constrain);
        if (nodes.size === 0) return { node, offset };


        // Den Startknoten ermitteln
        const startNode = !node.childNodes[offset] && node !== constrain
            ? node
            : node.childNodes[offset];

        if (!startNode) return { node, offset };

        // Den nächsten Knoten in der Linked List ermitteln
        let linkedNode = nodes.findNode(n => n === startNode)?.next ?? null;
        if (!linkedNode) return { node, offset };

        // Solange den nächsten Knoten ermitteln, bis wir einen Knoten gefunden haben, der ein valides DOM-Rechteck hat
        while (!domTool.isValidNode(linkedNode.value) && linkedNode.next)
            linkedNode = linkedNode.next;

        if (!linkedNode) return { node, offset };

        return {
            node: linkedNode.value,
            offset: 0
        }
    }

    /**
     * Positioniert eine Range an einem Knoten
     *
     * @param node Der Knoten, an dem die Range positioniert werden soll
     */
    private positionRangeAtNode(node: Node, offset: number) {
        const range = new Range();

        if (node.nodeType === Node.ELEMENT_NODE && node.childNodes.length > 0) {
            range.setStart(node, offset);
            range.setEnd(node, offset);

            return range;
        }

        range.setStartBefore(node);
        range.setEndBefore(node);

        return range;
    }

    /**
     * Ermittelt den Knoten und Offset, der einem Knoten links von der aktuellen Position entspricht,
     * ähnlich wie selection.modify("move", "backward", "character"), aber durch DOM-Traversierung.
     *
     * @param node Der aktuelle Knoten
     * @param offset Der aktuelle Offset im Knoten
     * @param constrain Optional: Wurzelelement, über das nicht hinausgegangen werden soll
     *
     * @returns Der ermittelte Knoten und Offset
     */
    public previousFocus(node: Node, offset: number, constrain: Node = document.body): { node: Node | null, offset: number } {
        const domTool = Toolkit.tool("dom");

        // Wir sind in einem gültigen Textknoten und nicht am Ende - einfach Offset erhöhen
        if (domTool.isValidNode(node) && node.nodeType === Node.TEXT_NODE && offset > 0)
            return { node, offset: offset - 1 };

        // Alle Knoten im Wurzelelement ermitteln
        const nodes = domTool.getAllNodes(constrain);
        if (nodes.size === 0) return { node, offset };

        // Den Endknoten ermitteln
        const endNode = !node.childNodes[offset] && node !== constrain
            ? node
            : node.childNodes[offset];

        if (!endNode) return { node, offset };

        // Den vorherigen Knoten in der Linked List ermitteln
        let linkedNode = nodes.findNode(n => n === endNode)?.prev ?? null;
        if (!linkedNode) return { node, offset };

        // Solange den vorherigen Knoten ermitteln, bis wir einen Knoten gefunden haben, der ein valides DOM-Rechteck hat
        while (!domTool.isValidNode(linkedNode.value) && linkedNode.prev)
            linkedNode = linkedNode.prev;

        if (!linkedNode) return { node, offset };

        // Den letzten Offset des Knotens in der Hierarchie des Knotens ermitteln
        return domTool.lastChildAndOffset(linkedNode.value);
    }

    /**
     * Entfernt den Inhalt der {@link selection} aus dem DOM und platziert den Cursor an der Stelle,
     * an der der Inhalt entfernt wurde.
     *
     * @param selection Die {@link Selection}, deren Inhalt entfernt werden soll
     * @param root Der Wurzelknoten, in dem der Inhalt entfernt werden darf
     */
    public removeContent({selection, root = document.body, callback}: { selection: Selection, root?: HTMLElement, callback?: () => void }) {
        const macroTool = Toolkit.tool("macro");

        // Neue Range mit korrekten Makro-Anpassungen auf Selection anwenden
        const range = macroTool.prepareRangeForManipulation(selection);
        if (!range) return false;

        selection.removeAllRanges();
        selection.addRange(range);

        const markers = MarkerUtils.prepareContentRemoval();
        if (!markers) return;

        const domTool = Toolkit.tool("dom");
        const listTool = Toolkit.tool("list");
        const selectionTool = Toolkit.tool("selection");
        const typeGuard = Toolkit.tool("typeGuard");

        const removalUtil = new RemovalUtil(
            root,
            domTool,
            listTool,
            selectionTool,
            typeGuard
        );

        // DOM-Operationen in Schritte aufteilen
        removalUtil.deleteMarkedContent(markers);

        // Nach dem Löschen von Inhalten aufräumen
        removalUtil.cleanupAfterDeletion(markers);

        if (callback instanceof Function)
            try {
                callback();
            } catch (e) {
                Logger.error("Fehler in der Callback-Methode", e);
            }

        // Selektion wiederherstellen und aufräumen
        this.restoreSelectionAfterContentRemoval(markers.markerEnd, root);

        // Marker entfernen
        markers.markerStart.remove();
        markers.markerEnd.remove();
    }

    /**
     * Stellt die Selektion nach dem Entfernen von Inhalten wieder her
     *
     * @param markerEnd Der Endmarker, an dem die Selektion wiederhergestellt werden soll
     * @param root Der Wurzelknoten, in dem die Selektion wiederhergestellt werden soll
     */
    private restoreSelectionAfterContentRemoval(markerEnd: HTMLSpanElement, root: HTMLElement = document.body) {
        const selection = getSelection();
        if (!selection) return;

        const { node: focusableNode, offset: focusableOffset } = this.previousFocus(markerEnd, 0, root);
        if (!focusableNode) return;

        const range = focusableNode.nodeName === "BR" && root.lastElementChild === focusableNode
            ? this.positionRangeAtNode(root, Array.from(root.childNodes).indexOf(focusableNode as ChildNode))
            : this.positionRangeAtNode(focusableNode, focusableOffset);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    /**
     * Wechselt die Laufrichtung der aktuellen {@link Selection}
     */
    public reverseSelection() {
        const selection = getSelection();

        if (!isValidSelection(selection))
            return;

        const anchorNode = selection.anchorNode;
        const anchorOffset = selection.anchorOffset;

        selection.collapse(selection.focusNode, selection.focusOffset);
        selection.extend(anchorNode, anchorOffset);
    }

    /**
     * Führt eine temporäre Änderung an der aktuellen {@link Selection} durch,
     * ruft die übergebene Funktion auf und stellt die ursprüngliche {@link Selection} wieder her.
     *
     * @param params Die Parameter für die temporäre Änderung.
     * @param params.alter Die Art der Änderung.
     * @param params.direction Die Richtung der Änderung.
     * @param params.granularity Die Granularität der Änderung.
     * @param params.callback Die Funktion, die aufgerufen wird.
     */
    public async temporaryModify({ alter, direction, granularity, callback }: { alter: "move" | "extend", direction: "forward" | "backward", granularity: "character" | "word" | "sentence" | "line" | "paragraph" | "document", callback: (selection: Selection | null) => Promise<void> | void }) {
        const selection = getSelection();

        if (!selection || selection.rangeCount === 0)
            return callback(selection);

        const anchorNode = selection.anchorNode;
        const anchorOffset = selection.anchorOffset;
        const focusNode = selection.focusNode;
        const focusOffset = selection.focusOffset;

        if (!anchorNode || !focusNode)
            return callback(selection);

        selection.modify(alter, direction, granularity);

        const result = callback(selection);

        if (result instanceof Promise)
            await result;

        selection.collapse(anchorNode, anchorOffset);
        selection.extend(focusNode, focusOffset);
    }
}

export default SelectionTool;
