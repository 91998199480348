<template lang="pug">
//- Ein Tabellenelement (DataView),
    mit Sortierfunktion und Toolbar
.container
    transition(name="fade")
        .data-view(
            tabindex="0"
            ref="dataView"

            :id="'dataview' + componentId"
            :class="{'data-view--sortable': sortable}"

            @keyup.delete="deleteSelectedRows"
        )
            table.table__measurement(
                ref="tableMeasurement"
                tabindex="-1"
            )
                thead
                    tr
                        th
                            span
                tbody
                    tr
                        td
                            span

            transition(name="fade")
                Toolbar(
                    data-fixed-height="true"
                    ref="toolbar"

                    :parentId="componentId"
                    :cssGroup="mode === 'select' ? 'dialog' : ''"
                    :searchValue="searchValue"
                    :selected="selected.ids.length > 1 ? 'multiple' : (selected.ids.length > 0 ? 'single' : 'none')"
                    :controls="toolbar.left"
                    :cachable="mode !== 'select'"
                    :rightControls="toolbar.right"
                    :pasteTopic="layout.clipboard ? layout.clipboard.topic : null"

                    @setSearchValue="setSearchValue"
                    @setFilter="setFilter"
                    @clearFilter="clearFilter"
                    @btnClick="tbButtonClickEvent"
                )

            Pending(
                v-if="request.fetching || request.error.raised"

                :pending="request.fetching && !request.error.raised"
                :error="request.error.raised"
                :message="!request.error.raised ? request.message.fetching : request.message.error"
                :button="request.error.raised ? request.error.button : undefined"
            )

            .data-view__border(
                ref="container"
                key="container"

                :style="{height: getHeight(-36)}"
            )

            .data-view__background(
                :style="{height: getHeight(-36)}"
            )

            .table.sticky
                table(
                    v-show="!request.fetching"

                    ref="table"

                    :name="getApmName()"

                    cellpadding="0"
                    cellspacing="0"

                    v-touch:longtap.prevent="touchLongPress"
                    v-touch:tap.prevent="touchDoubleTap"
                )
                    thead(
                        ref="tableHead"
                    )
                        transition(name="fade")
                            tr
                                template(
                                    v-for="(column, index) in columns"
                                )
                                    th(
                                        v-if="column.visible === true"

                                        @click="sortColumn(column)"
                                    )
                                        span(
                                            :style="getHeaderStyle(columns, column, index)"
                                            :class="'--text-'+column.alignment"
                                        )
                                            span(
                                                v-if="column.alignment !== 'right' && getColumnHeader(column) !== ''"
                                            ) {{ getColumnHeader(column) }}

                                            span.order(
                                                :class="getColumnSortOrder(column)"

                                                v-if="column.header !== undefined"
                                            )
                                                Icon.asc(icon="asc")
                                                Icon.desc(icon="desc")

                                            span(
                                                v-if="column.alignment === 'right' && getColumnHeader(column) !== ''"
                                            ) {{ getColumnHeader(column) }}
                    tbody(
                        ref="tableBody"
                        tabindex="0"
                    )
                        template(
                            v-for="(row, rowIndex) in rows"
                        )
                            tr(
                                @dblclick="onEdit(row, e)"

                                :data-row="rowIndex"

                                :ref="'row-' + rowIndex"
                            )
                                template(
                                    v-for="(column, index) in columns"
                                )
                                    td(
                                        v-if="column.visible === true"

                                        :class="'--text-'+column.alignment"
                                    )
                                        template(v-if="column['template'] !== undefined")
                                            span(
                                                :class="getClassObject(column)"
                                                :style="getHeaderStyle(columns, column, index)"
                                            )
                                                Icon.icon-only(
                                                    v-if="column.template === 'Icon' && getRowContent(row, column)"

                                                    :icon="getRowContent(row, column)"
                                                    :class="'--text-'+column.alignment"
                                                )

                                                Button.toggle-button(
                                                    v-if="column.template === 'Button'"

                                                    :events="column.button.events"
                                                    :verifyAll="true"
                                                    :metaData="{...jsonFilters(), ...row}"
                                                    :type="'secondary'"
                                                    :shape="'round'"
                                                    :size="'small'"
                                                )

                                                span(
                                                    v-if="column.template !== 'Icon' && column.template !== 'Button'"

                                                    v-touch:tap="e => { isLinkColumn(column) ? onEditByTap(row, e) : null }"

                                                    @click="e => { isLinkColumn(column) ? onEdit(row) : null }"
                                                ) {{ getRowContent(row, column, "") }}

                                        template(v-else)
                                            span(
                                                :class="getClassObject(column)"
                                                :style="getHeaderStyle(columns, column, index)"
                                            )
                                                span(
                                                    v-touch:tap="e => { isLinkColumn(column) ? onEditByTap(row, e) : null }"

                                                    @click="e => { isLinkColumn(column) ? onEdit(row) : null }"
                                                ) {{ getRowContent(row, column) }}
                                        .tr-pending
            .data-view__total(v-if="showTotal") # {{ totalRows }}
</template>

<script>
import { conditionsFullfilled } from "@/utils/ConditionsFullfilled";
import { fetchRows } from "./js/request";
import { isEmptyWs } from "@/utils/string";
import { isTouchEvent } from "@/utils/browser";
import { nextTick } from "vue";
import { vueDomUpdated } from "@/utils/dom";
import _ from "lodash";
import $ from "@/utils/dom";
import ActionFactory from "./ActionFactory";
import Button from "@/components/UI/Button/Button.vue";
import CacheMixin from "@/mixins/mixin.cache";
import Clipboard from "@/utils/Clipboard";
import ClipboardMixin from "@/mixins/mixin.clipboard";
import ComponentMixin from "@/mixins/mixin.component";
import Constant from "@/utils/Constant";
import EventHandler from "@/utils/EventHandler";
import FilterModel from "@/utils/Filter/FilterModel";
import FilterUtils from "@/utils/Filter";
import Formatter from "@/utils/formatter";
import Icon from "@/components/UI/Icon.vue";
import Logger from "@/utils/Logger";
import MessageProvider from "@/utils/MessageProvider";
import Navigation from "./js/navigation";
import ParametersMixin from "@/mixins/mixin.parameters";
import Pending from "@/components/UI/Pending/Pending.vue";
import Scroller from "@/utils/scroll";
import Toolbar from "@/components/UI/Toolbar.vue";
import Urls from "@/utils/Urls";

/**
 * Führt einen Server-Request durch, welches durch ein Event einer Formularkomponente
 * ausgelöst wird.
 *
 * @param {Object} actionData Die zu übertragenden Daten.
 *
 * @returns {Object} Das Antwort-Objekt.
 */
const remote = async function (actionData) {
    let args = actionData.args;
    let data = actionData.data;
    let method = actionData.method.toLowerCase();
    let map = actionData.map;
    let url = actionData.url;
    let request = actionData.request;
    let params = "";
    let result = null;
    let response = {};

    _.each(args, (arg, key) => {
        if (_.get(data, arg, undefined) !== undefined)
            params += `${key}=${_.get(data, arg)}&`;
    });

    if (params.length > 0)
        params = `?${params}`.slice(0, params.length);

    if (method === "get")
        result = await request.remoteCall({
            proc: url,
            queryParams: params
        });
    else {
        result = await request.post({
            url: url,
            content: params
        });

        return result.data;
    }

    _.each(map, (val, key) => {
        response[key] = _.get(result, val, undefined);
    });

    return response;
};

/**
 * Holt eine Datenzeile (remote).
 *
 * @param {Number} id Die Id des abzuholenden Datensatzes.
 * @param {Any} defaultValue Der Standardrückgabewert.
 *
 * @returns {Object|Any} Der Datensatz bzw. der Standardrückgabewert
 */
async function fetchRow(id, defaultValue) {
    let options = {
        filter: `id == "${id}"`
    };

    let columns = this.config.columns;
    let url = this.parameters().resolve(Urls(this.services, this.$config).Data.getItems(), this.filters().json);

    if (columns && columns.length > 0)
        options.include = _(columns)
            .filter(column => typeof _.get(column, "member", undefined) !== "undefined")
            .map(column => column.member)
            .value();

    try {
        let response = await this.$request.post({
            url: url,
            content: options
        });

        return _.first(_.get(response, "data.matches", [defaultValue]));
    } catch (e) {
        Logger.error("Der Datensatz konnte nicht abgerufen werden:", e);
    }

    return defaultValue;
}

/**
 * @typedef {Object} DataDefinition
 *
 * @property {Array} columns Die Spaltendefinition
 * @property {Array} ordering Die voreingestellte Spaltensortierung
 */

/**
 * Ruft die Konfiguration (Spalten, Toolbar, etc.) einer DataView von einem Service-Endpunkts ab.
 *
 * @param {Boolean} [fromCache=true] Ein Wert, der angibt, ob die Antwort aus dem Cache genommen werden soll.
 *
 * @returns {Promise<DataDefinition>} Die Datendefinition.
 */
const fetchConfig = async function (fromCache) {
    fromCache = fromCache !== false;

    const filters = (this.filters().json ?? [])
        .filter(filter => filter.name !== "type")
        .map(filter => `${filter.name}=${filter.value}`).join("&");

    let viewType = _.get(this.layout, "viewType", "full");
    let url = `${this.parameters().resolve(Urls(this.services, this.$config).View.config())}${`?type=${viewType}${filters.length > 0 ? `&${filters}` : ""}`}`;

    try {
        let result = await this.$request.get({
            url: url,
            useCache: fromCache,
            abortController: this.request.abortController
        });

        return _.get(result, "data", []);
    } catch (e) {
        if (!this.$request.hasCancelled(e)) Logger.error(e);

        throw e;
    }
};

/**
 * Holt einen vollständigen Datensatz (mit allen Spalten) vom Endpunkt ab.
 *
 * @param {Object} row Die ausgewählte Zeile.
 *
 * @returns {Promise} Ein Response-Versprechen.
 */
const fetchCompleteRow = async function (row) {
    return new Promise((resolve, reject) => {
        (async () => {
            try {
                let result = await this.$request.get({
                    url: this.parameters().resolve(Urls(this.services, this.$config).Data.getItem(), FilterUtils.mapJson(row)),
                });

                resolve(_.get(result, "data", null));
            } catch (e) {
                reject(e);
            }
        })();
    });
};

/**
 * Erzeugt für die DataView die Skrollbar.
 *
 * @returns {Promise} Das aufzulösendes Versprechen.
 */
const createScrollbar = async function () {
    const th = this.$refs.tableMeasurement.querySelector("thead th");
    const thHeight = th ? th.getBoundingClientRect().height : 36;

    return new Promise(async resolve => {
        if (this.scrollInstance)
            return resolve();

        await $(this.$refs.dataView).mounted(10000, ".table.sticky");

        if (!this.$refs.dataView)
            return;

        this.scrollInstance = new Scroller({
            element: this.$refs.dataView.querySelector(".table.sticky"),
            height: this.getHeight(),

            bars: {
                top: thHeight,
                minSize: 50
            }
        })
            .mounted(() => resolve())
            .onBottom({
                distance: "85%",
                throttle: 20
            }, async () => {
                this.scrollDirection = 1;

                if (!this.request.fetching && this.page < this.totalPages) {
                    try {
                        await fetchRows.call(this, this.page + 1, true);
                    } catch (e) {
                        this.page -= 1;
                    }
                }
            })
            .resize(() => {
                // Besteht eine Zeilenauswahl?
                if (this.selected.index > -1) {
                    nextTick(() => {
                        // Ist die Zeile nicht im sichbaren Bereich?
                        if (!isRowInViewport.call(this, this.selected.index))
                            this.scrollToSelected();
                    });
                }
            });
    });
};

/**
 * Prüft, ob die aktuell ausgewählte Zeile im sichbaren Bereich der Tabelle ist.
 *
 * @param {Number} rowIndex Der Index der zu prüfenden Zeile.
 *
 * @returns {Boolen} Der Wert, ob die Zeile im sichtbaren Bereich ist.
 */
const isRowInViewport = function (rowIndex) {
    let $row = this.$refs[`row-${rowIndex}`] && this.$refs[`row-${rowIndex}`][0]
        ? this.$refs[`row-${rowIndex}`][0]
        : null;

    if (!$row)
        return true;

    let $viewport = this.$refs.container;
    let rowBoundings = $row.getBoundingClientRect();
    let viewBoundings = $viewport.getBoundingClientRect();

    return rowBoundings.top >= viewBoundings.top && rowBoundings.top + rowBoundings.height <= viewBoundings.top + viewBoundings.height;
};

/**
 * Emittiert den aktuellen Zeilenwert des members zur Elternkomponente.
 *
 * @param {Object} row Die betroffene Zeile.
 * @param {String} type Die Variablenzuordnung.
 */
const emitSelectionToParent = function (row, type) {
    let member = _.get(this.layout, "member", undefined);
    let value = _.get(row, member, undefined);

    this.$emit("selectedValue", { value: value, type: type });
};

/**
 * Ein Aktualisieren Button für die DataView.
 */
const btnRefresh = {
    $type: "button",
    hint: "Aktualisieren",
    icon: "refreshwhite",
    mainButton: true,
    events: [{
        type: "onClick",
        actions: [{
            $type: "refreshView",
            type: "RefreshViewAction"
        }]
    }]
};


/**
 * Die Vue-Komponente DataView (Tabelle)
 */
const DataView = {
    mixins: [ComponentMixin, CacheMixin, ParametersMixin, ClipboardMixin],
    emits: ["loaded", "error", "selectedValue", "apply"],

    components: {
        Icon,
        Pending,
        Button,
        Toolbar
    },

    data() {
        return {
            /**
             * Die Spalten der DataView.
             *
             * @var {Array<Object>} [columns=[]]
             */
            columns: [],

            /**
             * Stellt eine Methode zum verzögerten Abruf von Zeilen bereit.
             *
             * @property {Object} debounced
             */
            debounced: null,

            /**
             * Die Zeilen der DataView.
             *
             * @var {Array<Object>} [rows=[]]
             */
            rows: [],

            /**
             * Die Toolbar Elemente.
             *
             * @var {Object<Array<Object>>} [toolbar={left: [], right: [], disabled: false}]
             */
            toolbar: {
                left: [],
                right: []
            },

            /**
             * Der Ladezustand Spaltendefinitionen und Sortierung der DataView.
             *
             * @var {Boolean} [isPartialFetching=true]
             */
            isPartialFetching: false,

            /**
             * Die aktuelle Sortierung der DataView.
             *
             * @var {String<asc|desc>|null} [ordering=null]
             */
            ordering: null,

            /**
             * Die initiale Sortierung der DataView.
             *
             * @var {Array} [initialOrdering=[]]
             */
            initialOrdering: [],

            /**
             * Anzahl aller Zeilen.
             *
             * @var {Number} [totalRows=0]
             */
            totalRows: 0,

            /**
             * Die aktuelle Seite.
             *
             * @var {Number} [page=0]
             */
            page: 0,

            /**
             * Die Scrollbar-Instanz.
             *
             * @type {Scroller}
             */
            scrollInstance: null,

            /**
             * Stellt Parameter zur Auflösung von Platzhaltervariablen zur Verfügung.
             *
             * @var {Object} [params={}]
             */
            params: {},

            /**
             * Stellt die initiale Konfiguration (Spalten, Sortierung, Toolbarelemente)
             * zur Verfügung.
             *
             * @var {Object} [config={}]
             */
            config: {},

            /**
             * Stellt eine Instanz der DataView-Navigation zur Verfügung.
             */
            navigation: null,

            /**
             * Stellt die aktuelle Zeilenauswahl zur Verfügung.
             *
             * @type {TDataViewSelection} selected
             */
            selected: {
                row: null,
                index: -1,
                ids: []
            },

            /**
             * Stellt Informationen des letzten Touch-Ereignisses im Rahmen einer Zeilenauswahl
             * (doubleTap) bereit.
             *
             * @property {{index: Number, lastTouch: Date}}
             */
            touch: {
                index: -1,
                lastTouch: 0
            }
        };
    },

    props: {
        /**
         * Die Layoutinformationen.
         *
         * @var {Object} [layout=null]
         */
        layout: {
            type: Object,
            default: () => { return {}; }
        },

        /**
         * Ein Parameter zum Anzeigen der DataView.
         *
         * @var {Boolean} [propActive=false]
         */
        propActive: {
            type: Boolean,
            default: false
        },

        /**
         * Gibt den Modus der DataView an (form/standard)
         *
         * Im Modus "form" wird bei einem Doppelklick die ausgewählte Membervariable der Zeile an die aufrufende Komponente
         * zurückgegeben.
         *
         * Im Modus "standard" wird bei einem Doppelklick die Edit-Form zur Zeile geöffnet.
         *
         * @var {String} [mode="standard"]
         */
        mode: {
            type: String,
            default: "standard"
        },

        /**
         * Ein Wert, der angibt, ob nach Auswahl in der SelectView der Datensatz abgeholt, oder
         * der bereits existierende Datensatz in der SelectView genutzt werden soll.
         *
         * @var {Boolean} [silent=false]
         */
        silent: {
            type: Boolean,
            default: false
        },

        /**
         * Gibt an, ob die Sucheingabe für spätere Aufrufe gespeichert werden soll.
         *
         * @var {Boolean} [cacheSearch=true]
         */
        cacheSearch: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        /**
         * Liefert den Wert, ob die Serverantwort der Toolbarkonfiguration zur nächsten Verwendung zwischengespeichert werden kann.
         *
         * @returns {Boolean} Der Wert, ob die Serverantwort zwischengespeichert werden kann.
         */
        cachable() {
            return _.get(this.layout, "cachable", true);
        },

        /**
         * Gibt die Quellbezeichung (Model Name) der zugrunde liegenden Daten zurück.
         *
         * @returns {String} Die Quellbezeichung.
         */
        source() {
            let source = _.get(this.layout, "source", null);

            return source
                ? `${source.charAt(0).toUpperCase()}${source.slice(1)}`
                : null;
        },

        /**
         * Gibt die Services zurück, welche für die Abfrage des User Interfaces sowie der Daten
         * Serverabfrage herangezogen wird.
         *
         * @returns {Object} Die Services.
         */
        services() {
            return _.get(this.layout, "services", {});
        },

        /**
         * Gibt den aktuellen Suchwert zurück.
         *
         * @returns {String} Der aktuelle Suchwert.
         */
        searchValue() {
            return this.cache.reactive.get("search") || "";
        },

        /**
         * Liefert einen Wert, der angibt, ob die Gesamtanzahl der Datensätze in der rechten unteren Ecke
         * dargestellt werden soll.
         *
         * @returns {Boolean}
         */
        showTotal() {
            return this.layout.showTotal === true;
        },

        /**
         * Gibt zurück, die Tabellen-Header sichbar sind.
         *
         * @returns {Boolean} Der Wert, ob die Tabellen-Header sichtbar sind.
         */
        isHeaderVisible() {
            return this.mode === "form" ? _(this.columns)
                .filter(["visible", true])
                .map("header")
                .flatten()
                .value()
                .join("")
                .length > 0 : true;
        },

        /**
         * Gibt die Gesamtanzahl der verfügbaren Seiten der Ergebnismenge zurück.
         *
         * @returns {Number} Die Anzahl der verfügbaren Seite der Ergebnismenge.
         */
        totalPages() {
            return Math.ceil(this.totalRows / this.pageSize());
        },

        /**
         * Ermittelt die Höhe einer Tabellenzeile zur Laufzeit.
         *
         * @returns {Number} Die Höhe der einer Tabellenzeile.
         */
        rowHeight() {
            if (this.$refs.tableMeasurement)
                return $(this.$refs.tableMeasurement)
                    .find("tbody tr:first-child")
                    .get(0)
                    .getBoundingClientRect()
                    .height;

            return 30;
        },

        /**
         * Liefert einen Wert, der angibt, ob die Tabelle sortierbar ist.
         *
         * @returns {Boolean} Der Wert, ob die Tabelle sortierbar ist.
         */
        sortable() {
            return _.get(this.layout, "sortable", true);
        }
    },

    watch: {
        variables: {
            /**
             * Überwacht Änderungen von Modul- sowie Sektionsvariablen und
             * führt alle OnChange-Aktionen aus der Struktur aus.
             *
             * @param {Object} newVars Die geänderten Variablen.
             * @param {Object} oldVars Die ursrpünglichen Variablen.
             */
            handler(newVars, oldVars) {
                this.fireEvents("onChange", {
                    before: oldVars,
                    after: newVars
                });
            },

            immediate: false,
            flush: "post"
        }
    },

    methods: {
        /**
         * Leert die Toolbar.
         */
        beforeInit() {
            this.toolbar.left = [];
            this.toolbar.right = [];
        },

        /**
         * Liefert eine Instanz zur Ereignisbehandlung.
         *
         * @param {unknown} initialValue Der initiale Wert, der an die erste Aktionsmethode übergeben wird.
         * @param {TControl} trigger Das auslösende Steuerelement.
         * @param {Record<string, unknown>} store Der Store.
         * @param {boolean} log Ein Wert, der angibt, ob die Ereignisse in der Konsole ausgegeben werden sollen.
         *
         * @returns {EventHandler} Die Instanz zur Ereignisbehandlung.
         */
        getEventHandler({ initialValue = undefined, store, trigger, conditionValueResolver = (member) => this.getEntityByMember(member), log = false } = {}) {
            const actionFactory = new ActionFactory();
            const actions = [
                "copy",
                "create",
                "cut",
                "delete",
                "deselect",
                "duplicatePlan",
                "getSelectedId",
                "paste",
                "refreshRow",
                "reselect",
                "setSelectedId"
            ].reduce((actions, current) => {
                actions[current] = async (options) =>
                    await actionFactory.execute({
                        ...options,

                        $refs: this.$refs,
                        cache: this.eventHandlerCache,
                        navigation: this.navigation,
                        scroller: this.scrollInstance,
                        params: this.params,
                        rows: this.rows,
                        selected: this.selected
                    });

                return actions;
            }, {});

            const eventHandler = new EventHandler({
                log,
                trigger,
                plugins: {
                    $auth: this.$auth,
                    $modal: this.$modal,
                    $model: this.$model,
                    $cache: this.cache,
                    $clipboard: this.clipboard,
                    $config: this.$config,
                    $page: this.$page,
                    $parameters: this.parameters(),
                    $request: this.$request
                },

                component: this,
                filters: this.filters().json,

                initialValue,
                store: store || this.layout,

                conditionValueResolver,

                hooks: {
                    actions: {
                        ...actions,

                        /**
                         * Öffnet den Dialog zu Meldung eines Fehlers einer Bankverbindung.
                         */
                        bankFeedback: () => {
                            this.$modal.show("bankaccountfeedback");
                        },

                        /**
                         * Liefert einen Datensatz anhand der übergebenen Id.
                         */
                        fetchRow: async ({ action }) =>
                            await fetchRow.call(this, action.id, {}),

                        /**
                         * Holt alle Datenzeilen vom Server.
                         */
                        fetchRows: async () => {
                            await fetchRows.call(this, 0, false, true);
                        },

                        /**
                         * Aktualisiert die Ansicht (DataView) und scrollt zur letzten Position vor Aktualisierung.
                         */
                        refreshView: async () => {
                            const scrollY = this.scrollInstance && this.scrollInstance.scrollPosition();

                            await this.refreshView();

                            this.scrollInstance && this.scrollInstance.scrollVertical(scrollY, false);

                            return this.rows;
                        },

                        /**
                         * Wählt die zuletzt ausgewählte Zeile aus.
                         */
                        reselect: () =>
                            this.selectLastItem(),

                        /**
                         * Scrollt die Tabelle zur Zeile des angegebenen Index.
                         *
                         * @param action Die Aktion mit dem Index.
                         */
                        scrollToSelected: ({ action }) =>
                            this.scrollToSelected(action.index),

                        /**
                         * Aktualisiert die Tabelle und hebt die aktuelle Auswahl auf.
                         */
                        updatetable: async () => {
                            try {
                                await fetchRows.call(this, 0, false, true);

                                this.navigation && await this.navigation.deselectAll();
                            } catch (e) {
                                Logger.error("Fehler in der Methode", e);
                            }
                        },

                        /**
                         * Aktualisiert die Toolbar.
                         */
                        updateToolbar: async () =>
                            await this.updateToolbar()
                    }
                }
            });

            return eventHandler;
        },

        /**
         * Komponente initialisieren und initiale Api-Anfragen durchführen.
         *
         * Wissenswert: Ab diesem Punkt stehen alle Eigenschaften sowie
         * das Dom-Element zur Verfügung.
         */
        async init() {
            /**
             * Wird genutzt, um den zuvor gecachten Suchwert aufzuheben,
             * wie bspw. bei Aufruf des Hinzufügen-Dialogs eines neuen Plans under Planungen
             */
            if (!this.cacheSearch) {
                this.cache.reactive.set("search", "");
                this.cache.reactive.commit();
            }

            /**
             * Auszuführende Methode registrieren, bevor Menü gewechselt wird.
             */
            MessageProvider.attach(Constant.System.NOTIFICATION.MENU_SWITCH, this.componentId, async (topic, receiverId, value) => {
                if (this.componentId !== receiverId)
                    return;

                MessageProvider.detach(Constant.System.NOTIFICATION.MENU_SWITCH, this.componentId);
                MessageProvider.detach(Constant.System.NOTIFICATION.TAB_SWITCH, this.componentId);

                await this.getEventHandler({ trigger: this.layout }).handle({
                    events: _.get(this.layout, "events", []),
                    eventName: "onUnload",
                    confirmation: this.layout.confirmation
                });
            });

            /**
             * Auszuführende Methode registrieren, bevor Tab gewechselt wird.
             */
            MessageProvider.attach(Constant.System.NOTIFICATION.TAB_SWITCH, this.componentId, async (topic, receiverId, value) => {
                if (this.componentId !== receiverId)
                    return;

                if (!this.componentId.startsWith(value))
                    return;

                window.removeEventListener("resize", this.debounced);
                window.removeEventListener("orientationchange", this.debounced);

                document.removeEventListener("keyup", this.copy);
                document.removeEventListener("keyup", this.cut);
                document.removeEventListener("keyup", this.paste);
                document.removeEventListener("keyup", this.unmark);

                MessageProvider.detach(Constant.System.NOTIFICATION.MENU_SWITCH, this.componentId);
                MessageProvider.detach(Constant.System.NOTIFICATION.TAB_SWITCH, this.componentId);

                await this.getEventHandler({
                    trigger: this.layout
                }).handle({
                    events: _.get(this.layout, "events", []),
                    eventName: "onUnload",
                    confirmation: this.layout.confirmation
                });
            });

            await this.getEventHandler({ trigger: this.layout }).handle({
                events: _.get(this.layout, "events", []),
                eventName: "onLoad"
            });

            this.debounced = _.debounce(() => {
                if (this.needRows())
                    fetchRows.call(this, 0, false, true);
            }, 250);

            window.removeEventListener("resize", this.debounced);
            window.removeEventListener("orientationchange", this.debounced);

            document.removeEventListener("keyup", this.copy);
            document.removeEventListener("keyup", this.cut);
            document.removeEventListener("keyup", this.paste);
            document.removeEventListener("keyup", this.unmark);

            window.addEventListener("resize", this.debounced);
            window.addEventListener("orientationchange", this.debounced);

            document.addEventListener("keyup", this.copy);
            document.addEventListener("keyup", this.cut);
            document.addEventListener("keyup", this.paste);
            document.addEventListener("keyup", this.unmark);

            if (this.layout.clipboard && this.layout.clipboard.topic)
                Clipboard.attach(this.layout.clipboard.topic, this.componentId, this.checkClipboard);

            const url = Urls(this.layout.services, this.$config).Data.getItems();
            const transaction = this.$apm && this.$apm.startTransaction(`POST ${url}`, "http-request", { managed: true });

            try {
                transaction && transaction.addLabels({
                    view: _.get(this.layout, "services.data.urls.create")
                });

                await this.refreshView();
            } catch (e) {
                this.$apm && this.$apm.captureError(e);

                throw e;
            } finally {
                transaction && transaction.end();
            }

            if (this.navigation) this.navigation.destroy();

            /**
             * Liefert alle Zeilen-Ids anhand deren Index.
             *
             * @param {Array} indices Die heranzuziehenden Indices.
             *
             * @returns {Array} Ein Array der Zeilen-Ids in Abhängikeit der Indices.
             */
            const getSeletedRowIds = indices => {
                return (this.rows || [])
                    .filter((row, index) => (indices || []).includes(index))
                    .map(row => row.id);
            };

            this.scrollToSelected();

            /**
             * Zeilenauswahl initialisieren.
             */
            this.navigation = new Navigation(this, {
                multiselect: this.mode !== "select",

                // eslint-disable-next-line consistent-return
                onSelect: async selected => {
                    let cacheSelected = this.mode !== "select" ? this.cache.get("selected") || { index: -1, ids: [], row: [] } : { index: -1, ids: [], row: [] };
                    let currentSelectedIndex = cacheSelected.index;
                    let newSelectedIndex = selected.length === 1 ? selected[0] : -1;
                    let rowIds = getSeletedRowIds(selected || []);

                    this.selected = {
                        index: newSelectedIndex,
                        row: selected.length === 1 ? this.rows[selected[0]] : null,
                        ids: rowIds
                    };

                    this.cache.set("selected", { index: newSelectedIndex, row: selected.length === 1 ? this.rows[selected[0]] : null, ids: rowIds });

                    if (selected.length < 2 && (currentSelectedIndex !== newSelectedIndex || !this.initialized)) {
                        this.cache.set("selectedPosition", newSelectedIndex);

                        new Promise((resolve, reject) => {
                            // Timer ermöglicht Zeilen-Doppelklick:
                            setTimeout(async () => {
                                try {
                                    await this.onSelect(selected);

                                    resolve();
                                } catch (err) {
                                    reject(err);
                                }
                            }, 500);
                        });
                    } else if (currentSelectedIndex !== newSelectedIndex || !this.initialized) {
                        this.onSelect(selected);
                    }
                }
            });

            if (this.navigation) {
                this.navigation.deselectAll(true);
                this.navigation.state.select.position = this.cache.get("selectedPosition") || 0;
            }

            Array.from(this.$el.querySelectorAll("tr.cutted")).forEach(row => $(row).removeClass("cutted"));

            let cacheSelected = this.cache.get("selected") || { index: -1, ids: [], row: [] };

            if (this.mode !== "select" && this.navigation) {
                let clipboard = _.cloneDeep(this.clipboard.ids()) || [];

                const selected = JSON
                    .parse(JSON.stringify(cacheSelected.ids ?? []))
                    .map(id => (this.rows ?? []).findIndex(row => row.id === id))
                    .filter(index => index > -1);

                let hasPasted = Clipboard.getLastHandle() !== this.componentId;

                (cacheSelected.ids || []).forEach(id => {
                    if (clipboard.includes(id) && hasPasted) {
                        cacheSelected.ids.splice(cacheSelected.ids.indexOf(id), 1);

                        if (id === cacheSelected.id) {
                            cacheSelected.id = -1;
                            cacheSelected.row = null;
                        }
                    }
                });

                this.selected = {
                    index: cacheSelected.index,
                    row: cacheSelected.row,
                    ids: cacheSelected.ids
                };

                selected
                    .forEach(index => this.navigation.select(index, false, false, false));

                await this.onSelect(selected, cacheSelected.row);
            }

            if (Clipboard.getLastHandle() !== this.componentId)
                this.clipboard.clear(true);

            if (this.clipboard.type() !== "cut")
                return;

            this.clipboard.ids().forEach(id => {
                let index = (this.rows || []).findIndex(row => row.id === id);

                if (index > -1 && this.$refs[`row-${index}`])
                    $(this.$refs[`row-${index}`][0]).addClass("cutted")
            });
        },

        /**
         * Aktiviert den Einfügen-Button, sobald das Clipboard über zugehörige Daten für die Tabelle verfügt.
         *
         * @param {String} topic Das Clipboard-Thema.
         */
        checkClipboard(topic) {
            let buttons = [...this.toolbar.left, ...this.toolbar.right];

            for (var i = 0; i < buttons.length; i++) {
                let button = buttons[i];
                let isPasteAction = _(button.events || [])
                    .flatten()
                    .map("actions")
                    .flatten()
                    .filter(["type", "PasteAction"])
                    .value()
                    .length;

                if (isPasteAction) button.disabled = topic === "clipboard.topic.reset";
            }
        },

        /**
         * Hebt die aktuelle Auswahl auf und entfernt alle Einträge aus einem Copy- oder Cutvorgang
         *
         * @param {KeyboardEvent} e Das Ereignisobjekt.
         */
        unmark(e) {
            if (!e.key || e.key.toLowerCase() !== "escape")
                return;

            const focused = document.activeElement;

            if (!this.$el.contains(focused))
                return;

            if (focused && focused.tagName.toLowerCase() === "input")
                return;

            this.navigation && this.navigation.deselectAll();

            this.clipboard.clear();

            (Object.keys(this.$refs || {}) || [])
                .filter(ref => ref.indexOf("row-") === 0)
                .forEach(ref => {
                    $(this.$refs[ref][0]).removeClass("cutted");
                });
        },

        /**
         * Sucht in der Toolbar nach dem Button mit der Aktion "copy", "cut", "paste" oder "delete",
         * startet dessen Ladeanimation und führt die entsprechende Aktion aus.
         *
         * @param {"copy"|"cut"|"paste"|"delete"} type Der Typ der Aktion.
         * @param {KeyboardEvent} e Das Tastaturereignis.
         */
        async handleCopyCutPasteDelete(type, e) {
            if (!e.key)
                return;

            if (type !== "delete" && !e.ctrlKey && !e.metaKey && !e.shiftKey)
                return;

            if (this.$refs.dataView !== e.target && !this.$refs.dataView.contains(e.target))
                return;

            if ($(document.activeElement).parent(".modal__container").get(0))
                return;

            let idxButton = [...this.toolbar.left, ...this.toolbar.right].findIndex(({ events = [] }) =>
                events
                    .map(({ actions }) => actions)
                    .flat()
                    .some(action => action.$type === type)
            );


            if (idxButton === -1)
                return;

            const button = [...this.toolbar.left, ...this.toolbar.right].at(idxButton);

            if (!button)
                return;

            const event = button && button.events
                ? button.events.find(({ actions }) => actions.some(action => action.$type === type))
                : undefined;

            const actions = event && event.actions
                ? event.actions
                : [{
                    $type: type,
                    type: `${type.charAt(0).toUpperCase}${type.slice(1)}Action`
                }];

            const $refButton = this.$refs.toolbar.items.at(idxButton);

            if ($refButton && $refButton.showLoader)
                $refButton.showLoader();

            const eventHandler = this.getEventHandler({ trigger: button });

            try {
                await eventHandler.handle({
                    eventName: "onClick",
                    events: [{
                        actions,
                        trigger: "onClick",
                    }]
                });
            } finally {
                if ($refButton && $refButton.hideLoader)
                    $refButton.hideLoader();
            }
        },

        /**
         * Kopiert Daten in die Zwischenablage.
         *
         * @param {KeyboardEvent} e Das Ereignisobjekt.
         */
        async cut(e) {
            if ((!e.ctrlKey || e.key.toLowerCase() !== "x"))
                return;

            await this.handleCopyCutPasteDelete("cut", e);
        },

        /**
         * Kopiert Daten in die Zwischenablage.
         *
         * @param {KeyboardEvent} e Das Ereignisobjekt.
         */
        async copy(e) {
            if ((![e.ctrlKey, e.metaKey].includes(true) || !["c", "insert"].includes(e.key.toLowerCase())))
                return;

            await this.handleCopyCutPasteDelete("copy", e);
        },

        /**
         * Fügt Daten aus der Zwischenablage in die Tabelle.
         *
         * @param {KeyboardEvent} e Das Ereignisobjekt.
         */
        async paste(e) {
            if ((![e.ctrlKey, e.metaKey].includes(true) || e.key.toLowerCase() !== "v") && (!e.shiftKey || e.key.toLowerCase() !== "insert"))
                return;

            await this.handleCopyCutPasteDelete("paste", e);
        },

        /**
         * Löscht die ausgewählten Zeilen.
         *
         * @param {KeyboardEvent} e Das Ereignis.
         */
        async deleteSelectedRows(e) {
            // Tabelle wird in einem Auswahldialog angezeigt?
            if (this.mode === "select")
                return;

            // Nicht die Taste "Delete" gedrückt?
            if (!e.key || e.key.toLowerCase() !== "delete")
                return;

            // Keine Zeilenauswahl?
            if (this.selected.ids.length === 0)
                return;

            const buttonDelete = [...this.toolbar.left, ...this.toolbar.right].find(({ events = [] }) =>
                events
                    .map(({ actions }) => actions)
                    .flat()
                    .some(action => action.$type === "delete")
            );

            const selectionMode = buttonDelete.selectionMode || "singleOrMultiple";

            // Falls nur eine Zeile zum löschen ausgewählt sein darf.
            if (selectionMode === "single" && this.selected.ids.length > 1)
                return;

            await this.handleCopyCutPasteDelete("delete", e);
        },

        /**
         * Bereitet sämtliche Filter der DataView vor.
         *
         * @returns {{json: FilterModel[], plain: String}} Die vorbereiteten Filter.
         */
        filters() {
            /**
             * Die von der Struktur vorgegebenen initialen Filter.
             */
            const layoutFilter = FilterUtils.mapFilters(this.layout.filters || [])

            /**
             * Die von der Toolbar vorgegebenen initialen Filter.
             */
            const toolbarFilter = ([...this.toolbar.left, ...this.toolbar.right])
                .filter(({ member, selected } = {}) => ![selected, member].includes(undefined))
                .map(({ member, selected, filters }) => {
                    const usableFilter = filters.find(({ name }) => name === member);

                    return FilterUtils.mapFilter({
                        name: usableFilter.name,
                        operator: usableFilter.operator,
                        value: selected,
                        scope: usableFilter.scope
                    })
                });

            const filterPriority = {
                component: 1,
                menu: 2,
                page: 3,
            };

            const componentFilters = this.cache.get("filters") || [];
            const menuFilters = this.cache.id(this.$page.left.id()).get("filters") || [];
            const pageFilters = this.cache.id(this.$page.top.id()).get("filters") || [];

            let cachedFilter = FilterUtils
                .mapFilters(
                    JSON.parse(JSON.stringify([
                        ...componentFilters,
                        ...menuFilters,
                        ...pageFilters
                    ]))
                )
                .reduce((result, filter) => {
                    const match = result.find(({ name }) => name === filter.name);

                    if (!match)
                        result.push(filter);
                    else if (filterPriority[match.scope] > filterPriority[filter.scope])
                        match.value = filter.value;

                    return result;
                }, []);

            /**
             * Alle Filter entfernen, deren Wert im Cache NULL sind.
             */
            cachedFilter
                .filter(filter => filter.value === null)
                .forEach(filter => {
                    const idxLayoutFilter = layoutFilter.findIndex(({ name }) => name === filter.name);
                    const idxToolbarFilter = toolbarFilter.findIndex(({ name }) => name === filter.name);

                    if (idxLayoutFilter > -1)
                        layoutFilter.splice(idxLayoutFilter, 1);

                    if (idxToolbarFilter > -1)
                        toolbarFilter.splice(idxToolbarFilter, 1);
                });

            cachedFilter = cachedFilter.filter(({ value }) => value !== null);

            const combinedFilters = [...layoutFilter, ...toolbarFilter, ...cachedFilter];

            // Doppelte Filter entfernen
            const uniqueFilters = [...new Map(combinedFilters.map(filter => [filter.name, filter])).values()];

            let commonFilter = FilterUtils.mapFilters(uniqueFilters);

            const excludables = this.layout.excludeFilter || [];

            if (excludables.length)
                commonFilter = commonFilter.filter(({ name }) => !excludables.includes(name));

            /**
             * Die vorbereiteten Filter für die Abfrage.
             */
            return {
                json: commonFilter,
                plain: this.parameters().resolve(FilterUtils.toString(commonFilter)),
            };
        },

        /**
         * Liefert die DataView-Filter als JSON-Objekt.
         *
         * @returns {Record<string, unknown>}
         */
        jsonFilters() {
            return FilterUtils.toJson(this.filters().json || []);
        },

        /**
         * Liefert den Inlinstyle der Tabellen-Header der DataView.
         *
         * @param {Array} columns Die Spalten der DataView.
         * @param {Object} column Die zu gestaltende Spalte.
         * @param {Number} index Die Position der Spalte.
         *
         * @returns {Object} Das Css-Inline-Style Objekt.
         */
        getHeaderStyle(columns, column, index) {
            let style = {
                width: index < columns.length - 1 ? column.width : ''
            };

            if (column.alignment === 'center' && parseInt(column.width, 10) < 20)
                style["padding-left"] = 0;

            return style;
        },

        /**
         * Gibt an, ob neue Zeilen abgefragt werden sollen.
         * Wird aufgerufen, sobald das Fenster vergrößert/verkleinert wird,
         * um sicherzustellen, dass die Tabelle gefüllt ist.
         *
         * @returns {Boolean} Der Wert, ob neue Zeilen nachgeladen werden müssen.
         */
        needRows() {
            if (this.$el) {
                let rLen = this.$el.querySelectorAll("tbody tr").length;
                let pSize = this.pageSize();

                return rLen < pSize && this.totalRows > pSize;
            }

            return false;
        },

        /**
         * Berechnet die Anzahl der notwendigen Zeilen in der Tabelle, um diese auszufüllen.
         *
         * @returns {Number} Die Anzahl der notwendigen Zeilen.
         */
        pageSize() {
            if (!this.$el)
                return 0;

            return Math.ceil(1.5 * window.innerHeight / this.rowHeight);
        },

        /**
         * Prüft, ob eine Spalte als Link dargestellt werden soll.
         *
         * @param {Object} column Die zu prüfende Spalte.
         *
         * @returns {Boolean} Der Wert, ob es sich um eine Spalte dargestellt als Link handeln soll.
         */
        isLinkColumn(column) {
            if (column.template === "Icon") return false;
            if (column.visible === false) return false;
            if (column.isPlaceholder) return false;

            return _.get((this.columns || []).filter(column => column.template !== "Icon" && column.visible !== false && column.isPlaceholder !== true), "0", null) === column;
        },

        /**
         * Aktualisiert eine Zeile in der Tabellenübersicht.
         *
         * @param {String|Number} id Die Id der Zeile in der Entität.
         * @param {Any} defaultValue Ein darzustellender optionaler Standardwert für den Übertrag in die Tabelle.
         *
         * @returns {Promise<object>} Die darzustellenden Zeileninformationen.
         */
        async fetchRow(id, defaultValue) {
            return await fetchRow.call(this, id, defaultValue);
        },

        /**
         * Führt ein Ereignis bei doppelten Tap aus.
         *
         * @param {TouchEvent} e
         */
        touchDoubleTap(e) {
            if (!isTouchEvent(e)) return;

            let target = e.target.closest("tr");
            let rowIndex = _.get(target, "dataset.row", false);
            let row = rowIndex !== false ? this.rows[rowIndex] : false;

            if (rowIndex !== this.touch.index) {
                this.touch.lastTouch = Date.now();
                this.touch.index = rowIndex;
            }

            const timeDistance = Date.now() - this.touch.lastTouch;

            if (timeDistance > 100 && timeDistance < 300 && row) {
                this.touch.lastTouch = Date.now();

                this.onEdit(row);
            } else if (timeDistance > 299)
                this.touch.lastTouch = Date.now();
        },

        /**
         * Führt ein Ereignis bei lang gedrücktem Touch aus.
         *
         * @param {TouchEvent} e
         */
        touchLongPress(e) {
            if (!isTouchEvent(e)) return;

            let target = e.target.closest("tr");
            let rowIndex = _.get(target, "dataset.row", false);
            let row = rowIndex !== false ? this.rows[rowIndex] : false;

            if (row)
                this.onEdit(row);
        },

        /**
         * Liefert die Bezeichnung einer Spalte.
         *
         * @param {Object} column Das Spaltenobjekt.
         *
         * @returns {String} Die Bezeichung der Spalte.
         */
        getColumnHeader(column) {
            let rxHeader = new RegExp("\\{{1}([a-z0-9]*)\\}{1}", "i");
            let match = null;
            let columnValue = _.get(column, "header", "");

            while ((match = rxHeader.exec(columnValue)) !== null) {
                columnValue = columnValue.replace(match[0], _.get(this.layout, match[1], ""));
            }

            return columnValue;
        },

        /**
         * Speichert und setzt den Wert der Eigenschaft "search" im DataView-Store.
         *
         * @param {string} searchValue Der Suchbegriff.
         * @param {Function} startPending Funktion, um Ladeanimation zu starten.
         * @param {Function} stopPending Funktion, um Ladeanimation zu stoppen.
         */
        async setSearchValue(searchValue, startPending, stopPending) {
            startPending();

            this.cache.reactive.set("search", searchValue);
            this.cache.reactive.commit();

            if (this.navigation)
                await this.navigation.deselectAll();

            nextTick(async () => {
                let apmName = `Input - input["toolbar-search-${_.kebabCase(this.layout.title)}"]`;

                const transaction = this.$apm && this.$apm.startTransaction(apmName, "user-interaction", { managed: true });

                try {
                    this.rows = [];

                    await vueDomUpdated();
                    await fetchRows.call(this, 0, false, true);

                    this.scrollInstance && this.scrollInstance.scrollVertical();
                } catch (e) {
                    this.$apm && this.$apm.captureError(e);

                    throw e;
                } finally {
                    stopPending();

                    transaction && transaction.end();
                }
            });
        },

        /**
         * Setzt den Filter ausgelöst durch ein Steuerelement in der Toolbar
         *
         * @param {FilterModel} filter Der anzuwendende oder aufzuhebende Filter
         * @param {{}} control Das auslösende Steuerelement
         */
        async setFilter(filter, control) {
            /**
             * @type {ClientScope}
             */
            const filterScope = filter.scope;

            /**
             * @type {[key:ClientScope]: () => FilterModel[]}
             */
            const getFilters = {
                "component": () => (this.cache.get("filters") || []).filter(({ name }) => name !== filter.name),
                "menu": () => (this.cache.id(this.$page.left.id()).get("filters") || []).filter(({ name }) => name !== filter.name),
                "page": () => (this.cache.id(this.$page.top.id()).get("filters") || []).filter(({ name }) => name !== filter.name)
            };

            /**
             * @type {[key:ClientScope]: (filters: FilterModel[]) => void}
             */
            const setFilters = {
                "component": filters => this.cache.set("filters", filters),
                "menu": filters => this.cache.id(this.$page.left.id()).set("filters", filters),
                "page": filters => this.cache.id(this.$page.top.id()).set("filters", filters)
            };

            /**
             * @type {FilterModel[]}
             */
            const cachedFilters = getFilters[filterScope]();

            cachedFilters.push(filter);

            const apmName = `Click - button["toolbar-filter-${_.kebabCase(control.hint || this.layout.title)}"]`;
            const transaction = this.$apm && this.$apm.startTransaction(apmName, "user-interaction", { managed: true });

            setFilters[filterScope](cachedFilters);

            this.navigation && await this.navigation.deselectAll();
            this.request.fetching = true;
            this.request.error.raised = false;

            try {
                this.rows = [];

                await vueDomUpdated();
                await fetchRows.call(this, 0, false, true);

                this.request.fetching = false;
                this.scrollInstance && this.scrollInstance.scrollVertical();
            } catch (e) {
                if (this.$request.hasCancelled(e))
                    return;

                if (this.$request.isDoubleRequest(e))
                    return;

                this.request.error.raised = true;
                this.$emit("error", true);

                this.$apm && this.$apm.captureError(e);

                throw e;
            } finally {
                transaction && transaction.end();
            }
        },

        /**
         * Entfernt einen Filter aus der Abfrage
         *
         * @param {String} name Der zu entfernende Filtername
         * @param {{}} control Das auslösende Steuerelement
         */
        async clearFilter(name, control) {
            /**
             * @type {[key:ClientScope]: (filters: FilterModel[]) => void}
             */
            const getFilters = {
                "component": () => this.cache.get("filters") || [],
                "menu": () => this.cache.id(this.$page.left.id()).get("filters") || [],
                "page": () => this.cache.id(this.$page.top.id()).get("filters") || []
            };

            /**
             * @type {FilterModel[]}
             */
            const filters = [
                ...getFilters.component(),
                ...getFilters.menu(),
                ...getFilters.page()
            ];

            if (!filters.find(filter => filter.name === name))
                filters.push(FilterUtils.mapFilter(control.filters.find(filter => filter.name === name)));

            const clearableFilter = filters.find(filter => filter.name === name);
            clearableFilter.value = null;

            const apmName = `Click - button["toolbar-filter-${_.kebabCase(control.hint || this.layout.title)}"]`;
            const transaction = this.$apm && this.$apm.startTransaction(apmName, "user-interaction", { managed: true });

            this.cache.set("filters", filters.filter(({ scope }) => scope === "component"));
            this.cache.id(this.$page.left.id()).set("filters", filters.filter(({ scope }) => scope === "menu"));
            this.cache.id(this.$page.top.id()).set("filters", filters.filter(({ scope }) => scope === "page"));

            this.navigation && await this.navigation.deselectAll();
            this.request.fetching = true;
            this.request.error.raised = false;

            try {
                this.rows = [];

                await vueDomUpdated();
                await fetchRows.call(this, 0, false, true);

                this.request.fetching = false;
                this.scrollInstance.scrollVertical()
            } catch (e) {
                if (this.$request.hasCancelled(e))
                    return;

                if (this.$request.isDoubleRequest(e))
                    return;

                this.request.error.raised = true;
                this.$emit("error", true);

                this.$apm && this.$apm.captureError(e);

                throw e;
            } finally {
                transaction && transaction.end();
            }
        },

        /**
         * Berechnet und liefert die Höhe aller Elemente mit dem DATA-Attribut "fixed-height".
         * Die berechnete Höhe dient der Berechnung der Höhe der Ladeanimation sowie der Positionierung
         * der Toolbar während die Daten geladen werden.
         *
         * @returns {string} Die Höhe aller Elemente mit dem DATA-Attribut "fixed-height".
         */
        getFixedHeights() {
            let heights = 0;

            if (this.$el) {
                let $children = this.$el.querySelectorAll(".data-view [data-fixed-height]");

                heights = _($children)
                    .filter($child => {
                        return !$($child).hasClass("no-display");
                    })
                    .map($child => {
                        let childStyle = $child.currentStyle || window.getComputedStyle($child);

                        let offsetHeight = parseInt(_.get($child, "offsetHeight", 0), 10) || 0;
                        let marginTop = parseInt(_.get(childStyle, "marginTop", 0), 10) || 0;
                        let marginBottom = parseInt(_.get(childStyle, "marginBottom", 0), 10) || 0;

                        return marginTop + offsetHeight + marginBottom;
                    })
                    .value();

                if (heights.length > 0) {
                    heights = heights.reduce((sum, n) => {
                        return sum + n;
                    });
                }
            }

            return heights;
        },

        /**
         * Berechnet und liefert die Höhe der Ladeanimation sowie der Tabellenoverlays.
         *
         * @param {Number} pixels Hinzufügen oder entfernen von Pixeln.
         *
         * @returns {string} Die Höhe.
         */
        getHeight(pixels) {
            if (isNaN(pixels))
                pixels = 0;

            return `calc(100% + ${pixels}px - ${this.getFixedHeights()}px)`;
        },

        /**
         * Berechnet und liefert die Top-Position der Ladeanimation sowie der Tabellenoverlays.
         *
         * @returns {string} Die Höhe.
         */
        getTopPosition() {
            return `${this.getFixedHeights()}px`;
        },

        /**
         * Liefert die CSS-Klasse(n) der Tabellen-Spalten für die Ausrichtung und die farbliche Hervorhebung
         * der jew. Spalte aller Zeilen im Rahmen der Sortierung.
         *
         * @param {Object} column Die Zeile.
         *
         * @returns {string} Die CSS-Klasse(n).
         */
        getClassObject(column) {
            let cssClasses = {
                ordered: this.getSortOrder(column) !== "",
                "data-view__span--link": this.isLinkColumn(column)
            };

            cssClasses[`--text-${column.alignment}`] = true;

            return cssClasses;
        },

        /**
         * Sortiert eine Spalte (ascending/descending).
         *
         * @param {Object} column Die zu sortierende Spalte.
         */
        async sortColumn(column) {
            if (this.sortable) {
                let sortInx = _.findIndex(this.ordering, orderColumn => orderColumn.property.toLowerCase() === column.member.toLowerCase());

                let sort = sortInx > -1
                    ? this.ordering[sortInx]
                    : { property: column.member, order: false };

                sort.order = !sort.order || sort.order === "ascending"
                    ? "descending"
                    : "ascending";

                let ordering = sort.order ? [sort] : [];

                this.cache.set("order", [ordering]);

                this.ordering = ordering;

                this.navigation && this.navigation.deselectAll();

                nextTick(async () => {
                    await fetchRows.call(this, 0, false, true);
                });
            }
        },

        /**
         * Liefert die Sortierung einer Spalte ("ascending"/"descending").
         *
         * @param {Object} column Die zu prüfende Spalte.
         *
         * @returns {string} Der Wert der Sortierung ("ascending"/"descending").
         */
        getSortOrder(column) {
            let ordering = _.find((this.cache.get("order") || [[]])[0], orderObject => {
                let props = _.get(orderObject, "property", "");

                props = props.split(".");

                _(props).each((prop, inx) => {
                    props[inx] = `${prop.charAt(0).toLowerCase()}${prop.slice(1)}`;
                });

                return props.join(".") === _.get(column, "member", _.get(column, "id", undefined));
            });

            return _.get(ordering, "order", "");
        },

        /**
         * Liefert die Css-Positionierungsklasse des Sortiersymbols.
         *
         * @param {Object} column Das Spaltenobjekt.
         *
         * @returns {String} Die Css-Klasse.
         */
        getColumnSortOrder(column) {
            return `${this.getSortOrder(column)}${column.alignment === "right" ? " -from-left" : ""}${parseInt(column.width, 10) < 20 ? " no-padding" : ""}`;
        },

        /**
         * Liefert die Zeile anhand des übergebenen Zeilenindex.
         *
         * @param {Number} rowIndex Der Zeilenindex.
         *
         * @returns {HTMLElement} Die zugehörige Zeile (tr).
         */
        getRow(rowIndex) {
            return $(this.$refs.tableBody).find(`tr:nth-child(${rowIndex + 1})`).get(0);
        },

        /**
         * Löst bei Klick auf eine Zeile alle in der Struktur vorhandenen Klick-Ereignisse aus.
         *
         * @param {Array} selected Die ausgewählte(n) Zeile(n).
         * @param {Object} [row=null] Die ausgewählte(n) Zeile(n). Überschreibt die Zeile des Parameters - selected -.
         */
        async onSelect(selected, row) {
            if (!selected || this.mode !== "standard" && Array.isArray(selected) && selected.length === 0)
                return;

            const rowIndex = selected.length > 0
                ? selected[selected.length - 1]
                : -1;

            const _row = row || this.rows[rowIndex];

            if (this.mode === "standard") {
                await this.getEventHandler({
                    trigger: this.layout,
                    store: _row
                }).handle({
                    events: _.get(this.layout, "events", []),
                    eventName: "onSelect"
                });
            } else if (this.mode === "form")
                emitSelectionToParent.call(this, _row, _.get(this.layout, "result", ""));
            else if (this.mode === "select")
                this.$emit("selectedValue", !this.silent ? await fetchCompleteRow.call(this, _row) : _row);
        },

        /**
         * Ruft bei einem Doppel-Klick auf eine Zeile die Formulardefinition - gegeben aus der EDIT-Url in der Struktur -
         * auf und öffnet einen daraus generierten Formular-Dialog.
         *
         * @param {Object} row
         */
        async onEdit(row) {
            if (this.mode === "standard") {
                await this.getEventHandler({
                    trigger: this.layout,
                    store: row,
                    initialValue: row
                }).handle({
                    events: _.get(this.layout, "events", []),
                    eventName: "onEdit"
                });
            } else if (this.mode === "form")
                this.$emit("apply");
            else if (this.mode === "select") {
                this.$emit("selectedValue", !this.silent ? await fetchCompleteRow.call(this, row) : row);
                this.$emit("apply");
            }
        },

        /**
         * Ruft bei einem TouchTap-Ereignis auf eine Zeile die Formulardefinition - gegeben aus der EDIT-Url in der Struktur -
         * auf und öffnet einen daraus generierten Formular-Dialog.
         *
         * @param {Object} row
         * @param {MouseEvent|TouchEvent} e
         */
        async onEditByTap(row, e = null) {
            if (!isTouchEvent(e))
                return;

            e.stopPropagation();
            e.preventDefault();

            this.onEdit(row);
        },

        /**
         * Öffnet ein modales Fenster zum editieren eines Datensatzes.
         *
         * @param {Object} row Die zu editierende Datenzeilen ID.
         */
        doEdit(row) {
            let filters = this.filters().json;
            let commonVariables = [...FilterUtils.mapJson(row), ...filters];
            let urls = Urls(this.services, this.$config);
            let editUrl = this.parameters().resolve(`${urls.View.edit()}?id=${_.get(row, "id", false)}`, commonVariables);
            let updateUrl = this.parameters().resolve(urls.Data.update(), commonVariables);

            try {
                this.$modal.show("remote", {
                    button: "save",

                    services: _.cloneDeep(this.services),
                    filters: this.filters().json,
                    params: row,
                    refresh: this.refreshView,

                    openUrl: editUrl,
                    saveUrl: updateUrl,

                    onError: (title, message) => {
                        this.$modal.show("dialog", {
                            title: title,
                            message: message,

                            buttons: {
                                cancel: {
                                    caption: Constant.Button.OK.Caption,
                                    accesskey: Constant.Button.OK.Accesskey
                                }
                            }
                        });
                    },

                    apply: async (data) => {
                        let id = _.get(data, "id", _.get(row, "id", false));
                        let fetchedRow = await fetchRow.call(this, id, {});
                        let assignedRow = _.assign({}, data, fetchedRow);

                        _(this.rows).each(
                            // eslint-disable-next-line
                            (row, rowIndex) => {
                                if (_.get(row, "id", null) === id) {
                                    this.rows[rowIndex] = _.assign(this.rows[rowIndex], assignedRow);

                                    nextTick(() => {
                                        this.navigation.select(rowIndex, false, true);
                                    });

                                    return false;
                                }
                            }
                        );

                        // In den Events nachsehen, ob es eine Change-Aktion gibt:
                        this.fireEvents("onChange", assignedRow);
                    }
                });
            } catch (e) {
                this.$modal.show("dialog", {
                    title: "Fehler!",
                    message: "Der Dialog konnte nicht geladen werden.",

                    buttons: {
                        cancel: {
                            caption: Constant.Button.OK.Caption,
                            accesskey: Constant.Button.OK.Accesskey,

                            callback: () => { }
                        }
                    }
                });

                Logger.error("Der Dialog konnte nicht geladen werden.", e);
            }
        },

        /**
         * Öffnet ein Modul in einem neuen Tab (ModulNavigation).
         *
         * @param {Object} action Die Informationen über das zu öffnende Modul.
         * @param {Object} row Die Daten der betroffenden Entität.
         * @param {Object} customVariables Zusätzliche Parameter (key-value-paare).
         */
        async doOpenModule(action, row, customVariables) {
            const rowIndex = this.rows.findIndex(r => r.id === row.id);
            const $row = this.$refs.tableBody.querySelector(`tr[data-row="${rowIndex}"] .tr-pending`);
            const service = _.get(action, "module.service", "crm");

            let parameters = _.merge(this.parameters().component.get(), customVariables || {});
            let pageId = `${_.get(action, "module.name", "undefined")}_${row.id}`;
            let useCache = _.get(action, "module.cache", false);

            $row.classList.add("tr-pending--show");

            try {
                await this.$page.fetch({
                    id: pageId,
                    layout: _.get(action, "module.name", "undefined"),
                    service: service,
                    persistent: false,
                    parameters: parameters,
                    qsa: row.id,
                    cache: useCache,
                    refId: this.$page.top.id()
                });

                this.$page.activate(pageId);
            } catch (e) {
                if (this.$request.isDoubleRequest(e))
                    return;

                this.$modal.show("dialog", {
                    title: "Fehler!",
                    message: "Das Modul konnte nicht geladen werden.",

                    buttons: {
                        cancel: {
                            caption: Constant.Button.OK.Caption,
                            accesskey: Constant.Button.OK.Accesskey,

                            callback: () => { }
                        }
                    }
                });

                Logger.error("Das Modul konnte nicht geladen werden.", e);
            } finally {
                $row.classList.remove("tr-pending--show");
            }
        },

        /**
         * Holt die Daten einer Zeile erneut vom Server ab und wendet die neuen Daten auf die Zeile an.
         *
         * @param {String} topic Der Grund der Nachricht.
         * @param {Array} values Die vom Notifizierungsereignis übergebenen Werte
         */
        async updaterow(topic, values) {
            const rowId = values.primaries[Object.keys(values.primaries || { id: -1 })[0]];
            const fetchedRow = await this.fetchRow(rowId);

            const rowIndex = this.rows.findIndex(row => row.id === rowId);

            if (rowIndex === -1)
                return;

            this.rows[rowIndex] = {...this.rows[rowIndex], ...fetchedRow};
        },

        /**
         * Sendet ein Signal an alle registrierten Empfänger.
         *
         * @param {Object} action Ein Aktions-Objekt.
         */
        doNotify(action) {
            nextTick(() => {
                MessageProvider.notify(_.get(action, "key", undefined));
            });
        },

        /**
         * Aktualisiert die Tabelle der DataView.
         *
         * @param {Object} action Ein Aktions-Objekt.
         * @param {Object} params Ein Parameter-Objekt.
         */
        async doRefresh(action, params) {
            let key = _.get(action, "key", undefined);

            if (key) {
                let before = _.get(params, `before.${key}`, null);
                let after = _.get(params, `after.${key}`, null);

                if (before !== after)
                    nextTick(async () => {
                        let response = await fetchConfig.call(this, this.cachable);

                        this.config = response;

                        await fetchRows.call(this, 0, false, true);
                    });
            }
        },

        /**
         * Liefert den aktuellen Wert einer Eigenschaft.
         *
         * @param {String} field Die haranzuziehende Eigenschaft.
         *
         * @returns {Any} Der Wert der Eigenschaft.
         */
        getEntityByMember(field) {
            return _.get(this, field, undefined);
        },

        /**
         * Filtert alle Aktionen eines Ereignisses und lösst diese aus, sofern
         * die korrespondierenden Methoden existieren.
         *
         * @todo get rid of it. Der EventHandler soll übernehmen
         *
         * @param {String} triggerType Die Art des auslösenden Ereignisses (bsp. onEdit, onSelect, etc.)
         * @param {Object} row Zur Aktion zugehörige Daten, falls die Aktion derartige benötigen sollte.
         */
        fireEvents(triggerType, row) {
            let events = _.get(this.layout, "events", []);
            let triggerEvents = _(events)
                .filter(["trigger", triggerType])
                .value() || [];

            triggerEvents.forEach(event => {
                let conditions = _.get(event, "conditions", false);
                let actions = _.get(event, "actions", []);

                if (!conditions || (conditions && conditionsFullfilled.call(this, conditions)))
                    _(actions)
                        .each(action => {
                            let type = _.get(action, "$type", "edit");
                            type = `${type.charAt(0).toUpperCase()}${type.slice(1)}`;

                            let method = `do${type}`;

                            if (typeof this[method] === "function") {
                                try {
                                    if (type !== "Edit")
                                        this[`do${type}`](action, row);
                                    else
                                        this[`do${type}`](row);
                                } catch (e) {
                                    Logger.error(`Fehler in der Methode ${method}:`, e);
                                }
                            } else {
                                Logger.warn(`Die Methode ${type} ist nicht implementiert!`);
                            }
                        });
            });
        },

        /**
         * Löst bei Klick auf einen Toobar-Button das zugehörige Event aus.
         *
         * @param {Object} evButton Das Button-Event.
         * @param {Function} showLoader Eine Methode, die eine Ladeanimation startet
         * @param {Function} hideLoader Eine Methode, die eine Ladeanimation beendet
         */
        async tbButtonClickEvent(evButton, showLoader, hideLoader) {
            const selectionMode = evButton.selectionMode || "notRelevant";

            let params;

            if (evButton.params)
                params = _.cloneDeep(evButton.params);
            else if (selectionMode === "single" && this.selected.index > -1)
                params = _.cloneDeep(this.rows[this.selected.index]);
            else if (selectionMode === "singleOrMultiple" && this.selected.ids.length)
                params = _.cloneDeep(this.selected.ids);


            try {
                showLoader && showLoader();

                await this
                    .getEventHandler({
                        initialValue: params,
                        trigger: evButton
                    })
                    .handle({
                        events: evButton.events,
                        eventName: "onClick",
                        confirmation: evButton.confirmation
                    });
            } catch (e) {
                throw e;
            } finally {
                hideLoader && hideLoader();
            }
        },

        /**
         * Liefert den Inhalt einer Zelle in der Tabelle anhand der Eigenschaft Member oder Id der Spalte.
         *
         * @param {Object} row Die Zeile.
         * @param {Object} column Die spalte.
         * @param {Any} defaultValue Ein Standardwert.
         *
         * @returns {String} Der Inhalt der Zelle.
         */
        getRowContent(row, column, defaultValue) {
            let template = _.get(column, "template", "Null");
            let value = _.get(row, column.member, _.get(row, column.id, defaultValue));

            if (value) {
                let decimals = _.get(column, "decimals", 0);
                let format = _.get(column, "format", undefined);
                let options = {
                    format,
                    minDecimals: decimals,
                    maxDecimals: decimals
                };

                try {
                    let result = Formatter(value)[`as${template}`](options);

                    if (isEmptyWs(result)) result = "&nbsp;";

                    return result;
                } catch (e) {
                    Logger.error(`Fehler DataView::getRowContent: Formatierer as${format} nicht gefunden.`, e);
                }
            }

            return "";
        },

        /**
         * Löst die Aktionen eines ToggleButtons aus.
         *
         * @param {Object} button Das von der ToggleControlComponent emittierte Button-Objekt.
         * @param {Function} showLoader Eine Methode zum anzeigen einer Ladeanimation.
         * @param {Function} hideLoader Eine Methode zum verbergen einer Ladeanimation.
         */
        async onClickToggleButton(button, showLoader, hideLoader) {
            const actions = button.actions || [];
            const row = button.row;
            const aLen = actions.length - 1;
            let error = false;

            showLoader();

            for (const [inx, actionObject] of actions.entries()) {
                const method = actionObject.action || "get";
                const filters = this.filters().json;
                const commonVariables = [...FilterUtils.mapJson(row), ...filters];
                const endpoint = `${this.$config.get(`services.${actionObject.service}.baseUrl`)}${actionObject.url || ""}`;
                const url = this.parameters().resolve(endpoint, commonVariables);

                try {
                    await remote({ url, method, request: this.$request });
                } catch (e) {
                    error = e;

                    Logger.error(e);
                } finally {
                    if (inx === aLen) {
                        if (!error) {
                            let rId = row.id;
                            let rInx = _.findIndex(this.rows, ["id", rId]);

                            try {
                                this.rows[rInx] = await fetchRow.call(this, rId, {});
                            } catch (e) {
                                Logger.error(e);
                            } finally {
                                hideLoader();
                                // ToDo: Zeile hervorheben: Fehler! + notification (vgl. Argos.Admin).
                            }
                        } else {
                            hideLoader();
                            // ToDo: Zeile hervorheben: Fehler! + notification (vgl. Argos.Admin).
                        }
                    }
                }
            }
        },

        /**
         * Aktualisiert die DataView - Konfiguration.
         *
         * @returns {Promise<Record<string, unknown>} Die Toolbarkonfigur
         */
        async fetchToolbar() {
            try {
                let response = await fetchConfig.call(this, false);

                return _.get(response, "toolbar", []);
            } catch (e) {
                if (!this.$request.hasCancelled(e))
                    Logger.error("Die Konfiguration der DataView konnte nicht abgerufen werden:", e);
            }

            return [];
        },

        /**
         * Aktualisiert die Toolbar.
         */
        updateToolbar() {
            this.toolbar.left = [];
            this.toolbar.right = [btnRefresh];

            this.config.toolbar = {};

            return new Promise(resolve => {
                nextTick(async () => {
                    this.config.toolbar = await this.fetchToolbar();

                    this.toolbar.left = this.config.toolbar.elements ?? [];
                    this.toolbar.right = [
                        ...this.config.toolbar.rightElements ?? [],
                        btnRefresh
                    ];
                    resolve();
                });
            });
        },

        /**
         * Aktualisiert die Daten der DataView (inkl. Toolbar).
         */
        async refreshView() {
            this.request.fetching = true;
            this.request.error.raised = false;

            try {
                let response = await fetchConfig.call(this, this.cachable);

                this.config = response;
                this.toolbar.left = this.config.toolbar.elements;
                this.toolbar.right = [
                    ...(this.config.toolbar.rightElements ?? []),
                    btnRefresh
                ];

                /**
                 * @type {[key:ClientScope]: () => FilterModel[]}
                 */
                const getFilters = {
                    "component": () => this.cache.get("filters") || [],
                    "menu": () => this.cache.id(this.$page.left.id()).get("filters") || [],
                    "page": () => this.cache.id(this.$page.top.id()).get("filters") || []
                };

                /**
                 * @type {[key:ClientScope]: (filters: FilterModel[]) => void}
                 */
                const setFilters = {
                    "component": filters => this.cache.set("filters", filters),
                    "menu": filters => this.cache.id(this.$page.left.id()).set("filters", filters),
                    "page": filters => this.cache.id(this.$page.top.id()).set("filters", filters)
                };

                const layoutFilters = FilterUtils.mapFilters(this.layout.filters || []);

                layoutFilters.forEach(({ name, scope, value, operator }) => {
                    const cachedFilters = getFilters[scope || "component"]();
                    const cachedFilter = cachedFilters.find((filter) => filter.name === name);

                    if (cachedFilter)
                        cachedFilter.value = value;
                    else
                        cachedFilters.push(new FilterModel({ name, value, scope, operator }));

                    setFilters[scope || "component"](cachedFilters);
                });


                ([...this.toolbar.left, ...this.toolbar.right])
                    .filter(({ $type, filters }) => $type === "select" && filters && filters.length)
                    .map(({ filters, selected }) => {
                        return {
                            filters,
                            selected
                        }
                    })
                    .forEach(({ filters, selected }) => {
                        filters.forEach((filter) => {
                            const clonedFilter = JSON.parse(JSON.stringify(filter));
                            const cachedFilters = getFilters[filter.scope || "component"]();

                            if (cachedFilters.find(({ name }) => name === filter.name))
                                return setFilters[filter.scope || "component"](cachedFilters);

                            clonedFilter.value = selected;

                            cachedFilters.push(new FilterModel(clonedFilter));

                            setFilters[filter.scope || "component"](cachedFilters);
                        });
                    });

                let ordering = this.mode !== "select"
                    ? (this.cache.get("order") || [[]])[0]
                    : [];

                if (ordering && ordering.length === 0) {
                    ordering = _.map(_.get(this.config, "ordering", []), orderObj => {
                        return {
                            property: orderObj.member,
                            order: orderObj.order
                        };
                    });
                }

                let page = this.cache.get("page") || 0;
                let cacheSelected = this.cache.get("selected") || { index: -1, row: null, ids: [] };

                if (cacheSelected.index > -1)
                    page = Math.ceil(cacheSelected.index / this.pageSize());

                this.cache.set("order", [ordering]);
                this.ordering = ordering;
                this.cache.set("page", page);

                let columns = _.dropRightWhile(_.cloneDeep(this.config.columns), column => {
                    return typeof column.visible !== "undefined" && column.visible === false
                });

                let lastColumn = columns.length > 0 ? columns[columns.length - 1] : null;
                let appendColumn = lastColumn && lastColumn.alignment && lastColumn.alignment !== "left";

                if (appendColumn) {
                    columns.push({
                        isPlaceholder: true,
                        visible: true,
                        alignment: "left",
                        header: "",
                        member: null
                    });
                }

                this.columns = columns;
                this.page = page;

                try {
                    this.rows = [];

                    await vueDomUpdated();
                    await fetchRows.call(this, page, false, true);

                    this.request.fetching = false;

                    await createScrollbar.call(this);

                    if (this.mode === "select")
                        this.scrollInstance.scrollVertical();

                    this.$emit("loaded", true);
                } catch (e) {
                    if (this.$request.hasCancelled(e))
                        this.$emit("loaded", true);
                    else if (this.$request.isDoubleRequest(e)) {
                        return;
                    } else {
                        this.request.error.raised = true;
                        this.$emit("error", true);
                    }
                }

                if (this.mode === "select") {
                    const buttons = [...this.toolbar.left, ...this.toolbar.right];
                    const toolbarIndex = buttons.findIndex(button => button.$type === "search");

                    toolbarIndex > -1 && this.$refs.toolbar.items && this.$refs.toolbar.items.at(toolbarIndex).$el.querySelector("input").focus();
                }
            } catch (e) {
                if (this.$request.hasCancelled(e)) this.$emit("loaded", true);
                else if (!this.$request.isDoubleRequest(e)) {
                    this.request.error.raised = true;
                    this.$emit("error", true);

                    Logger.error("Fehler nach Ladevorgang:", e);
                }
            }
        },

        /**
         * Selektiert eine Zeile in Abhängigkeit der zuletzt ausgewählten Entitäts-Id.
         */
        selectLastItem() {
            let lastRowId = _.get(this.selected, "row.id", -1);
            let lastIndex = _.findIndex(this.rows, { id: lastRowId })

            if (lastIndex > -1)
                this.navigation && this.navigation.select(lastIndex);
            else
                this.navigation && this.navigation.deselectAll();
        },

        /**
         * Scrollt die Tabelle zum ausgewählten oder übergebenen Zeilenindex.
         *
         * @param {Number} [index=-1] Der Zeilenindex, zu dem gescrollt werden soll.
         */
        scrollToSelected(index) {
            if (!this.scrollInstance)
                return;

            index = index !== undefined ? index : (this.cache.get("selected") || { index: -1, row: [] }).index;

            if (this.$refs.tableBody && this.$refs.tableHead && index > -1) {
                let row = $(this.$refs.tableBody).find(`tr:nth-child(${index + 1})`).get(0);
                let tHeadHeight = $(this.$refs.tableHead).get(0).getBoundingClientRect().height;

                this.page = Math.ceil(index / this.pageSize());
                this.scrollInstance.scrollTo(row, tHeadHeight, false);
            }
        },

        /**
         * Liefert die Bezeichnung der Tabelle für das Logging.
         *
         * @param {String} [prefix="data-view"] Der prefix des auslösenden Steuerelements.
         * @return {String} Die Bezeichnung.
         */
        getApmName(prefix) {
            let name = _.get(this.layout, "services.data.urls.create");

            if (!prefix) prefix = "data-view";

            return `${prefix}${name ? `-${name}` : ''}`;
        }
    },

    async beforeUnmount() {
        this.unregisterNotificationEvents();
        this.cancelRequests();

        MessageProvider.detach(Constant.System.NOTIFICATION.MENU_SWITCH, this.componentId);
        MessageProvider.detach(Constant.System.NOTIFICATION.TAB_SWITCH, this.componentId);

        window.removeEventListener("resize", this.debounced);
        window.removeEventListener("orientationchange", this.debounced);

        document.removeEventListener("keyup", this.copy);
        document.removeEventListener("keyup", this.cut);
        document.removeEventListener("keyup", this.paste);

        this.scrollInstance && this.scrollInstance.destroy();

        if (this.layout.clipboard && this.layout.clipboard.topic)
            Clipboard.detach(this.layout.clipboard.topic, this.componentId);

        if (this.mode === "select")
            this.cache.set("filters", []);
    },

    unmounted() {
        // Zeilennavigation aus dem Dom entfernen.
        if (this.navigation)
            this.navigation.destroy();
    }
};

export default DataView;
</script>

<style lang="scss">
$tableHeader_height: 26px;

.panel-dataView {
    >.container {
        overflow: hidden;
    }
}

.data-view {
    position: relative;
    float: left;
    width: 100%;

    .scrollcontainer {
        width: calc(100% - 2px) !important;
        margin-left: 1px;

        .scrollbars .scrollvert .scrollthumb,
        .scrollbars .scrollhoz .scrollthumb {
            background-color: var(--color-dataview-scrollthumb);
        }
    }

    &:focus {
        outline: none;
    }

    .table-header {
        position: relative;
        float: left;
        width: 100%;
        overflow: hidden;
    }

    .table {
        &__measurement {
            position: fixed;
            z-index: 10;
            visibility: hidden;
            pointer-events: none;
            touch-action: none;
            height: auto;
            top: -50000px;
            left: -50000px;
        }
    }

    table {
        position: relative;
        float: left;
        border-spacing: 0px 0px;
        width: 100%;
        border: none;
        table-layout: auto;
        height: auto;
        border-collapse: separate;
        overflow: hidden;

        thead {
            background-color: transparent;

            tr {
                min-width: 100%;
                white-space: nowrap;

                >span {
                    display: block;
                }

                th {
                    margin: 0px;
                    padding: 0px;
                    height: 36px;
                    text-align: left;
                    cursor: default;

                    &:last-of-type {
                        width: 100%;

                        >span {
                            width: calc(100% - 12px);
                        }
                    }

                    >span {
                        width: auto;
                        text-transform: uppercase;
                        color: var(--color-table-header);
                        font-size: 0.6875em;
                        font-weight: 600;
                        display: inline-block;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0px 6px;

                        text-align: center;

                        >span:not(.order) {
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 20px;
                        }
                    }

                    .order {
                        display: none;

                        background-color: transparent;
                        outline: none;
                        border: none;
                        width: 20px;
                        max-width: 100%;
                        text-align: left;
                        padding: 0px 0px 0px 4px;

                        &.\-from-left {
                            margin-right: 4px;
                        }

                        &:focus,
                        &:active {
                            outline: none;
                        }

                        /** BEM - Block: Icon */
                        .icon {

                            /** BEM - Element: Der Icon-Wrapper */
                            &__wrapper {
                                line-height: 6px;
                                font-size: 0.625em;
                                width: 100%;
                                display: none;
                                flex-direction: column;
                                background-repeat: no-repeat;
                                align-items: center;
                                justify-content: center;
                            }

                            /** BEM - Element: Der Html Container für das darzustellende Icon */
                            &__html {
                                width: 9px;
                                height: 7px;

                                /** HTML - Element: SVG Element (Der Icon-Inhalt) */
                                >svg {
                                    // vertical-align: top;

                                    path {
                                        fill: var(--color-table-header-icon);
                                        stroke: none;
                                    }
                                }
                            }

                            /**
                             * BEM - Modifizierer:
                             *
                             * - Icon aufsteigend
                             * - Icon absteigend
                             */
                            &--asc,
                            &--desc {
                                display: none;
                            }
                        }

                        &.ascending,
                        &.descending {
                            display: inline-block;

                            &.no-padding {
                                padding: 0;
                            }
                        }

                        &.ascending {

                            /** BEM - Modifizierer: Icon aufsteigend */
                            .icon--asc {

                                /** BEM - Element: Der Icon-Wrapper */
                                &.icon__wrapper {
                                    display: flex;
                                }
                            }

                            /** BEM - Modifizierer: Icon absteigend */
                            .icon--desc {

                                /** BEM - Element: Der Icon-Wrapper */
                                &.icon__wrapper {
                                    display: none;
                                }
                            }
                        }

                        &.descending {

                            /** BEM - Modifizierer: Icon aufsteigend */
                            .icon--asc {

                                /** BEM - Element: Der Icon-Wrapper */
                                &.icon__wrapper {
                                    display: none;
                                }
                            }

                            /** BEM - Modifizierer: Icon absteigend */
                            .icon--desc {

                                /** BEM - Element: Der Icon-Wrapper */
                                &.icon__wrapper {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .loading-animation {
        position: absolute;
        height: calc(100% - 80px);
        bottom: 0;
        background-color: $white;
        z-index: 2;

        .messages,
        span.error {
            color: var(--color-foreground);
        }
    }

    &__border {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        bottom: 0px;
        overflow: auto;

        border-radius: 4px;

        background: transparent;
        pointer-events: none;
        touch-action: none;

        z-index: 2;
    }

    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        bottom: 0px;
        border-radius: 4px;
        background: #FFFFFF;
    }

    .table {
        position: relative;
        float: left;
        width: 100%;
        height: 100%;
        z-index: 1;

        overflow: auto;

        &.sticky {
            overflow: hidden;

            table {
                background: #FFFFFF;
                overflow: unset;

                thead {
                    tr {
                        th {
                            position: sticky;
                            top: 0px;
                            height: 36px;
                            vertical-align: middle;
                            z-index: 2;
                            background-color: var(--color-background);
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }

    table {
        position: relative;
        float: left;
        border-spacing: 0px 0px;
        width: 100%;
        border: none;
        table-layout: auto;
        border-collapse: separate;
        overflow: hidden;

        tbody {
            tr {
                position: relative;
                min-width: 100%;
                white-space: nowrap;
                background-color: transparent;
                transition: background-color 250ms ease-out;

                .tr-pending {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: white;
                    overflow: hidden;
                    opacity: 0;
                    pointer-events: none;
                    touch-action: none;

                    &--show {
                        opacity: 0;
                        animation: trFadeInOut 1750ms infinite 250ms ease-out;
                    }
                }

                &:first-child {
                    td {
                        border-top: none;
                    }
                }

                &:hover,
                &.selected {
                    transition: background-color 250ms ease-out;

                    td {
                        transition: border-color 250ms ease-out;
                    }

                    &:first-child {
                        td {
                            border-top: none;
                        }
                    }
                }

                .toggle-button {
                    width: 22px;
                }

                &.next-preselect {
                    td {
                        border-bottom: 1px var(--color-dataview-border-preselect) solid;
                    }
                }

                &.preselect {
                    td {
                        border-bottom: 1px var(--color-dataview-border-preselect) solid;
                    }

                    &.selected {
                        box-shadow: none;

                        td {
                            border-bottom: 1px var(--color-dataview-border-preselect) solid;
                        }
                    }
                }

                /** BEM - Modifizierer: Span als Link darstellen */
                .data-view__span--link {
                    >* {
                        cursor: pointer;
                    }
                }

                &:hover {
                    background-color: var(--color-dataview-hover-bg);

                    /** BEM - Modifizierer: Span als Link darstellen */
                    .data-view__span--link {
                        text-decoration: underline;
                    }
                }

                &.selected {
                    background-color: var(--color-dataview-selected-bg);

                    &.next-preselect {
                        td {
                            border-bottom: 1px var(--color-dataview-border-preselect) solid;
                        }
                    }
                }

                &.cutted {
                    opacity: 0.75;
                }

                td {
                    margin: 0px;
                    padding: 0px;
                    height: auto;
                    text-align: left;
                    border-top: none;
                    border-bottom: 1px hsl(205.7, 25.9%, 94.7%) solid;

                    transition: border-color 250ms ease-out;

                    .icon-only {

                        /** BEM - Element: Der Icon-Wrapper */
                        &.icon__wrapper {
                            width: 100%;
                            height: calc(#{$font-size-body} * 1.8);
                        }

                        /** BEM - Element: Der Html Container für das darzustellende Icon */
                        .icon__html {
                            width: 100%;
                            height: 100%;
                            max-height: 17px;
                        }
                    }

                    &:last-of-type {
                        width: 100%;

                        >span {
                            width: calc(100% - 12px);
                        }
                    }

                    >span {
                        display: flex;
                        font-size: 0.75em;
                        color: var(--color-font-2);

                        overflow: hidden;
                        height: calc(#{$font-size-body} * 1.8);
                        align-items: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        margin: 0 6px;
                        padding-top: 0px;

                        text-align: center;

                        &.ordered {
                            color: var(--color-dataview-ordered-text);
                            font-weight: 500;
                        }
                    }

                    .toggle-button {
                        width: 22px;
                    }
                }
            }
        }
    }

    /** BEM - Modifizierer: Sortierbare Tabelle */
    &--sortable {
        table {
            thead {
                tr {
                    th {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__total {
        position: absolute;
        display: flex;

        padding-right: 6px;
        padding-left: 6px;

        right: 1px;
        top: 46px;
        width: auto;
        min-width: 60px;
        height: 29px;

        font-size: 0.75em;

        justify-content: center;
        align-items: center;

        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        color: $color-font-2;
        background: $white;
        z-index: 4;
    }
}
</style>
