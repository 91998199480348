import ToolkitTool from "../ToolkitTool";

type Params<T> = {
    /**
     * Die Funktion, die ausgeführt werden soll
     *
     * @returns Das Ergebnis der Ausführung der Funktion
     */
    callback: () => T;

    /**
     * Eine Callback-Funktion, die bei einer Wiederholung ausgeführt wird
     */
    onRetry?: (retries: number) => void;

    /**
     * Der Intervall zwischen den Wiederholungen in Millisekunden
     */
    interval?: number;

    /**
     * Die maximale Anzahl der Wiederholungen
     */
    maxRetries: number;
}

class RetryTool extends ToolkitTool {
    /**
     * Die aktuelle Anzahl der Wiederholungen
     */
    private retries: number = 0;

    /**
     * Die Funktion, die ausgeführt werden soll
     */
    private callback?: () => unknown;

    /**
     * Eine Callback-Funktion, die bei einer Wiederholung ausgeführt wird
     */
    private onRetry?: (retries: number) => void;

    /**
     * Der Intervall zwischen den Wiederholungen in Millisekunden (default: 1000)
     */
    private interval?: number;

    /**
     * Die maximale Anzahl der Wiederholungen (default: 2)
     */
    private maxRetries?: number;

    /**
     * Führt die Funktion aus und wiederholt sie bei einem Fehler
     *
     * @returns Das Ergebnis der Ausführung der Funktion
     */
    public async retry<T>({ callback, onRetry, interval = 1000, maxRetries = 2 }: Params<T>): Promise<T | undefined> {
        if (!callback)
            return;

        this.callback = callback;
        this.onRetry = onRetry;

        this.interval = interval;
        this.maxRetries = maxRetries;

        return await this.tryCallback<T>();
    }


    /**
     * Führt die Funktion aus und wiederholt sie bei einem Fehler
     *
     * @returns Das Ergebnis der Ausführung der Funktion
     */
    private async tryCallback<T>(): Promise<T | undefined> {
        if (this.retries > 0)
            this.onRetry?.(this.retries);

        try {
            const result = this.callback?.();

            return result instanceof Promise
                ? await result
                : result as T;
        } catch (error) {
            if (this.retries === this.maxRetries)
                return;

            this.retries++;

            return new Promise(resolve =>
                setTimeout(async () => resolve(await this.tryCallback()), this.interval)
            );
        }
    }
}

export default RetryTool;
