/**
 * Ein Knoten in einer doppelt verketteten {@link LinkedList}.
 */
export class LinkedNode<T> {
    /**
     * Der Wert des Knotens.
     */
    public value: T;

    /**
     * Der Nachfolger des Knotens.
     */
    public next: LinkedNode<T> | null = null;

    /**
     * Der Vorgänger des Knotens.
     */
    public prev: LinkedNode<T> | null = null;

    /**
     * Der aktuelle Index des Knotens in der Liste.
     */
    public index: number = 0;

    /**
     * C'tor.
     *
     * @param value Der Wert des Knotens.
     */
    constructor(value: T) {
        this.value = value;
    }
}

/**
 * Eine doppelt verkettete Liste.
 */
export default class LinkedList<T> {
    /**
     * Der Anfangsknoten der Liste.
     */
    public head: LinkedNode<T> | null = null;

    /**
     * Der Endknoten der Liste.
     */
    public tail: LinkedNode<T> | null = null;

    /**
     * Die private Eigenschaft für die Länge der Liste.
     */
    private _size = 0;

    /**
     * Aktualisiert die Indizes aller Knoten in der Liste.
     * @private
     */
    private updateIndices(): void {
        let current = this.head;
        let index = 0;

        while (current) {
            current.index = index++;
            current = current.next;
        }
    }

    /**
     * Die Länge der Liste.
     */
    public get size() {
        return this._size;
    }

    /**
     * Fügt ein Element am Ende der verketteten Liste hinzu.
     *
     * @param value Das Element, das hinzugefügt werden soll.
     * @returns Die verkettete Liste.
     */
    public append(value: T): LinkedList<T> {
        const newNode = new LinkedNode(value);
        newNode.index = this._size; // Neuer Knoten bekommt den Index am Ende

        if (this.tail) {
            // Bestehende Liste: Verbinde neuen Knoten mit dem aktuellen Tail
            newNode.prev = this.tail;
            this.tail.next = newNode;
            this.tail = newNode;
        } else {
            // Leere Liste: Setze Head und Tail auf den neuen Knoten
            this.head = this.tail = newNode;
        }

        this._size++;
        return this;
    }

    /**
     * Fügt ein Element am Anfang der verketteten Liste hinzu.
     *
     * @param value Das Element, das hinzugefügt werden soll.
     * @returns Die verkettete Liste.
     */
    public prepend(value: T): LinkedList<T> {
        const newNode = new LinkedNode(value);
        newNode.index = 0; // Neuer Knoten bekommt Index 0

        if (this.head) {
            // Bestehende Liste: Verbinde neuen Knoten mit dem aktuellen Head
            newNode.next = this.head;
            this.head.prev = newNode;
            this.head = newNode;

            // Alle nachfolgenden Indizes um 1 erhöhen
            this.updateIndices();
        } else {
            // Leere Liste: Setze Head und Tail auf den neuen Knoten
            this.head = this.tail = newNode;
        }

        this._size++;
        return this;
    }

    /**
     * Fügt ein Element an einer bestimmten Position in die verkettete Liste ein.
     *
     * @param value Das Element, das hinzugefügt werden soll.
     * @param index Die Position, an der das Element hinzugefügt werden soll.
     * @returns Die verkettete Liste.
     */
    public insert(value: T, index: number): LinkedList<T> {
        if (index < 0 || index > this._size)
            throw new Error(`Index ${index} out of bounds`);

        // Spezialfälle für Anfang und Ende
        if (index === 0)
            return this.prepend(value);
        if (index === this._size)
            return this.append(value);

        // Optimierung: Suche vom nächsten Ende aus für bessere Performance
        const fromStart = index < this._size / 2;

        const newNode = new LinkedNode(value);
        newNode.index = index; // Setze Index des neuen Knotens

        let current: LinkedNode<T> | null;

        if (fromStart) {
            // Vom Anfang traversieren
            current = this.head;
            for (let i = 0; i < index - 1; i++)
                current = current!.next;

            // Verbinde neuen Knoten
            newNode.next = current!.next;
            newNode.prev = current;

            if (current!.next)
                current!.next.prev = newNode;

            current!.next = newNode;
        } else {
            // Vom Ende traversieren
            current = this.tail;
            for (let i = this._size - 1; i > index; i--)
                current = current!.prev;

            // Verbinde neuen Knoten
            newNode.prev = current!.prev;
            newNode.next = current;

            if (current!.prev)
                current!.prev.next = newNode;

            current!.prev = newNode;
        }

        this._size++;

        // Aktualisiere alle nachfolgenden Indizes
        this.updateIndices();

        return this;
    }

    /**
     * Gibt den Index des ersten Vorkommens eines Elements zurück.
     *
     * @param searchElement Das Element, nach dem gesucht werden soll
     * @param compareFn Optionale Funktion, die zwei Elemente vergleicht und true zurückgibt, wenn sie als gleich gelten
     * @returns Der Index des Elements oder -1, wenn es nicht gefunden wurde
     *
     * @example
     * // Suche nach einem einfachen Wert
     * const index = list.indexOf(42);
     *
     * // Suche mit benutzerdefinierter Vergleichsfunktion
     * const index = list.indexOf(targetObj, (a, b) => a.id === b.id);
     */
    public indexOf(searchElement: T, compareFn?: (a: T, b: T) => boolean): number {
        let current = this.head;

        while (current) {
            // Verwende die Vergleichsfunktion, wenn eine angegeben wurde,
            // sonst direkter Wertevergleich
            const isMatch = compareFn
                ? compareFn(current.value, searchElement)
                : current.value === searchElement;

            if (isMatch) {
                return current.index;
            }
            current = current.next;
        }

        return -1;
    }

    /**
     * Entfernt das erste Element aus der verketteten Liste und gibt es zurück.
     *
     * @returns Das entfernte Element oder `null`, wenn die Liste leer ist.
     */
    public removeFirst(): T | null {
        if (!this.head) return null;

        const value = this.head.value;

        if (this.head === this.tail) {
            // Nur ein Element: Liste leeren
            this.head = this.tail = null;
        } else {
            // Mehrere Elemente: Head aktualisieren und Verbindung trennen
            this.head = this.head.next;
            if (this.head)
                this.head.prev = null;

            // Aktualisiere Indizes
            this.updateIndices();
        }

        this._size--;
        return value;
    }

    /**
     * Entfernt das letzte Element aus der verketteten Liste und gibt es zurück.
     *
     * @returns Das entfernte Element oder `null`, wenn die Liste leer ist.
     */
    public removeLast(): T | null {
        if (!this.tail) return null;

        const value = this.tail.value;

        if (this.head === this.tail) {
            // Nur ein Element: Liste leeren
            this.head = this.tail = null;
        } else {
            // Mehrere Elemente: Tail aktualisieren und Verbindung trennen
            this.tail = this.tail.prev;
            if (this.tail)
                this.tail.next = null;

            // Bei Entfernen vom Ende müssen keine Indizes aktualisiert werden
        }

        this._size--;
        return value;
    }

    /**
     * Entfernt das Element an einer bestimmten Position aus der verketteten Liste und gibt es zurück.
     *
     * @param index Die Position des Elements, das entfernt werden soll.
     * @returns Das entfernte Element oder wirft einen Fehler, wenn der Index außerhalb der Grenzen liegt.
     */
    public removeAt(index: number): T {
        if (index < 0 || index >= this._size)
            throw new Error(`Index ${index} out of bounds`);

        if (index === 0)
            return this.removeFirst()!;

        if (index === this._size - 1)
            return this.removeLast()!;

        // Optimierung: Suche vom nächsten Ende aus für bessere Performance
        const fromStart = index < this._size / 2;
        let current: LinkedNode<T> | null;

        if (fromStart) {
            // Vom Anfang traversieren
            current = this.head;
            for (let i = 0; i < index; i++)
                current = current!.next;
        } else {
            // Vom Ende traversieren
            current = this.tail;
            for (let i = this._size - 1; i > index; i--)
                current = current!.prev;
        }

        // Knoten entfernen und Verbindungen neu verknüpfen
        const value = current!.value;

        if (current!.prev)
            current!.prev.next = current!.next;

        if (current!.next)
            current!.next.prev = current!.prev;

        this._size--;

        // Aktualisiere nachfolgende Indizes
        this.updateIndices();

        return value;
    }

    /**
     * Gibt das Element an einer bestimmten Position zurück.
     *
     * @param index Die Position des Elements.
     * @returns Das Element oder null, wenn der Index ungültig ist.
     */
    public get(index: number): T | null {
        if (index < 0 || index >= this._size) return null;

        // Optimierung: Suche vom nächsten Ende aus für bessere Performance
        const fromStart = index < this._size / 2;
        let current: LinkedNode<T> | null;

        if (fromStart) {
            // Vom Anfang traversieren
            current = this.head;
            for (let i = 0; i < index; i++)
                current = current!.next;
        } else {
            // Vom Ende traversieren
            current = this.tail;
            for (let i = this._size - 1; i > index; i--)
                current = current!.prev;
        }

        return current!.value;
    }

    /**
     * Findet das erste Element in der Liste, das dem angegebenen Suchkriterium entspricht.
     *
     * @param predicate Eine Funktion, die auf jedes Element angewendet wird und true zurückgibt, wenn das Element gefunden wurde
     * @returns Das gefundene Element oder null, wenn keines gefunden wurde
     *
     * @example
     * // Finde das erste Element mit Wert > 5
     * const value = list.find(item => item > 5);
     *
     * // Finde das erste Element mit bestimmten Eigenschaften
     * const node = list.find(node => node.id === "target");
     */
    public find(predicate: (value: T, index?: number) => boolean): T | null {
        let current = this.head;

        while (current) {
            if (predicate(current.value, current.index)) {
                return current.value;
            }
            current = current.next;
        }

        return null;
    }

    /**
     * Findet den Knoten des ersten Elements in der Liste, das dem angegebenen Suchkriterium entspricht.
     *
     * @param predicate Eine Funktion, die auf jedes Element angewendet wird und true zurückgibt, wenn das Element gefunden wurde
     * @returns Der gefundene Knoten oder null, wenn keiner gefunden wurde
     */
    public findNode(predicate: (value: T, index?: number) => boolean): LinkedNode<T> | null {
        let current = this.head;

        while (current) {
            if (predicate(current.value, current.index)) {
                return current;
            }
            current = current.next;
        }

        return null;
    }

    /**
     * Findet alle Elemente in der Liste, die dem angegebenen Suchkriterium entsprechen.
     *
     * @param predicate Eine Funktion, die auf jedes Element angewendet wird und true zurückgibt, wenn das Element gefunden wurde
     * @returns Ein Array mit allen gefundenen Elementen oder ein leeres Array
     */
    public filter(predicate: (value: T, index?: number) => boolean): T[] {
        const result: T[] = [];
        let current = this.head;

        while (current) {
            if (predicate(current.value, current.index)) {
                result.push(current.value);
            }
            current = current.next;
        }

        return result;
    }

    /**
     * Wandelt die Liste in ein Array um.
     *
     * @returns Ein Array mit allen Werten der Liste.
     */
    public toArray(): T[] {
        const result = new Array<T>(this._size);
        let current = this.head;
        let index = 0;

        while (current) {
            result[index++] = current.value;
            current = current.next;
        }

        return result;
    }

    /**
     * Leert die Liste.
     */
    public clear(): void {
        this.head = null;
        this.tail = null;
        this._size = 0;
    }

    /**
     * Implementiert den Iterator für for...of Schleifen.
     */
    public *[Symbol.iterator](): Iterator<T> {
        let current = this.head;
        while (current) {
            yield current.value;
            current = current.next;
        }
    }

    /**
     * Erzeugt einen Generator für die Liste.
     */
    public* generator() {
        let current = this.head;

        while (current) {
            yield current;
            current = current.next;
        }
    }
}
