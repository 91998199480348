import Toolkit from "..";
import ToolkitTool from "../ToolkitTool";

export type CssText = CSSStyleDeclaration["cssText"];

/**
 * Hilfsklasse für die Verarbeitung von Stilen
 */
class StyleTool extends ToolkitTool {
    /**
     * Ein Tool zur Bereinigung von Stilen
     */
    private sanitizeTool = Toolkit.tool("sanitize");

    /**
     * Weist die Stileigenschaften des Elternelements dem Kindelement zu.
     *
     * @param parentStyle Die Stileigenschaften des Elternelements
     * @param childStyle Die Stileigenschaften des Kindelements
     * @param overwrite Gibt an, ob die Stileigenschaften des Kindelements überschrieben werden sollen
     *
     * @returns Die zugewiesenen Stileigenschaften
     */
    private assignStyles(parentStyle?: Partial<Record<keyof CSSStyleDeclaration, string>>, childStyle?: Partial<Record<keyof CSSStyleDeclaration, string>>, overwrite = false): Partial<Record<keyof CSSStyleDeclaration, string>> {
        if (!parentStyle && !childStyle)
            return {} as Record<keyof CSSStyleDeclaration, string>;

        if (!childStyle || Object.keys(childStyle).length === 0)
            return parentStyle!;

        if (!parentStyle || Object.keys(parentStyle).length === 0)
            return childStyle!;

        if (overwrite) {
            // Alle Stileigenschaften des Elternelements werden in die Stileigenschaften des Kindelements übertragen
            Object
                .entries(parentStyle)
                .forEach(([prop, _]) =>
                    (childStyle as any)[this.sanitizeTool.camelToKebab(prop) as keyof CSSStyleDeclaration] = (parentStyle as any)[this.sanitizeTool.camelToKebab(prop) as keyof CSSStyleDeclaration]
                );

            return childStyle;
        }

        // Alle Stileigenschaften des Elternelements, die im Kindelement vorhanden sind, werden in den Stileigenschaften des Elternelements aktualisiert
        Object
            .entries(parentStyle)
            .filter(([prop, _]) => childStyle.hasOwnProperty(prop))
            .forEach(([prop, _]) =>
                (parentStyle as any)[this.sanitizeTool.camelToKebab(prop) as keyof CSSStyleDeclaration] = (childStyle as any)[this.sanitizeTool.camelToKebab(prop) as keyof CSSStyleDeclaration]
            );

        // Alle Stileigenschaften des Kindelements, die nicht im Elternelement vorhanden sind, werden hinzugefügt
        Object
            .entries(childStyle)
            .forEach(([prop, value]) =>
                (parentStyle as any)[this.sanitizeTool.camelToKebab(prop) as keyof CSSStyleDeclaration] = value
            );

        return parentStyle;
    }

    public applyStyles(element: HTMLElement, styles: Partial<Record<keyof CSSStyleDeclaration, string>>) {
        Object.entries(styles).forEach(([prop, value]) => {
            if (!value)
                element.style.removeProperty(this.sanitizeTool.camelToKebab(prop));
            else
                element.style.setProperty(this.sanitizeTool.camelToKebab(prop), value);
        });
    }

    /**
     * Kopiert Stileigenschaften des Elternelements in die Kindelemente.
     * Vorhandene Stile im Kindelement werden nicht überschrieben,
     * wenn {@link overwrite} auf false gesetzt ist.
     *
     * @example
     * // Quellmarkup:
     * <span style="color: blue">
     *      Lorem
     *      <span>
     *          ipsum
     *          <span style="color: green">
     *              dolor
     *              <span style="font-weight: normal">sit</span>
     *          </span>
     *      </span>
     *      amet
     * </span>
     *
     * // Zielmarkup:
     *
     * <span style="color: blue">
     *      Lorem
     *      <span style="color: blue">
     *          ipsum
     *          <span style="color: green; font-weight: bold">
     *              dolor
     *              <span style="color: green; font-weight: normal">sit</span>
     *          </span>
     *      </span>
     *      amet
     * </span>
     *
     * @param element Der Startknoten
     * @param styles Die anzuwendenden Stileigenschaften
     * @param overwrite Gibt an, ob die Stileigenschaften des Kindelements überschrieben werden sollen
     * @param ignoreTags Tags, die ignoriert werden sollen
     */
    public copyToChildren(element: HTMLElement | DocumentFragment, styles: Partial<Record<keyof CSSStyleDeclaration, string>> = {}, overwrite = false, ignoreTags: Partial<keyof HTMLElementTagNameMap>[] = []) {
        if (element.nodeType !== Node.DOCUMENT_FRAGMENT_NODE && element.nodeType !== Node.ELEMENT_NODE)
            return;

        const typeGuard = Toolkit.tool("typeGuard");

        ignoreTags = [...ignoreTags, "br"];

        Array
            .from((element as Element).children)
            .forEach(child => {
                if (ignoreTags.some(tag => typeGuard.isTag(child, tag)))
                    return;

                const sourceStyles = Object.entries(styles).reduce<Partial<Record<keyof CSSStyleDeclaration, string>>>((styles, style) => {
                    return {...styles, [this.sanitizeTool.camelToKebab(style[0])]: style[1]};
                }, {});

                const mergedStyles = this.assignStyles(sourceStyles, this.styleToRecord((child as HTMLElement).style.cssText), overwrite);

                this.applyStyles(child as HTMLElement, mergedStyles);
                this.copyToChildren(child as HTMLElement, mergedStyles, overwrite, ignoreTags);
            });
    };

    /**
     * Kopiert rekursiv alle Stileigenschaften auf die Kindknoten des übergebenen Elements,
     * sofern der Kindknoten ein Elementknoten ist und die Stileigenschaften nicht bereits gesetzt ist.
     *
     * @param element Das Element mit den Stileigenschaften
     * @param ignoreTags Tags, die ignoriert werden sollen
     * @param ignoreStyleProperties Stileigenschaften, die ignoriert werden sollen
     */
    public copyStylesToChildren(element: HTMLElement | DocumentFragment, ignoreTags: Partial<keyof HTMLElementTagNameMap>[] = [], ignoreStyleProperties: Partial<keyof CSSStyleDeclaration>[] = []) {
        const typeGuard = Toolkit.tool("typeGuard");

        if (element instanceof DocumentFragment)
            return Array
                .from(element.childNodes)
                .filter(node => {
                    if (!typeGuard.isElement(node)) return false;

                    return !ignoreTags.some(tag => typeGuard.isTag(node, tag));
                })
                .forEach(node => {
                    this.copyStylesToChildren(node as HTMLElement, ignoreTags, ignoreStyleProperties);
                });

        const elementStyles = this.styleToRecord(element.style.cssText, ignoreStyleProperties);
        this.copyToChildren(element, elementStyles, false, ignoreTags);

        element.childNodes.forEach(child => {
            if (child.nodeType === Node.TEXT_NODE)
                return;

            this.copyStylesToChildren(child as HTMLElement, ignoreTags, ignoreStyleProperties);
        });
    }

    /**
     * Liefert die Stileigenschaften einer {@link Range}
     *
     * @param rangeOrNode Die {@link Range} oder ein {@link Node}, dessen Stileigenschaften ermittelt werden sollen
     * @param root Das Wurzelelement, dessen Stile nicht berücksichtigt werden sollen
     * @param kebabCase Gibt an, ob die Stileigenschaften in Kebab-Case zurückgegeben werden sollen. Wenn false, dann in Camel-Case
     *
     * @returns Die Stileigenschaften
     */
    public getStyles(rangeOrNode: Range | Node, root: HTMLElement = document.body, kebabCase = true): Partial<Record<keyof CSSStyleDeclaration, string>> {
        const domTool = Toolkit.tool("dom");
        const selectionTool = Toolkit.tool("selection");

        /**
         * Fügt Stileigenschaften zum {@link style} hinzu
         *
         * @param collectableStyles Die zu sammelnden Stileigenschaften
         */
        const collectStyles = (collectableStyles: Partial<Record<keyof CSSStyleDeclaration, string>>) => {
            // Jede Stileigenschaft zum Set hinzufügen, wenn sie noch nicht vorhanden ist
            Object.entries(collectableStyles).forEach(([prop, value]) => {
                if (!value) return; // Leere Werte ignorieren

                // Stileigenschaft initialisieren, wenn noch nicht vorhanden
                if (!styles[prop as keyof CSSStyleDeclaration])
                    styles[prop as keyof CSSStyleDeclaration] = new Set<string>();

                // Wert zum Set hinzufügen
                styles[prop as keyof CSSStyleDeclaration]!.add(value);
            });
        };

        const commonAncestorContainer = rangeOrNode instanceof Range
            ? rangeOrNode.commonAncestorContainer
            : selectionTool.commonAncestorContainer(rangeOrNode, rangeOrNode);

        const styles: Partial<Record<keyof CSSStyleDeclaration, Set<string | undefined>>> = {};
        const ancestorElement = commonAncestorContainer.nodeType !== Node.ELEMENT_NODE
            ? commonAncestorContainer.parentElement
            : commonAncestorContainer as HTMLElement;

        const ancestorStyles = ancestorElement !== root
            ? this.styleToRecord(ancestorElement?.style.cssText)
            : {};

        collectStyles(ancestorStyles);

        const nodes = commonAncestorContainer !== root
            ? domTool.getNodes(commonAncestorContainer).toArray()
            : [commonAncestorContainer];

        nodes.forEach(node => {
            // Nur Elementknoten haben Stile
            if (node.nodeType !== Node.ELEMENT_NODE) return;

            const element = node as HTMLElement;

            // Inline-Stile des Elements extrahieren
            const elementStyles = this.styleToRecord(element.style.cssText);

            collectStyles(elementStyles);
        });

        const result: Partial<Record<keyof CSSStyleDeclaration, string>> = {};

        // Sets in einfache Strings umwandeln (erster gefundener Wert wird verwendet)
        Object.entries(styles).forEach(([prop, valueSet]) => {
            if ((valueSet?.size ?? 0) === 0) return;

            const [firstValue] = Array.from(valueSet?.values() ?? []);

            const propToUse = kebabCase
                ? prop
                : this.sanitizeTool.kebabToCamel(prop as string);

            result[propToUse as keyof CSSStyleDeclaration] = firstValue;
        });

        return result;
    }

    /**
     * Entfernt alle Stileigenschaften, die den Wert {@link value} haben
     *
     * @param element Das Element, dessen Stileigenschaften bereinigt werden
     * @param value Der Wert, der entfernt werden soll
     * @param exclude Stileigenschaften, die nicht entfernt werden sollen
     */
    public removePropertiesByValue(element: HTMLElement, value: string, exclude: (keyof CSSStyleDeclaration)[] = []) {
        const styles = this.styleToRecord(element.style.cssText);

        Object
            .entries(styles)
            .forEach(([cssProperty, cssValue]) => {
                if (cssValue === value && !exclude.includes(cssProperty as keyof CSSStyleDeclaration))
                    element.style.removeProperty(cssProperty);
            });
    }

    /**
     * Prüft, ob zwei {@link CssText} gleich sind
     *
     * @param cssText1 Die erste {@link CssText}
     * @param cssText2 Die zweite {@link CssText}
     *
     * @returns Der Wert, ob die {@link CssText}e gleich sind
     */
    public cssTextEquals(cssText1: CssText, cssText2: CssText): boolean {
        const style1 = this.styleToRecord(cssText1);
        const style2 = this.styleToRecord(cssText2);

        if (Object.keys(style1).length !== Object.keys(style2).length) return false;

        return Object
            .entries(style1)
            .every(([prop, value]) => style2[prop as keyof CSSStyleDeclaration] === value);
    }

    /**
     * Konvertiert einen {@link CssText} in eine {@link Record} mit den Stileigenschaften
     *
     * @example
     * // Quelltext:
     * "font-size: 4em; color: red; font-weight: bold"
     *
     * // Zielrecord:
     * {
     *    "font-size": "4em",
     *    "color": "red",
     * }
     *
     * @param style Die {@link CssText}
     * @@param ignore Stileigenschaften, die ignoriert werden sollen
     *
     * @returns Der {@link Record} mit den Stileigenschaften
     */
    public styleToRecord(style?: CssText, ignore?: Partial<keyof CSSStyleDeclaration>[]): Record<keyof CSSStyleDeclaration, string> {
        if (!style)
            return {} as Record<keyof CSSStyleDeclaration, string>;

        if (!Array.isArray(ignore))
            ignore = [];

        return style
            .split(";")
            .filter(Boolean)
            .reduce((record, style) => {
                const [key, value] = style.split(":");
                if (ignore!.some(prop => prop === key.trim()))
                    return record;

                if (!record.hasOwnProperty(key))
                    (record as any)[this.sanitizeTool.camelToKebab(key.trim())] = value.trim();

                return record;
            }, {} as Record<keyof CSSStyleDeclaration, string>);
    }
}

export default StyleTool;
