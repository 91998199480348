import { resettableOnce } from "@/utils/Decorator/once";
import ToolkitTool from "../ToolkitTool";
import Logger from "@/utils/Logger";

/**
 * Ein Typ zur Beschreibung der möglichen Event-Namen
 */
type EventName =
  // Maus-Events
  | "click"
  | "dblclick"
  | "mousedown"
  | "mouseup"
  | "mousemove"
  | "mouseover"
  | "mouseout"
  | "mouseenter"
  | "mouseleave"
  | "contextmenu"

  // Tastatur-Events
  | "keydown"
  | "keyup"
  | "keypress"

  // Fokus-Events
  | "focus"
  | "blur"

  // Formular-Events
  | "change"
  | "input"
  | "submit"
  | "reset"

  // Fenster- und Dokument-Events
  | "resize"   // Wird ausgelöst, wenn die Fenstergröße geändert wird
  | "scroll"   // Wird ausgelöst, wenn ein Element gescrollt wird
  | "load"     // Wird ausgelöst, wenn die gesamte Seite geladen ist
  | "unload"   // Wird ausgelöst, wenn das Dokument entladen wird
  | "beforeunload" // Wird vor dem Entladen der Seite ausgelöst
  | "DOMContentLoaded" // Wird ausgelöst, wenn das HTML-Dokument vollständig geladen ist

  // Touch-Events
  | "touchstart"
  | "touchmove"
  | "touchend"
  | "touchcancel"

  // Drag-and-Drop-Events
  | "drag"
  | "dragstart"
  | "dragend"
  | "dragenter"
  | "dragleave"
  | "dragover"
  | "drop"

  // Sonstige Events
  | "wheel";   // Wird ausgelöst, wenn ein Mausrad bewegt wird

type EventCount = {
    amount: number
    listener: EventListenerOrEventListenerObject[]
}

type EventCounts = {
    [Key in EventName]: EventCount
}

type CustomEvents<N> =
    N extends Document ? keyof DocumentEventMap | "activeElement":
    N extends HTMLElement ? keyof HTMLElementEventMap :
    N extends Window ? keyof WindowEventMap : never;

type CustomOptions<T> = T extends keyof DocumentEventMap | keyof WindowEventMap | keyof HTMLElementEventMap
    ? AddEventListenerOptions | boolean | undefined
    : never

/**
 * Ein Interface zur Intellisenseunterstützung für den {@link ContentElementManager}
 * für den {@link resetRender}-Methodenaufruf des Dekorators {@link resettableOnce}
 */
interface EventTool {
    resetMonitorActiveElement(): void;
}

/**
 * Ein Toolkit-Tool, zur Bereitstellung von Hilfsmethoden für Ereignisse
 */
class EventTool extends ToolkitTool implements EventTool  {
    /**
     * Die Instanz des Event-Tools
     */
    private static Instance: EventTool;

    /**
     * Ein WeakMap zur Speicherung von Event-Listenern
     */
    private listeners = new WeakMap<
        Document | Window | HTMLElement,
        Map<
            string,
            Map<(...arg: any[]) => void, Set<string | string[] | boolean>>
        >
    >();

    /**
     * Die ID des Animation-Frames zur Überwachung des aktiven Elements
     */
    private activeElementFrameId: number | null = null;

    /**
     * Die Anzahl der Ereignisse nach Ereignis-Typ
     */
    private _eventCounts: Partial<EventCounts> = new Proxy({} as Partial<EventCounts>, {
        set: (target, p: EventName, value: EventCount) => {
            target[p] = value;

            console.clear();
            console.table(target);
            console.log("Total: ", Object.values(this.eventCounts).reduce((acc, curr) => acc + curr.amount, 0));

            return true;
        }
    });

    /**
     * Ein Setter für die Event-Counts
     */
    private set eventCounts(value: Partial<EventCounts>) {
        this._eventCounts = value;
    }

    /**
     * Ein Getter für die Event-Counts
     */
    private get eventCounts(): Partial<EventCounts> {
        return this._eventCounts;
    }

    /**
     * Das ursprüngliche `addEventListener` von `document`
     */
    private originalAddEventListener?: typeof document.addEventListener;

    /**
     * Das ursprüngliche `removeEventListener` von `document`
     */
    private originalRemoveEventListener?: typeof document.removeEventListener;

    /**
     * Ein Timer zur Ausgabe aller registrierten Ereignisse in der Konsole
     */
    private watchTimer?: NodeJS.Timeout;

    private constructor() {
        super();
    }

    /**
     * Erzeugt eine Instanz des Event-Tools, falls noch keine existiert
     *
     * @returns Die Instanz des Event-Tools
     */
    public static getInstance() {
        if (!EventTool.Instance)
            EventTool.Instance = new EventTool();

        return EventTool.Instance;
    }

    /**
     * Erzeugt einen Wrapper für das `addEventListener` und `removeEventListener` von `document`
     */
    public capture() {
        if (this.originalAddEventListener || this.originalRemoveEventListener)
            return;

        this.originalAddEventListener = document.addEventListener.bind(document);
        this.originalRemoveEventListener = document.removeEventListener.bind(document);

        const _this: EventTool = this;

        document.addEventListener = function(type: EventName, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions ): void {
            if (!_this.eventCounts[type])
                _this.eventCounts[type] = {
                    amount: 1,
                    listener: [listener]
                }
            else
                _this._eventCounts[type] = {
                    amount: _this.eventCounts[type]!.amount + 1,
                    listener: [..._this.eventCounts[type]!.listener, listener]
                };

            _this.originalAddEventListener?.(type, listener, options);
        };

        document.removeEventListener = function(type: EventName, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
            if (!_this.eventCounts[type])
                return _this.originalRemoveEventListener?.(type, listener, options);

            if (!_this.eventCounts[type]!.listener.includes(listener))
                return _this.originalRemoveEventListener?.(type, listener, options);

            _this.eventCounts[type] = {
                amount: _this.eventCounts[type]!.amount - 1,
                listener: _this.eventCounts[type]!.listener.filter(l => l !== listener)
            };

            _this.originalRemoveEventListener?.(type, listener, options);
        };
    }

    /**
     * Überwacht das aktive Element und informiert die registrierten Event-Listener bei Änderungen
     */
    @resettableOnce
    private monitorActiveElement() {
        let lastActiveElement: Element | null = null;

        const checkActiveElement = () => {
            if (lastActiveElement === document.activeElement) {
                this.activeElementFrameId = requestAnimationFrame(checkActiveElement);

                return;
            }

            lastActiveElement = document.activeElement;

            this.listeners.get(document)?.get("activeElement")?.forEach((_, listener) => {
                try {
                    listener(new CustomEvent("activeElement", {detail: {target: lastActiveElement}}));
                } catch (error) {
                    Logger.error("Fehler in der Callback-Funktion des activeElement-Listeners", error);
                }

            });

            this.activeElementFrameId = requestAnimationFrame(checkActiveElement);
        };

        this.activeElementFrameId = requestAnimationFrame(checkActiveElement);
    }


    /**
     * Gibt die Anzahl der registrierten Ereignisse im 1 Sekunden Intervall der Konsole aus
     */
    public watch() {
        if (this.watchTimer)
            return;

        this.watchTimer = setInterval(() => {
            console.clear();
            console.table(this.eventCounts);
            console.log("Total: ", Object.values(this.eventCounts).reduce((acc, curr) => acc + curr.amount, 0));
        }, 1000);
    }

    /**
     * Stoppt die Ausgabe der registrierten Ereignisse in der Konsole
     */
    public unwatch() {
        if (!this.watchTimer)
            return;

        clearInterval(this.watchTimer);

        this.watchTimer = undefined;
    }

    /**
     * Stoppt das Erfassen von Ereignissen und die Ausgabe der registrierten Ereignisse in der Konsole
     */
    public stop() {
        this.unwatch();

        if (this.originalAddEventListener)
            document.addEventListener = this.originalAddEventListener;

        if (this.originalRemoveEventListener)
            document.removeEventListener = this.originalRemoveEventListener;

        this.originalAddEventListener = undefined;
        this.originalRemoveEventListener = undefined;
    }

    /**
     * Sortiert die Optionen eines Event-Listeners
     *
     * @param options Die Optionen des Event-Listeners
     *
     * @returns Die sortierten Optionen des Event-Listeners
     */
    private sortOptions(options: string | string[] | number | boolean | AddEventListenerOptions | undefined ) {
        if (typeof options === "boolean" || options === undefined || typeof options === "string" || typeof options === "number")
            return String(options ?? false);

        return JSON.stringify(Object.fromEntries(Object.entries(options).sort()));
    };

    /**
     * Fügt einen Event-Listener einmalig hinzu. Existiert der Event-Listener bereits, wird er nicht erneut hinzugefügt.
     * Dabei werden die Optionen des Event-Listeners berücksichtigt.
     *
     * @param node Das Element, an das der Event-Listener gebunden werden soll
     * @param type Der Name des Events
     * @param listener Der Event-Listener
     * @param options Die Optionen des Event-Listeners
     */
    public addEventListener<
        N extends Document | Window | HTMLElement | null | undefined,
        T extends CustomEvents<N>,
        O extends CustomOptions<T>
    >(
        node: N,
        type: T,
        listener: (...args: any[]) => any,
        options?: O
    ): void {
        if (!node) return;

        this.listeners.set(node, this.listeners.get(node) ?? new Map());

        const typeListenersOfNode = this.listeners.get(node)!;
        typeListenersOfNode.set(type, typeListenersOfNode.get(type) ?? new Map());

        const listenersOfType = typeListenersOfNode.get(type)!;
        listenersOfType.set(listener, listenersOfType.get(listener) ?? new Set());

        const optionsOfListener = listenersOfType.get(listener)!;
        const sortedOptions = this.sortOptions(options);

        if (optionsOfListener.has(sortedOptions))
            return;

        optionsOfListener.add(sortedOptions);

        if (type !== "activeElement")
            return node.addEventListener(type, listener, options);

        this.monitorActiveElement();
    }

    /**
     * Entfernt einen per {@link addEventListener} registrierten Event-Listener.

     * @param node Das Element, an das der Event-Listener gebunden ist
     * @param type Der Name des Events
     * @param listener Der Event-Listener
     * @param options Die Optionen des Event-Listeners
     */
    public removeEventListener<
        N extends Document | Window | HTMLElement | null | undefined,
        T extends CustomEvents<N>,
        O extends CustomOptions<T>
    >(
        node: N,
        type: T,
        listener: (...args: any[]) => any,
        options?: O
    ): void {
        if (!node) return;
        if (!this.listeners.has(node)) return;

        const typeListenersOfNode = this.listeners.get(node)!;
        if (!typeListenersOfNode.has(type)) return;

        const listenersOfType = typeListenersOfNode.get(type)!;
        const optionsOfListener = listenersOfType.get(listener);

        const sortedOptions = this.sortOptions(options);
        if (!optionsOfListener?.has(sortedOptions)) return;

        optionsOfListener.delete(sortedOptions);

        if (typeof options !== "number" && type !== "activeElement")
            node.removeEventListener(type, listener, options);

        if (optionsOfListener.size === 0)
            listenersOfType.delete(listener);

        if (listenersOfType.size === 0)
            typeListenersOfNode.delete(type);

        if (type === "activeElement" && this.activeElementFrameId !== null && listenersOfType.size === 0) {
            cancelAnimationFrame(this.activeElementFrameId);

            this.resetMonitorActiveElement();
        }

        if (typeListenersOfNode.size === 0)
            this.listeners.delete(node);
    }

}

export default EventTool;
