import Toolkit from "..";
import ToolkitTool from "../ToolkitTool";
import DOMPurifiy from "dompurify";
import DomTool from "./DomTool";

DOMPurifiy.addHook("afterSanitizeAttributes", (node) => {
    if (!node.hasAttribute || !node.hasAttribute("style"))
        return;

    const cssClass = node.getAttribute("class");
    const isMacroNode = cssClass?.includes("macro") || cssClass?.includes("trap") || cssClass?.includes("label");

    if (!isMacroNode)
        return;

    node.removeAttribute("style");
});

DOMPurifiy.addHook("uponSanitizeAttribute", (node, data) => {
    if (data.attrName !== "class")
        return;

    if (data.attrValue.includes("macro")) {
        data.attrValue = "macro";

        return;
    }

    if (data.attrValue.includes("trap") || data.attrValue.includes("label"))
        return;

    data.attrValue = "";
    node.removeAttribute("class");
});

/**
 * Eine Klasse mit Hilfsfunktionen zur Bereinigung von Html und Css.
 */
class SanitizeTool extends ToolkitTool {
    /**
     * Kopiert rekursiv Stileigenschaften vom Elternelement in das Kindelement
     * und entfernt leere Knoten
     *
     * @param element
     * @param exclude Nicht zu berücksichtigende Selektoren
     */
    public clearMarkup(element: HTMLElement, exclude: string[] = []) {
        this.removeZWS(element, exclude);
        this.removeEmptyNodes(element);
        this.mergeEqualNodes(element, exclude);

        element.normalize();
    }

    /**
     * Vereint benachbarte Textknoten in einem Element, wenn
     *
     * 1) es sich um Inline-Elemente handelt
     * 2) Kein {@link DomTool.VoidElements} ist
     * 3) die Stile und der Tagname zum Nachbarknoten übereinstimmen.
     *
     * @param element Das Element
     * @param exclude Nicht zu berücksichtigende Selektoren
     */
    public mergeEqualNodes(element: HTMLElement, exclude: string[] = []) {
        const domTool = Toolkit.tool("dom");
        const styleTool = Toolkit.tool("style");
        const typeGuard = Toolkit.tool("typeGuard");

        const nodes = Array.from(element.children) as Node[];

        const mergeNode = (mainNode: HTMLElement, mergableNode: ChildNode | null) => {
            if (!mergableNode) return;
            if (!typeGuard.isHtmlElement(mergableNode)) return;
            if (domTool.matchSelector(mergableNode, exclude)) return;
            if (mainNode.tagName !== mergableNode.tagName) return;
            if (!styleTool.cssTextEquals(mainNode.style.cssText, mergableNode.style.cssText)) return;

            Array
                .from(mergableNode.childNodes)
                .forEach(childNode => mainNode.appendChild(childNode));

            const nextNode = mergableNode.nextSibling;
            mergableNode.remove();

            mergeNode(mainNode, nextNode);
        }

        nodes.forEach(currentNode => {
            if (!document.body.contains(currentNode)) return;
            if (!typeGuard.isHtmlElement(currentNode)) return;
            if (domTool.matchSelector(currentNode, exclude)) return;
            if (domTool.isVoidElement(currentNode)) return;
            if (domTool.isBlockElement(currentNode))
                return this.mergeEqualNodes(currentNode, exclude);

            let nextSibling: ChildNode | null = currentNode.nextSibling;
            while (nextSibling && !domTool.isValidNode(nextSibling))
                nextSibling = nextSibling?.nextSibling ?? null;

            mergeNode(currentNode, nextSibling);
        });
    }

    /**
     * Entfernt Zero-Width-Space-Zeichen aus einem Element.
     *
     * @param element Das Element
     */
    public removeZWS(element: HTMLElement, exclude: string[] = []) {
        const domTool = Toolkit.tool("dom");

        const traverse = (node: Node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const text = node.textContent;

                if (text?.includes('\u200B'))
                    node.textContent = text.replace(/\u200B/g, "");

                if (text?.includes('\u200C'))
                    node.textContent = text.replace(/\u200C/g, "");

            } else if (!domTool.matchSelector((node as HTMLElement), exclude))
                node.childNodes.forEach(traverse);
        };

        element.childNodes.forEach(traverse);
    }

    /**
     * Entfernt {@link Element}e ohne Textinhalt
     *
     * @param element Das Quellelement in dem leere {@link Element}e entfernt werden sollen
     * @param exclude Nicht zu berücksichtigende Selektoren
     */
    public removeEmptyNodes(element: Element | DocumentFragment, exclude: string[] = []) {
        const domTool = Toolkit.tool("dom");

        const remove = (element: Element | DocumentFragment) =>
            Array
                .from(element.children)
                .forEach(node => {
                    if (domTool.matchSelector(node as HTMLElement, exclude))
                        return;

                    if (node.tagName !== "SPAN")
                        return;

                    if (node.childNodes.length === 0)
                        return node.remove();

                    if (node.textContent?.length === 0 && Array.from(node.childNodes).filter(node => node.nodeType !== Node.TEXT_NODE).length === 0)
                        return node.remove();

                    remove(node as Element)
                });

        remove(element);
    }

    /**
     * Bereinigt rekursiv die Stile aller Kindknoten eines Elements von unerwünschten Css-Eigenschaften.
     *
     * @param element Das Element
     */
    public sanitizeChildStyles(element: Node) {
        element.childNodes.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE)
                return;

            const element = node as HTMLElement;
            const style = this.sanitizeStyle(element.style).trim();

            if (style.length > 0)
                element.style.cssText = style;
            else
                element.removeAttribute("style");

            this.sanitizeChildStyles(element);
        });
    };

    /**
     * Erzeugt einen HTML-String aus einem Text und bereinigt diesen von unerwünschten Tags, Attributen und Stilen.
     *
     * @param html Der HTML-String
     * @param config Die Konfiguration
     * @param config.allowedAttributes Erlaubte Attribute
     * @param config.allowedStyles Erlaubte Stileigenschaften
     * @param config.allowedTags Erlaubte Tags
     *
     * @returns Der bereinigte HTML-String
     */
    public sanitizeMarkup(html: string, {allowedAttributes, allowedStyles, allowedTags}: {
        allowedAttributes?: string[]
        allowedStyles?: (keyof CSSStyleDeclaration)[]
        allowedTags?: (keyof HTMLElementTagNameMap)[]
    }) {
        const typeGuard = Toolkit.tool("typeGuard");
        const kebabProperties = allowedStyles?.map(style => this.camelToKebab(style as string));

        if (allowedStyles)
            DOMPurifiy.addHook("afterSanitizeAttributes", (node) => {
                if (!typeGuard.isHtmlElement(node)) return;
                if (!node.hasAttribute("style")) return;

                const style = node.style;

                const forbiddenStyles = Array
                    .from(style)
                    .filter(styleProperty => !kebabProperties?.includes(styleProperty));

                forbiddenStyles.forEach(styleProperty => style.removeProperty(styleProperty));
            });

        allowedAttributes = [...(allowedAttributes ?? []), allowedStyles ? "style" : ""]
        allowedTags = allowedTags ?? [];

        const purified = DOMPurifiy.sanitize(html, {
            ALLOWED_TAGS: allowedTags,
            ALLOWED_ATTR: allowedAttributes
        });

        const fragment = document.createDocumentFragment();
        const div = document.createElement("div");
        div.innerHTML = purified;

        while (div.firstChild)
            fragment.appendChild(div.firstChild);

        div.remove();

        this.sanitizeChildStyles(fragment);
        Toolkit.tool("dom").mapTags(fragment);

        const output = document.createElement("div");
        output.appendChild(fragment);

        const innerHTML = output.innerHTML;

        output.remove();

        return innerHTML;
    }

    /**
     * Entfernt nicht gültige Css-Eigenschaften aus der übergebenen {@link CSSStyleDeclaration}
     *
     * @param style Die {@link CSSStyleDeclaration}
     *
     * @returns Der bereinigte Stil
     */
    public sanitizeStyle(style: CSSStyleDeclaration) {
        let sanitizedStyle = "";

        for (const prop of style) {
            const value = style.getPropertyValue(prop);

            if (CSS.supports(prop, value))
                sanitizedStyle += `${prop}: ${style.getPropertyValue(prop)};`;
        }

        return sanitizedStyle.trim();
    }

    /**
     * Wandelt einen CamelCase-Case-String in einen Kebab-String um.
     *
     * @param camelCaseString Der CamelCase-String
     *
     * @returns Der Kebab-String
     */
    public camelToKebab(camelCaseString: string) {
        return camelCaseString
            .replace(/([a-z])([A-Z])/g, '$1-$2') // Füge einen Bindestrich vor jedes Großbuchstabenpaar ein
            .toLowerCase(); // Wandelt alles in Kleinbuchstaben um
    }

    /**
     * Wandelt einen Kebab-String in einen CamelCase-String um.
     *
     * @param kebabString Der Kebab-String
     *
     * @returns Der CamelCase-String
     */
    public kebabToCamel(kebabString: string) {
        return kebabString
            .replace(/-./g, (match) => match.charAt(1).toUpperCase());
    }

    /**
     * Prüft, ob ein Element einem Selektor entspricht.
     *
     * @param element Das Element
     * @param selector Der Selektor
     *
     * @returns Das Ergebnis, ob das Element dem Selektor entspricht
     */
     public isSelectorMatch(element: HTMLElement, selector: string) {
        const matchTag = element.nodeName === selector.toUpperCase();

        if (matchTag)
            return true;

        const matchId = element.id === selector.replace("#", "");

        if (matchId)
            return true;

        const matchClass = element.classList.contains(selector.replace(".", ""));

        if (matchClass)
            return true;

        return false;
    }
}

export default SanitizeTool;
