import type { DebouncedFunc } from "lodash";
import Logger from "@/utils/Logger";
import throttle from "lodash/throttle";
import ToolkitTool from "../ToolkitTool";

type Params = {
    /**
     * Die Funktion, die ausgeführt werden soll
     */
    callback: () => Promise<boolean | void> | boolean | void;

    /**
     * Die Dauer aller Wiederholungen in Millisekunden
     */
    duration: number;

    /**
     * Das Intervall einer Widerholung in Millisekunden
     */
    interval: number;
};

class RepeatTool extends ToolkitTool<Params> {
    /**
     * Die Startzeit der ersten Wiederholung
     */
    private start?: number;

    /**
     * Die gedrosselte Callback-Funktion, die ausgeführt werden soll
     */
    private throttledCallback?: DebouncedFunc<(...args: unknown[]) => Promise<unknown> | unknown>

    /**
     * Führt eine Funktion in regelmäßigen Abständen aus
     */
    public repeat() {
        if (!this.params) return this;

        this.start = new Date().getTime();

        this.throttledCallback?.cancel();
        this.throttledCallback = throttle(this.params.callback, this.params.interval);

        this.repeatThrottledCallback();

        return this;
    }

    /**
     * Stoppt die Wiederholung der Funktion
     */
    public stop() {
        this.throttledCallback?.cancel();
        this.throttledCallback = undefined;
    }

    /**
     * Führt die Wiederholung der {@link throttledCallback} aus
     */
    private async repeatThrottledCallback() {
        if (!this.params) return;
        if (!this.throttledCallback) return;

        if (this.start === undefined)
            return;

        try {
            const end = new Date().getTime();

            if (end - this.start > this.params.duration)
                return;

            const result = this.throttledCallback()
            const shouldRepeat = result instanceof Promise
                ? await result
                : result;

            if (!shouldRepeat)
                return this.throttledCallback.cancel();

            setTimeout(() => this.repeatThrottledCallback(), 1)
        } catch (e) {
            Logger.error("Fehler in der Repeat-Funktion: ", e);
        }
    }
}

export default RepeatTool;
