import ToolkitTool from "../ToolkitTool";

export default class TypeGuardTool extends ToolkitTool {
    /**
     * Die Instanz des TypeGuard-Tools
     */
    private static Instance: TypeGuardTool;

    private constructor() {
        super();
    }

    /**
     * Erzeugt eine Instanz des TypeGuard-Tools, falls noch keine existiert
     *
     * @returns Die Instanz des TypeGuard-Tools
     */
    public static getInstance() {
        if (!TypeGuardTool.Instance)
            TypeGuardTool.Instance = new TypeGuardTool();

        return TypeGuardTool.Instance;
    }

    /**
     * Prüft, ob ein Objekt ein Abbruchfehler ist
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt ein Abbruchfehler ist
     */
    public hasAborted = (obj: unknown): obj is DOMException => obj instanceof DOMException && obj.name === "AbortError";

    /**
     * Prüft, ob ein Objekt über die Eigenschaft {@linkcode inputType} verfügt
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt über die Eigenschaft {@linkcode inputType} verfügt
     */
    public hasInputType = (obj: unknown): obj is { inputType: string } =>
        this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "inputType");

    /**
     * Prüft, ob ein Objekt über die Eigenschaft {@linkcode maximum} verfügt
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt über die Eigenschaft {@linkcode maximum} verfügt
     */
    public hasMaxProperty = (obj: unknown): obj is { maximum: number } =>
        this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "maximum") && (obj as { maximum: number | null }).maximum !== null;

    /**
     * Prüft, ob ein Objekt über die Eigenschaft {@linkcode minimum} verfügt
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt über die Eigenschaft {@linkcode minimum} verfügt
     */
    public hasMinProperty = (obj: unknown): obj is { minimum: number } =>
        this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "minimum") && (obj as { minimum: number | null }).minimum !== null;

    /**
     * Prüft, ob ein Objekt über die Eigenschaften {@linkcode minimum} und {@linkcode maximum} verfügt
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt über die Eigenschaften {@linkcode minimum} und {@linkcode maximum} verfügt
     */
    public hasMinMaxProperty = (obj: unknown): obj is { minimum: number, maximum: number } =>
        this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "minimum") && Object.prototype.hasOwnProperty.call(obj, "maximum")
        && (obj as { minimum: number | null }).minimum !== null && (obj as { maximum: number | null }).maximum !== null;

    /**
     * Prüft, ob ein Objekt über die Eigenschaft {@linkcode panel} verfügt
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt über die Eigenschaft {@linkcode panel} verfügt
     */
    public hasPanel = (obj: unknown): obj is { panel: TPanel } =>
        this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "panel");

    /**
     * Prüft, ob ein Objekt über die Eigenschaft {@linkcode stack} verfügt
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt über die Eigenschaft {@linkcode stack} verfügt
     */
    public hasStack = (obj: unknown): obj is { stack: TStack } =>
        this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "stack");

    /**
     * Prüft, ob ein Objekt über die Eigenschaft {@linkcode tabs} verfügt
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt über die Eigenschaft {@linkcode tabs} verfügt
     */
    public hasTabs = (obj: unknown): obj is { tabs: TTab[] } =>
        this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "tabs");

    /**
     * Prüft, ob ein Wert vom Typ {@linkcode bigint} ist
     *
     * @param value Der zu prüfende Wert
     *
     * @returns Ob der Wert vom Typ {@linkcode bigint} ist
     */
    public isBigInt = (value: unknown): value is bigint => typeof value === "bigint";

    /**
     * Prüft, ob ein Steuerelement vom Typ {@linkcode TControl} ein {@linkcode IButtonControl} ist
     *
     * @param obj Das zu prüfende Steuerelement
     *
     * @returns Ob das Steuerelement vom Typ {@linkcode IButtonControl} ist
     */
    public isButtonControl = (obj: unknown): obj is IButtonControl =>
        this.isControl(obj) && obj.$type === "button";

    /**
     * Prüft, ob ein Objekt vom Typ {@linkcode TControl} ist
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt vom Typ {@linkcode TControl} ist
     */
    public isControl = (obj: unknown): obj is TControl =>
        !this.isNullOrUndefined(obj) && typeof obj === "object" && !Array.isArray(obj) && obj!.hasOwnProperty("$type");

    /**
     * Prüft, ob ein Wert vom Typ {@linkcode Date} ist
     *
     * @param value Der zu prüfende Wert
     *
     * @returns Ob der Wert vom Typ {@linkcode Date} ist
     */
    public isDate = (value: unknown): value is Date => value instanceof Date;

    /**
     * Prüft, ob ein Steuerelement vom Typ {@linkcode TControl} ein Date-Steuerelement ist
     *
     * @param control Das zu prüfende Steuerelement
     *
     * @returns Ob das Steuerelement ein Date-Steuerelement ist
     */
    public isDateControl = (control: unknown): control is { $type: "date" } =>
        this.isControl(control) && control.$type === "date";

    /**
     * Prüft, ob es sich bei einer Aktion um eine Löschaktion handelt
     *
     * @param action Die zu prüfende Aktion
     *
     * @returns Ob es sich bei der Aktion um eine Löschaktion handelt
     */
    public isDeleteAction = (action: TAction<TActionName>): action is TAction<"delete"> => action.$type === "delete";

    /**
     * Prüft, ob ein Fehler ein Dialog-Abbruchfehler ist
     *
     * @param error Der zu prüfende Fehler
     *
     * @returns Der Wert, ob der Fehler ein Dialog-Abbruchfehler ist
     */
    public isDialogCancelError = (error: unknown): error is { isCanceled: boolean } => error instanceof Error && error.name === "DialogCancelError";

    /**
     * Prüft, ob ein Wert vom Typ {@linkcode DocumentFragment} ist
     *
     * @param element Das zu prüfende Element
     *
     * @returns Der Wert, ob das Element ein {@linkcode DocumentFragment} ist
     */
    public isDocumentFragment = (element: unknown): element is DocumentFragment => element instanceof DocumentFragment;

    /**
     * Prüft. ob ein Knoten ein {@linkcode Element} ist
     *
     * @param node Der zu prüfende Knoten
     *
     * @returns Ob der Knoten ein {@linkcode Element} ist
     */
    public isElement = (node: unknown): node is Element => node instanceof Element;

    /**
     * Prüft, ob ein Wert vom Typ {@linkcode Error} ist
     *
     * @param obj Der zu prüfende Wert
     *
     * @returns Ob der Wert vom Typ {@linkcode Error} ist
     */
    public isError = (obj: unknown): obj is Error => obj instanceof Error;

    /**
     * Prüft, ob ein Steuerelement vom Typ {@linkcode TControl} ein {@linkcode TFileControl} ist
     *
     * @param obj Das zu prüfende Steuerelement
     *
     * @returns Ob das Steuerelement vom Typ {@linkcode TFileControl} ist
     */
    public isFileControl = (obj: unknown): obj is IFileControl =>
        this.isControl(obj) && obj.$type === "file";

    /**
     * Prüft, ob ein Wert vom Typ {@linkcode PromiseSettledResult} ein erfülltes Promise ist
     *
     * @param value Der zu prüfende Wert
     *
     * @returns Ob der Wert ein erfülltes Promise ist
     */
    public isFullfilled = (value: PromiseSettledResult<any>): value is PromiseFulfilledResult<any> => value.status === "fulfilled";

    /**
     * Prüft, ob ein Wert vom Typ {@linkcode Function} ist
     *
     * @param obj Der zu prüfende Wert
     *
     * @returns Ob der Wert vom Typ {@linkcode Function} ist
     */
    public isFunction = (obj: any): obj is Function => obj instanceof Function;

    /**
     * Prüft, ob ein Wert ein {@linkcode HTMLElement} ist
     *
     * @param element Das zu prüfende Element
     *
     * @returns Ob das Element ein {@linkcode HTMLElement} ist
     */
    public isHtmlElement = (element: unknown): element is HTMLElement => element instanceof HTMLElement;

    /**
     * Prüft, ob ein Element ein interaktives Formularelement ist
     *
     * @param element Das zu prüfende Element
     *
     * @returns Ob das Element ein interaktives Formularelement ist
     */
    public isInteractiveFormElement = (element: HTMLElement): element is InteractiveFormElement => (
        element instanceof HTMLSelectElement ||
        element instanceof HTMLInputElement ||
        element instanceof HTMLTextAreaElement ||
        element instanceof HTMLOptionElement ||
        element instanceof HTMLAnchorElement ||
        element instanceof HTMLButtonElement
    );

    /**
     * Prüft, ob das nächste verfügbare übergeordnete Element ein interaktives Formularelement ist
     *
     * @param target Das zu prüfende Element
     *
     * @returns Ob das nächste verfügbare übergeordnete Element ein interaktives Formularelement ist
     */
    public isInteractiveFormElementClosest = (target: EventTarget | null): target is InteractiveFormElement => {
        if (!target)
            return false;

        const closestElement = (target as HTMLElement).closest("input, select, textarea, option, a, button");

        return closestElement !== null && this.isInteractiveFormElement(closestElement as HTMLElement);
    };

    /**
     * Prüft, ob ein Wert ein Schlüssel-Wert-Paar ist
     *
     * @param value Der zu prüfende Wert
     *
     * @returns Ob der Wert ein Schlüssel-Wert-Paar ist
     */
    public isKeyValuePair = <T>(value: unknown): value is KeyValuePair<T> =>
        typeof value === "object" && !this.isNullOrUndefined(value) && !Array.isArray(value);

    /**
     * Prüft, ob ein Objekt vom Typ {@linkcode TModule} ist
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt vom Typ {@linkcode TModule} ist
     */
    public isModule = (obj: unknown): obj is TModule =>
        this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "sections");

    /**
     * Prüft, ob ein Knoten ein {@linkcode Node} ist
     *
     * @param node Der zu prüfende Knoten
     *
     * @returns Ob der Knoten ein {@linkcode Node} ist
     */
    public isNode = (node: unknown): node is Node => node instanceof Node;

    /**
     * Prüft, ob ein Wert {@linkcode null} oder {@linkcode undefined} ist
     *
     * @param value Der zu prüfende Wert
     *
     * @returns Ob der Wert {@linkcode null} oder {@linkcode undefined} ist
     */
    public isNullOrUndefined = (value: unknown): value is null | undefined => value === null || value === undefined;

    /**
     * Prüft, ob ein Wert vom Typ {@linkcode object} ist
     *
     * @param value Der zu prüfende Wert
     *
     * @returns Ob der Wert vom Typ {@linkcode object} ist
     */
    public isObject = (value: unknown): value is object => typeof value === "object" && !this.isNullOrUndefined(value) && !Array.isArray(value);

    /**
     * Prüft, ob ein Objekt vom Typ {@linkcode TSection} ist
     *
     * @param obj Das zu prüfende Objekt
     *
     * @returns Ob das Objekt vom Typ {@linkcode TSection} ist
     */
    public isSection = (obj: unknown): obj is TSection =>
        this.isObject(obj) && Object.prototype.hasOwnProperty.call(obj, "subSections");

    /**
     * Prüft, ob ein Steuerelement vom Typ {@linkcode TControl} ein {@linkcode TSelectControl} ist
     *
     * @param control Das zu prüfende Steuerelement
     *
     * @returns Ob das Steuerelement vom Typ {@linkcode TSelectControl} ist
     */
    public isSelectControl = (obj: unknown): obj is TSelectControl => this.isControl(obj) && obj.$type === "select";

    /**
     * Prüft, ob es sich bei einer Aktion um eine SetIcon-Aktion handelt
     *
     * @param action Die zu prüfende Aktion
     *
     * @returns Ob es sich bei der Aktion um eine SetIcon-Aktion handelt
     */
    public isSetIconAction = (action: TAction<TActionName>): action is TAction<"setIcon"> => action.$type === "setIcon";

    /**
     * Prüft, ob ein Wert vom Typ {@linkcode string} ist
     *
     * @param value Der zu prüfende Wert
     *
     * @returns Ob der Wert vom Typ {@linkcode string} ist
     */
    public isString = (value: unknown): value is string => typeof value === "string";

    /**
     * Prüft, ob ein {@link element} ein {@link HTMLElement} vom Typ {@link tag} ist
     *
     * @param element Das zu prüfende Element
     * @param tag Der Tag, der geprüft werden soll
     *
     * @returns Ob das Element ein {@link HTMLElement} vom Typ {@link tag} ist
     */
    public isTag<T extends keyof HTMLElementTagNameMap>(element: unknown, tag: T): element is HTMLElementTagNameMap[T] {
        if (!this.isHtmlElement(element)) return false;

        return element.tagName.toLowerCase() === tag;
    };

    /**
     * Prüft, ob ein Knoten ein {@linkcode Text} ist
     *
     * @param node Der zu prüfende Knoten
     *
     * @returns Ob der Knoten ein {@linkcode Text} ist
     */
    public isText = (node: unknown): node is Text => node instanceof Text;

    /**
     * Prüft, ob ein Wert vom Typ {@link type} ist
     *
     * @param value Der zu prüfende Wert
     * @param type Der Typ, der geprüft werden soll
     *
     * @returns Ob der Wert vom Typ {@link type} ist
     */
    public isTypeOf = <T>(value: unknown, type: new () => T): value is T =>
        value instanceof type;

    /**
     * Prüft, ob ein {@linkcode methodName} in einem {@linkcode obj} ein Promise-Objekt ist
     *
     * @param obj Das zu prüfende Objekt
     * @param methodName Der Name der Methode, die geprüft werden soll
     *
     * @returns Ob die Methode ein Promise-Objekt ist
     */
    public isPromiseMethod = (obj: any, methodName: string): obj is { [key: string]: AsyncFunction } => {
        const method = obj[methodName];

        return (
            method &&
            typeof method === "function" &&
            method.constructor &&
            method.constructor.name === "AsyncFunction"
        );
    };

    /**
     * Prüft, ob ein {@linkcode error} ein Response-Error ist
     *
     * @param error Der zu prüfende Fehler
     *
     * @returns Ob der Fehler ein Response-Fehler ist
     */
    public isResponseError = (error: unknown): error is { response: Response } => {
        if (!(error instanceof Error)) return false;
        if (!("response" in error)) return false;
        if (typeof error.response !== "object") return false;
        if (!error.response) return false;
        if (!("status" in error.response)) return false;

        return error.response.status !== undefined;
    }
}


