import ToolkitTool from "../ToolkitTool";

/**
 * Ein Tool zur Verwaltung/Manipulation von Farben.
 */
export default class ColorTool extends ToolkitTool {
    /**
     * Die Singleton-Instanz des Tools.
     */
    private static Instance: ColorTool;

    /**
     * Die private Eigenschaft, die die Basisfarben in Hexwerten des Mandanten enthält.
     */
    private _baseColors: string[] = [];

    /**
     * Die Basisfarben des Mandanten.
     */
    public get baseColors() {
        return this._baseColors;
    }

    /**
     * C'tor.
     */
    private constructor() {
        super();

        // Initialisiere die Basisfarben
        this._baseColors = this.getBaseColorsFromDocument();
    }

    /**
     * Liefert die Singleton-Instanz des Tools.
     *
     * @returns Die Singleton-Instanz des Tools
     */
    public static getInstance(): ColorTool {
        if (!ColorTool.Instance)
            ColorTool.Instance = new ColorTool();

        return ColorTool.Instance;
    }

    /**
     * Konvertiert einen CSS-Farbwert in HSL-Komponenten
     *
     * @param color CSS-Farbstring (rgb, rgba, hsl, hsla, hex, hexa)
     * @returns HSL-Komponenten und Alpha-Wert
     */
    private cssColorToHSL(color: string): {h: number, s: number, l: number, a: number} {
        // Standardwerte
        const defaultColor = {h: 0, s: 70, l: 50, a: 0.45};

        try {
            // Canvas-basierte Konvertierung (unterstützt alle CSS-Farbformate)
            const canvas = document.createElement('canvas');
            canvas.width = 1;
            canvas.height = 1;
            const ctx = canvas.getContext('2d');
            if (!ctx) return defaultColor;

            // Farbe auf Canvas anwenden
            ctx.fillStyle = color;

            // Ungültige Farbe erkennen
            if (ctx.fillStyle === "#000000" && color !== "#000000" && color !== "black" && color !== "rgb(0,0,0)")
                return defaultColor;

            // Farbe auslesen
            ctx.fillRect(0, 0, 1, 1);
            const [r, g, b, a] = ctx.getImageData(0, 0, 1, 1).data;

            // RGB zu HSL konvertieren
            const rNorm = r / 255;
            const gNorm = g / 255;
            const bNorm = b / 255;

            const max = Math.max(rNorm, gNorm, bNorm);
            const min = Math.min(rNorm, gNorm, bNorm);
            let h = 0;
            let s = 0;
            const l = (max + min) / 2;

            if (max !== min) {
                const d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

                switch (max) {
                    case rNorm: h = (gNorm - bNorm) / d + (gNorm < bNorm ? 6 : 0); break;
                    case gNorm: h = (bNorm - rNorm) / d + 2; break;
                    case bNorm: h = (rNorm - gNorm) / d + 4; break;
                }

                h /= 6;
            }

            return {
                h: Math.round(h * 360),
                s: Math.round(s * 100),
                l: Math.round(l * 100),
                a: a / 255
            };
        } catch (e) {
            return defaultColor;
        }
    }

    /**
     * Erzeugt harmonische Farben basierend auf einer Startfarbe
     *
     * @param baseColor Die Startfarbe (optional)
     * @param count Anzahl der gewünschten Farben
     * @returns Array mit Farbcodes
     */
    public generateHarmonicColors(baseColor: string, count: number): string[] {
        const colors: string[] = [];

        // Basis-HSL-Werte ermitteln
        const hsl = this.cssColorToHSL(baseColor);

        // Goldener Schnitt für gleichmäßige Verteilung
        const goldenRatio = 0.618033988749895;
        let hue = hsl.h / 360; // Auf Bereich [0,1] normalisieren

        for (let i = 0; i < count; i++) {
            if (i === 0 && baseColor) {
                // Erste Farbe ist die Basisfarbe
                colors.push(baseColor);
            } else {
                // Hue-Wert rotieren und neue Farbe erzeugen
                hue += goldenRatio;
                hue %= 1; // Im Bereich [0,1] halten

                // Farbton auf den Farbkreis (0-360°) abbilden
                const h = Math.floor(hue * 360);

                colors.push(`hsla(${h}, ${hsl.s}%, ${hsl.l}%, ${hsl.a})`);
            }
        }

        return colors;
    }

    /**
     * Extrahiert die Basisfarben aus dem Document, konvertiert sie in Hexadezimal-Farbwerte und sortiert sie nach Helligkeit.
     *
     * @returns Die Basisfarben sortiert nach Helligkeit
     */
    private getBaseColorsFromDocument(): string[] {
        const styles = getComputedStyle(document.documentElement);
        const baseColors: string[] = [
            this.hsl2hex(styles.getPropertyValue("--color-accent-1")),
            this.hsl2hex(styles.getPropertyValue("--color-accent-1")),
            this.hsl2hex(styles.getPropertyValue("--color-accent-2")),
            this.hsl2hex(styles.getPropertyValue("--color-foreground")),
            this.hsl2hex(styles.getPropertyValue("--color-background")),
            this.hsl2hex(styles.getPropertyValue("--color-success")),
            this.hsl2hex(styles.getPropertyValue("--color-error"))
        ].reduce((colors, color) => {
            if (!colors.includes(color)) colors.push(color);

            return colors;
        }, [] as string[]);

        return this.sortColorsByBrightness(baseColors);
    }

    /**
     * Ermittelt die Helligkeit einer Farbe aus einem Hexadezimal-String.
     *
     * @param hex Der Hexadezimal-String
     *
     * @returns Die Helligkeit der Farbe
     */
    private getBrightness(hex: string) {
        const { r, g, b } = this.getRGB(hex);

        return (0.299 * r + 0.587 * g + 0.114 * b);
    };

    /**
     * Zerlegt einen Hexadezimal-String in eine RGB-Komponente.
     *
     * @param hex Der Hexadezimal-String
     *
     * @returns Die RGB-Komponente als Objekt
     */
    private getRGB(hex: string)  {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return { r, g, b };
    };

    /**
     * Kovertiert eine HSL-Farbe in eine Hex-Farbe.
     *
     * @param hsl Die HSL-Farbe
     *
     * @returns Die Hex-Farbe
     */
    public hsl2hex(hsl: string): string {
        // Extrahiere h, s, l Werte mit Regex
        const matches = hsl.match(/hsl\((\d+\.?\d*)deg?,\s*(\d+\.?\d*)%,\s*(\d+\.?\d*)%\)/);
        if (!matches) return "#000000";

        // Konvertiere zu Zahlen
        const hue = parseFloat(matches[1]) / 360; // Normalisiere in den Bereich [0,1]
        const saturation = parseFloat(matches[2]) / 100; // Normalisiere in den Bereich [0,1]
        const luminance = parseFloat(matches[3]) / 100; // Normalisiere t in den Bereich [0,1]

        let red: number, green: number, blue: number;

        /**
         * Wenn keine Sättigung, dann Graustufe (rod, grün und blau entsprechen der Luminanz)
         *
         * @link https://de.wikipedia.org/wiki/Farbbeziehung#Achromatische_Farbverwandtschaft
         */
        if (saturation === 0)
            red = green = blue = luminance;
        // Sonst komplexere Berechnung
        else {
            const q = luminance < 0.5 ? luminance * (1 + saturation) : luminance + saturation - luminance * saturation;
            const p = 2 * luminance - q;

            red = this.hue2rgb(p, q, hue + 1/3);
            green = this.hue2rgb(p, q, hue);
            blue = this.hue2rgb(p, q, hue - 1/3);
        }

        return `#${this.toHex(red)}${this.toHex(green)}${this.toHex(blue)}`;
    }

    /**
     * Konvertiert eine Hue-Komponente in eine RGB-Komponente.
     *
     * @param p Der erste Wert, berechnet aus Luminanz und Sättigung (2 * l - q)
     * @param q Der zweite Wert, berechnet aus Luminanz und Sättigung (l < 0.5 ? l * (1 + s) : l + s - l * s)
     * @param t Der verschobene Farbton (h + 1/3 für Rot, h für Grün, h - 1/3 für Blau)
     *
     * @returns Eine RGB-Komponente im Bereich [0,1]
     */
    private hue2rgb(p: number, q: number, t: number): number {
        if (t < 0) t += 1; // Normalisiere t in den Bereich [0,1]
        if (t > 1) t -= 1; // Normalisiere t in den Bereich [0,1]

        // Berechne die RGB-Komponente basierend auf der Position im Farbkreis
        if (t < 1/6) return p + (q - p) * 6 * t; // Erste Sechstel des Farbkreises
        if (t < 1/2) return q; // Zweite und dritte Sechstel
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6; // Vierte Sechstel

        return p; // Letzte zwei Sechstel
    }

    /**
     * Prüft mittels Regex, ob ein Farbwert ein HEXA-String ist.
     *
     * @param color Der Farbwert
     *
     * @returns Das Ergebnis des Regex-Tests
     */
    public matchHEXAString(color: string)  {
        return color.match(/^#[0-9A-Fa-f]{8}$/);
    }

    /**
     * Prüft mittels Regex, ob ein Farbwert ein HSLA-String ist.
     *
     * @param color Der Farbwert
     *
     * @returns Das Ergebnis des Regex-Tests
     */
    public matchHSLAString(color: string) {
        return color.match(/^hsla\(\s*\d+\s*,\s*\d+%\s*,\s*\d+%\s*,\s*([0-9.]+)\s*\)$/);
    }

    /**
     * Prüft mittels Regex, ob ein Farbwert ein RGBA-String ist.
     *
     * @param color Der Farbwert
     *
     * @returns Das Ergebnis des Regex-Tests
     */
    public matchRGBAString(color: string) {
        return color.match(/^rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*([0-9.]+)\s*\)$/);
    }

    /**
     * Prüft, ob ein Farbwert transparent ist.
     *
     * @param color Der Farbwert als HSV-/HSL-/HSLA/RGB-/RGBA-/HEX-/HEXA-String
     *
     * @returns Der Wert, ob die Farbe transparent ist
     */
    public isTranpsarent(color: string): boolean {
        // Keine Farbe oder leerer String
        if (color.trim().length === 0) return true;
        if (color.trim().toLowerCase() === "transparent") return true;

        const rgbaMatch = this.matchRGBAString(color);
        if (rgbaMatch) return parseFloat(rgbaMatch[1]) === 0;

        const hslaMatch = this.matchHSLAString(color);
        if (hslaMatch) return parseFloat(hslaMatch[1]) === 0;

        const hexaMatch = this.matchHEXAString(color);
        if (hexaMatch) return parseInt(hexaMatch[1], 16) === 0;

        return false;
    }

    /**
     * Sortiert ein Array von Hex-Farben von dunkel nach hell
     *
     * @param colors Array von Hex-Farben (z.B. ["#000000", "#FF0000", "#FFFFFF"])
     *
     * @returns Das sortierte Array von dunkel nach hell
     */
    public sortColorsByBrightness(colors: string[]): string[] {
        return [...colors].sort((a, b) => this.getBrightness(a) - this.getBrightness(b));
    }

    /**
     * Konvertiert eine RGB-Komponente (0-1) in einen zweistelligen Hexadezimal-String.
     *
     * @param rgbComponent Die RGB-Komponente als Dezimalzahl zwischen 0 und 1
     *
     * @returns Eine zweistellige Hexadezimalzahl als String
     */
    private toHex(rgbComponent: number): string {
        // Konvertiere den Wert von 0-1 zu 0-255 und dann zu Hex
        const hex = Math.round(rgbComponent * 255).toString(16);

        // Stelle sicher, dass der String zwei Stellen hat
        return hex.length === 1 ? `0${hex}` : hex;
    };
}
