import ColorTool from "./tools/ColorTool";
import DecodeQueryTool from "./tools/DecodeQueryTool";
import DomTool from "./tools/DomTool";
import DifferenceTool from "./tools/DifferenceTool";
import EventTool from "./tools/EventsTool";
import ListTool from "./tools/ListTool";
import MacroTool from "./tools/MacroTool";
import MouseTool from "./tools/MouseTool";
import RepeatTool from "./tools/RepeatTool";
import RetryTool from "./tools/RetryTool";
import SanitizeTool from "./tools/SanitizeTool";
import SelectionTool from "./tools/SelectionTool";
import StyleTool from "./tools/StyleTool";
import TypeGuardTool from "./tools/TypeGuardTool";

/**
 * Eine Liste von Tools zur Instanziierung eines Tools
 */
const Tools = {
    color: ColorTool,
    difference: DifferenceTool,
    decodeQuery: DecodeQueryTool,
    dom: DomTool,
    event: EventTool,
    list: ListTool,
    macro: MacroTool,
    mouse: MouseTool,
    repeat: RepeatTool,
    retry: RetryTool,
    sanitize: SanitizeTool,
    selection: SelectionTool,
    style: StyleTool,
    typeGuard: TypeGuardTool
}

/**
 * Ein Typ zur Beschreibung der Konstruktoren der Tools
 */
type ToolType = {
    [key in keyof typeof Tools]: typeof Tools[key];
}

/**
 * Ein Toolkit-Facroty zur Bereitsstellung von Tools
 */
class Toolkit {
    /**
     * Liefert ein Tool zurück
     *
     * @param tool Das Tool, das geliefert werden soll
     * @param params Die Parameter des Tools
     *
     * @returns Die Instanz des Tools
     */
    public static tool<T extends keyof typeof Tools>(
        tool: T,
        params?: ToolType[T] extends abstract new (...args: any) => any
            ? ConstructorParameters<ToolType[T]>[0]
            : never
    ): ToolType[T] extends { prototype: any} & { getInstance: () => infer R }
        ? R
        : ToolType[T] extends abstract new (...args: any) => any
            ? InstanceType<ToolType[T]>
            : ToolType[T] extends { getInstance: () => infer R } ? R : never
    {
        const ToolClass = Tools[tool] as any;

        if (typeof ToolClass.getInstance === "function")
            return ToolClass.getInstance() as any;

        return new ToolClass(params) as any;
    }
}

export default Toolkit;
