import LinkedList from "@/utils/structures/LinkedList";
import Toolkit from "..";
import ToolkitTool from "../ToolkitTool";

/**
 * Ein Typ für die Methoden des Debug-Containers in {@link DomTool.debugContainer}
 */
type DebugMethod = {
    /**
     * Setzt die Dimensionen des Containers
     *
     * @param width Die Breite
     * @param height Die Höhe
     */
    setSize: (width: number, height: number) => DebugMethod

    /**
     * Setzt die Position des Containers
     *
     * @param x Die X-Koordinate
     * @param y Die Y-Koordinate
     */
    setPosition: (x: number, y: number) => DebugMethod

    /**
     * Setzt die Hintergrundfarbe des Containers
     *
     * @param color Die Hintergrundfarbe
     */
    setBackgroundColor: (color: string) => DebugMethod

    /**
     * Entfernt den Debug-Container
     */
    remove: () => void
}

/**
 * Ein Tool mit Hilfsmethoden für den Umgang mit dem DOM
 */
class DomTool extends ToolkitTool {
    /**
     * Blockelemente
     */
    public static BlockElements = new Set([
        // Textstrukturierende Blockelemente
        "ADDRESS",
        "ARTICLE",
        "ASIDE",
        "BLOCKQUOTE",
        "DETAILS",
        "DIALOG",
        "DD",
        "DL",
        "DT",
        "DIV",
        "FIELDSET",
        "FIGCAPTION",
        "FIGURE",
        "FOOTER",
        "FORM",
        "HEADER",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "HR",
        "MAIN",
        "NAV",
        "P",
        "PRE",
        "SECTION",

        // Listen
        "OL",
        "UL",
        "LI",

        // Tabelle
        "TABLE"
    ]);

    /**
     * Void-Elemente (HTML-Elemente, die keine Kindknoten haben können)
     */
    public static VoidElements = new Set([
        "AREA",
        "BASE",
        "BR",
        "COL",
        "EMBED",
        "HR",
        "IMG",
        "INPUT",
        "LINK",
        "META",
        "PARAM",
        "SOURCE",
        "TRACK",
        "WBR"
    ]);

    /**
     * Prüft, ob die übergebenen Koordinaten innerhalb des {@link element}s liegen
     *
     * @param rect Die Koordinaten
     * @param element Das Element, in dem die Koordinaten liegen sollen
     *
     * @returns Der Status, ob die Koordinaten innerhalb des {@link elements} liegen
     */
    public boundsInsideElement(rect: DOMRect, element: Element) {
        const rootRect = element.getBoundingClientRect();

        return (rect.top >= rootRect.top || rect.bottom >= rootRect.top && rect.bottom <= rootRect.bottom) && rect.top <= rootRect.bottom;
    }

    /**
     * Prüft, ob der Knoten oder ein Elternknoten des Knotens {link node} einem Selektor entspricht.
     *
     * @param node Der zu prüfende Knoten
     * @param selector Der Selektor
     *
     * @returns Das Ergebnis, ob der Knoten oder ein Elternknoten dem Selektor entspricht
     */
    public closest(node: Node | ChildNode | Element | HTMLElement | null, selector: string): boolean {
        if (!node) return false;
        if (node.nodeType !== Node.ELEMENT_NODE && !node.parentElement) return false;

        const element = node.nodeType === Node.ELEMENT_NODE ? node as HTMLElement : node.parentElement as HTMLElement;

        return element.closest(selector) !== null;
    }

    /**
     * Erzeugt {@link amount} Debug-Container und gibt diese in einer ObjektListe zurück, mit dem die Position, Größe und Hintergrundfarbe der Container gesteuert werden kann.
     * Mit der Methode {@link DebugContainer.remove} kann der jeweilige Container wieder entfernt werden.
     *
     * @returns Die Debug-Container
     */
    public debugContainer<N extends number>(amount: N):
        N extends 1 ? DebugMethod :
        DebugMethod[] {
        const colorTool = Toolkit.tool("color");
        const colors = colorTool.generateHarmonicColors("rgba(161, 38, 41, 0.45)", amount);

        const methods = new Array(amount).fill(null).map((_, index) => {
            const debugContainer: HTMLElement = document.querySelector(`.debug-container-${index}`) ?? (() => {
                const div = document.createElement("div");
                div.classList.add(`debug-container-${index}`);

                Object.assign(div.style, {
                    position: "absolute",
                    backgroundColor: colors[index],
                    top: "0px",
                    left: "0px",
                    zIndex: "9999",
                    pointerEvents: "none"
                });

                document.body.appendChild(div);

                return div;
            })();

            const methods: DebugMethod = {
                /**
                 * Setzt die Dimensionen des Containers
                 *
                 * @param width Die Breite
                 * @param height Die Höhe
                 */
                setSize: (width: number, height: number) => {
                    Object.assign(debugContainer.style, {
                        width: `${width}px`,
                        height: `${height}px`
                    });

                    return methods;
                },

                /**
                 * Setzt die Position des Containers
                 *
                 * @param x Die X-Koordinate
                 * @param y Die Y-Koordinate
                 */
                setPosition: (x: number, y: number) => {
                    Object.assign(debugContainer.style, {
                        top: `${y}px`,
                        left: `${x}px`
                    });

                    return methods;
                },

                /**
                 * Setzt die Hintergrundfarbe des Containers
                 *
                 * @param color Die Hintergrundfarbe
                 */
                setBackgroundColor: (color: string) => {
                    debugContainer.style.backgroundColor = color;

                    return methods;
                },

                remove: () => debugContainer.remove()
            };

            return methods;
        });

        if (amount === 1)
            return methods[0] as any;

        return methods as any
    }

    /**
     * Filtert alle Kindknoten bis zum übergebenen Knoten
     *
     * @param childNodes Die Kindknoten
     *
     * @returns Die gefilterten Kindknoten und die erste Liste
     */
    public filterNodesBeforeNode(childNodes: NodeListOf<ChildNode>, node: Node | null = null) {
        const result: ChildNode[] = [];

        if (!node)
            return Array.from(childNodes);

        for (const childNode of childNodes) {
            if (childNode === node)
                break; // Abbruch, sobald die erste Liste gefunden wird


            if (childNode.nodeType === Node.ELEMENT_NODE || childNode.nodeType !== Node.TEXT_NODE) {
                result.push(childNode); // Knoten hinzufügen

                continue;
            }

            if (childNode.nodeType !== Node.TEXT_NODE)
                continue;

            if (this.isEmptyNode(childNode))
                continue;

            result.push(childNode);
        }

        return Array.from(result);
    };

    /**
     * Liefert den ersten Kindknoten (tief) und den Offset des übergebenen Knotens
     *
     * @param node Der Knoten, dessen erstes Kind und Offset ermittelt werden soll
     *
     * @returns Das erste Kind und Offset des Knotens oder null, wenn kein Kindknoten vorhanden ist
     */
    public firstChildAndOffset(node: Node): { node: Node | null, offset: number } {
        if (node.nodeType === Node.TEXT_NODE) return { node, offset: 0 };
        if (node.nodeType !== Node.ELEMENT_NODE) return { node, offset: 0 };

        const element = node as Element;
        let firstChild = element.firstChild;
        let firstChildBounds = this.getBoundingBoxes([firstChild as Node]);


        // while (firstChildBounds && firstChildBounds.length === 0 && firstChild?.firstChild) {
        if (!firstChild) return { node, offset: 0 };

        while (firstChild?.firstChild)
            firstChild = firstChild.firstChild;

        if (firstChild.nodeType === Node.TEXT_NODE)
            return {
                node: firstChild,
                offset: 0
            };

        return {
            node,
            offset: 0
        };
    }

    /**
     * Wandelt ein geschachteltes {@link element} in ein flaches Markup um,
     * wobei Block-Elemente erhalten bleiben und nur deren Inhalte geflattened werden
     *
     * @param element Das Element
     * @param ignoreSelector Ein Array von Selektoren, die nich weiter aufgeflacht werden sollen
     * @param debug Gibt an, ob das Element im sichtaren Dom aufgeflachtet werden soll. Wenn false,
     *             dann wird die Aufflachung in einem {@link DocumentFragment} durchgeführt um performanter zu sein
     *
     * @example
     * // Quellmarkup:
     * <span style="font-size: 4em">
     *      Lorem
     *      <span style="color: red; font-weight: bold">
     *          ipsum
     *          <span style="color: green; font-weight: normal">
     *              dolor
     *              <span style="font-weight: bold; text-decoration: underline">sit</span>
     *          </span>
     *      </span>
     *      amet
     * </span>
     *
     * // Zielmarkup:
     *
     * <span style="font-size: 4em">
     *      <span style="font-size: 4em">Lorem</span>
     *      <span style="font-size: 4em; color: red; font-weight: bold">ispum</span>
     *      <span style="font-size: 4em; color: green; font-weight: normal>dolor</span>
     *      <span style="font-size: 4em; font-weight: bold; text-decoration: underline">sit</span>
     *      <span style="font-size: 4em">amet</span>
     * </span>
     */
    public flatElement(element: Element | DocumentFragment | null | undefined, ignoreSelector: (string | Element)[] = [], debug: boolean = false) {
        if (!element) return;

        const sourceNode = !debug && !(element instanceof DocumentFragment)  ? document.createDocumentFragment() : element;
        const destNode = !debug && !(element instanceof DocumentFragment)?  document.createDocumentFragment() : element;

        if (!debug && !(element instanceof DocumentFragment))
            // Originale Kinder in ein Fragment verschieben
            while (element.firstChild)
                sourceNode.appendChild(element.firstChild);

        // Rekursive Funktion zum aufflachen der Knoten
        const flatten = (node: Node, targetContainer: Element | DocumentFragment) => {
            Array.from(node.childNodes).forEach(child => {
                // 1. Ignorierte Selektoren unverändert übernehmen
                if (
                    child.nodeType === Node.ELEMENT_NODE
                    && ignoreSelector.some(selector => this.isSelectorOrNodeMatch(child as HTMLElement, selector))
                )
                    return targetContainer.appendChild(child);

                // 2. Block-Elemente separat behandeln
                if (this.isBlockElement(child)) {
                    const blockClone = child.cloneNode(false) as Element;
                    targetContainer.appendChild(blockClone);

                    flatten(child, blockClone);

                    if (this.getNodes(child).size === 0)
                        child.parentElement?.removeChild(child);

                    return;
                }

                // 3. Rekursiv für SPANs
                if (child.nodeName === "SPAN") {
                    flatten(child, targetContainer);

                    if (child.childNodes.length === 0)
                        child.parentElement?.removeChild(child);

                    return;
                }

                if (child.nodeType === Node.TEXT_NODE && !child.textContent)
                    return;

                if (child.nodeType === Node.TEXT_NODE && node !== targetContainer && !this.isBlockElement(node)) {
                    const newParentNode = node.cloneNode(false);

                    newParentNode.appendChild(child);
                    targetContainer.appendChild(newParentNode);

                    if (this.getNodes(node).size === 0)
                        node.parentElement?.removeChild(node);

                    return;
                }

                if (this.getNodes(node).size === 0)
                    node.parentElement?.removeChild(node);

                targetContainer.appendChild(child);
            });
        };

        flatten(sourceNode, destNode);

        // Wenn der Zielknoten der gleiche ist wie der aufzuflachende Knoten,
        // dann befinden sich die Kinder bereits im aufzuflachenden Knoten
        if (destNode === element) return;

        // Ergebnis zurück in den aufzuflachenden Knoten verschieben
        element.appendChild(destNode);
    }

    /**
     * Berechnet die Bounding-Boxen der Knoten
     *
     * @param nodes Die Knoten
     * @param draw Gibt an, ob die Bounding-Boxen gezeichnet werden sollen
     *
     * @returns Die Bounding-Boxen der Knoten
     */
    public getBoundingBoxes<D extends boolean>(
        nodes: Node[],
        draw: D = false as D
    ): D extends false
        ? { node: Node, rect: DOMRect }[] | null
        : { node: Node, rect: DOMRect, box: HTMLElement }[] | null {
        if (nodes.length === 0) return null;

        document.querySelectorAll(".bounding-rect").forEach(rect => rect.remove());

        const createDrawRect = (rect: DOMRect, idx: number) => {
            const drawContainer = document.querySelector(".bounding-rect-container") ?? (() => {
                const div = document.createElement("div");
                div.classList.add("bounding-rect-container");

                Object.assign(div.style, {
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    zIndex: "9999",
                    pointerEvents: "none"
                });

                document.body.appendChild(div);

                return div;
            })();

            const box = document.createElement("div");
            box.classList.add("bounding-rect");
            box.innerText = idx.toString();

            Object.assign(box.style, {
                position: "absolute",
                display: "grid",
                placeItems: "center",
                top: `${rect.top}px`,
                left: `${rect.left}px`,
                width: `${rect.width}px`,
                height: `${rect.height}px`,
                border: "1px solid #3D8D7A",
                zIndex: "9999",
                pointerEvents: "none",
                backgroundColor: "rgba(61, 141, 122, 0.7)",
                color: "white",
                fontSize: "24px"
            });

            drawContainer.appendChild(box);

            return box as HTMLElement;
        };

        return Array
            .from(nodes)
            .map((node, idx) => {
                let rect = new DOMRect();

                if (node.nodeType === Node.ELEMENT_NODE && node.childNodes.length === 0)
                    rect = (node as Element).getBoundingClientRect();
                else {
                    const range = new Range();
                    range.selectNode(node);

                    rect = range.getBoundingClientRect();
                }

                if (draw) {
                    const box = createDrawRect(rect, idx + 1);

                    return { node, rect, box };
                }

                return { node, rect };
            })
            .filter(({ rect }) => rect.width > 0 || rect.height > 0) as any;
    }

    /**
     * Prüft, ob der Knoten kein Elementknoten ist und gibt den Elternknoten zurück.
     * Ist der Knoten ein Elementknoten, wird dieser zurückgegeben.
     *
     * @param node Der Knoten
     *
     * @returns Der Elternknoten oder der Knoten selbst oder null, wenn kein Elementknoten gefunden wurde
     */
    public getElement(node: Node) {
        return node.nodeType !== Node.ELEMENT_NODE ? node.parentElement : node as Element;
    }

    /**
     * Ermittelt den letzen Offset eines Knotens
     *
     * @param node Der Knoten
     *
     * @returns Der letzte Offset des Knotens
     */
    private getLastOffsetOfNode(node: Node | ChildNode): number {
        if (node.nodeType === Node.TEXT_NODE)
            return node.textContent?.length ?? 0;

        return node.childNodes.length;
    }

    /**
     * Liest alle Knoten (inklusiver solcher mit Höhe und Breite = 0) des Hauptknoten {@link node} aus.
     *
     * @param node Der Hauptknoten
     * @param whatToShow Der Typ der Knoten, die angezeigt werden sollen (default: Elemente und Textknoten)
     * @param filter Der Filter für die Knoten
     *
     * @returns Die Liste aller Knoten
     */
    public getAllNodes = (node: Node, whatToShow: number = (NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT), filter: NodeFilter | null = null) => {
        const allNodes = new LinkedList<Node>();

        const walker = document.createTreeWalker(node, whatToShow, {
            acceptNode: currentNode => {
                if (!filter)
                    return NodeFilter.FILTER_ACCEPT;

                if (typeof filter === "function")
                    return filter(currentNode);

                return filter.acceptNode(currentNode);
            }
        });

        while (walker.nextNode())
            allNodes.append(walker.currentNode);

        return allNodes;
    }

    /**
     * Liest alle Knoten des Hauptknoten {@link node} aus.
     *
     * @param node Der Hauptknoten
     * @param whatToShow Der Typ der Knoten, die angezeigt werden sollen (default: Elemente und Textknoten)
     * @param filter Der Filter für die Knoten
     *
     * @returns Die Liste aller Knoten
     */
    public getNodes = (node: Node, whatToShow: number = (NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT), filter: NodeFilter | null = null) => {
        const allNodes = new LinkedList<Node>();
        const rootIsFragment = node.nodeType === Node.DOCUMENT_FRAGMENT_NODE || node.getRootNode() instanceof DocumentFragment;

        const walker = document.createTreeWalker(node, whatToShow, {
            acceptNode: currentNode => {
                if (!rootIsFragment && !this.isValidNode(currentNode))
                    return NodeFilter.FILTER_SKIP

                if (!filter)
                    return NodeFilter.FILTER_ACCEPT;

                if (typeof filter === "function")
                    return filter(currentNode);

                return filter.acceptNode(currentNode);
            }
        });

        while (walker.nextNode())
            allNodes.append(walker.currentNode);

        return allNodes;
    }

    /**
     * Liefert alle Knoten ab dem Quellknoten {@link source} bis zum letzten Kind im Wurzelknoten {@link root}
     *
     * @param params Die Parameter
     * @param params.root Der Wurzelknoten
     * @param params.source Der Quellknoten
     *
     * @returns Die Liste aller Knoten vom Quellknoten bis zum letzten Kind im Wurzelknoten
     */
    public getNodesAfter({root, source}: {root: Node, source: Node}): Node[] {
        const typeGuard = Toolkit.tool("typeGuard");
        let foundSource = false;

        const childNodes = this.getNodes(root, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, {
            acceptNode: node => {
                if (node === source)
                    foundSource = true;

                if (!foundSource) return NodeFilter.FILTER_SKIP;

                if (typeGuard.isElement(source) && source.contains(node)) return NodeFilter.FILTER_SKIP;

                return NodeFilter.FILTER_ACCEPT;
            }
        });

        return childNodes.toArray();
    }

    /**
     * Liefert alle Knoten vom Wurzelknoten {@link root} bis zum Zielknoten {@link target}
     *
     * @param params Die Parameter
     * @param params.root Der Wurzelknoten
     * @param params.target Der Zielknoten
     *
     * @returns Die Liste aller Knoten vom Wurzelknoten bis zum Zielknoten
     */
    public getNodesBefore({root, target}: {root: Node, target: Node}): Node[] {
        let foundTarget = false;

        const childNodes = this.getNodes(root, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, {
            acceptNode: node => {
                if (node === target)
                    foundTarget = true;

                if (foundTarget) return NodeFilter.FILTER_REJECT;

                return NodeFilter.FILTER_ACCEPT;
            }
        });

        return childNodes.toArray();
    }

    /**
     * Ermittelt alle Knoten ab dem Offset eines Start- und Endknotens unter Berücksichtigung der Offsets
     *
     * @param param Die Parameter
     * @param param.startNode Der Startknoten
     * @param param.endNode Der Endknoten
     * @param param.includeStartAndEndNode Gibt an, ob der Start- und Endknoten in der Liste enthalten sein sollen (default: false)
     *
     * @returns Die Liste aller Knoten zwischen dem Start- und Endknoten
     */
    public getNodesBetween({ startNode, endNode, includeStartAndEndNode = false }: { startNode: Node | ChildNode, endNode: Node | ChildNode, includeStartAndEndNode?: boolean }) {
        const selectionTool = Toolkit.tool("selection");

        // Der gemeinsame Vorfahrenknoten des Anker- und Fokusknoten
        const commonAncestorContainer = selectionTool.commonAncestorContainer(startNode, endNode);

        // Einen gültigen Startknoten ermitteln
        let start: Node | null = startNode;
        if (!this.isValidNode(start) && start.parentElement)
            start = selectionTool.nextFocus(start.parentElement, Array.from(start.parentElement.childNodes).indexOf(start as ChildNode), commonAncestorContainer).node;

        if (!start) return [];

        // Einen gültigen Endknoten ermitteln
        let end: Node | null = endNode;
        if (!this.isValidNode(end) && end.parentElement)
            end = selectionTool.nextFocus(end.parentElement, Array.from(end.parentElement.childNodes).indexOf(end as ChildNode), commonAncestorContainer).node;

        if (!end) return [];

        let foundStart = commonAncestorContainer === startNode;
        let foundEnd = commonAncestorContainer === endNode;

        const typeGuard = Toolkit.tool("typeGuard");

        // Alle gültigen Knoten zwischen dem Start- und Endknoten ermitteln
        const nodesBetween = this.getNodes(commonAncestorContainer, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, {
            acceptNode: (node) => {
                if (foundEnd) return NodeFilter.FILTER_REJECT;

                if (node === start) {
                    foundStart = true;
                    return includeStartAndEndNode ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
                }

                if (node === end) {
                    foundEnd = true;
                    return includeStartAndEndNode ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
                }

                if (!foundStart) return NodeFilter.FILTER_SKIP;
                if (typeGuard.isElement(start) && start.contains(node)) return NodeFilter.FILTER_SKIP;

                return NodeFilter.FILTER_ACCEPT;
            }
        });

        return Array.from(nodesBetween);
    }

    /**
     * Präzisiert die Position eines Cursors, indem ein Element+Offset in den konkreten Textknoten+Offset
     * umgewandelt wird, der diese Position im DOM repräsentiert. Dies ist besonders wichtig für Selektion
     * und Cursor-Positionierung, da der Browser genauere Koordinaten benötigt.
     *
     * @param node Der Knoten
     * @param offset Der Offset
     * @param exclude Ein Selektor oder Element oder Array von Selektoren oder Elementen, die nicht berücksichtigt werden sollen
     *
     * @example
     * // HTML Struktur:
     * <div id="editor">
     *      <p>
     *          Hallo
     *          <span class="highlight">
     *              Welt
     *          </span>
     *          <span></span>
     *          !
     *      </p>
     * </div>
     *
     * // Codebeispiele:
     * const domTool = Toolkit.tool("dom");
     * const editor = document.getElementById("editor");
     *
     * // Fall 1 : Mit Ausschluss bestimmter Elemente
     * const result1 = domTool.getTargetAndOffset(paragraph, 1, ".highlight");
     * // → { node: paragraph, offset: 1 } (bleibt auf Paragraph-Ebene, da Highlight ausgeschlossen)
     *
     * // Fall 2: Textknoten (gibt den Textknoten selbst zurück)
     * const textNode = editor.querySelector("p").firstChild;
     * const result2 = domTool.getTargetAndOffset(textNode, 2);
     * // → { node: #text "Hallo ", offset: 2 } (zeigt auf "Ha|llo")
     *
     * // Fall 3: Element mit Offset am Ende (navigiert zum letzten Kind)
     * const result3 = domTool.getTargetAndOffset(paragraph, 4);
     * // → { node: #text "!", offset: 1 } (zeigt auf das Ende von "!")
     *
     * // Fall 4: Element mit einem Offset, das selbst ein Element ist (navigiert zum Kind)
     * const result4 = domTool.getTargetAndOffset(paragraph, 1);
     * // → { node: #text "Welt", offset: 0 } (zeigt auf den Anfang von "Welt")
     *
     * // Fall 5: Element mit einem Offset, das selbst ein Element ist, aber keine Kinder hat (navigiert zum Elternknoten)
     * const result5 = domTool.getTargetAndOffset(paragraph, 3);
     * // → { node: paragraph, offset: 3 } (zeigt auf den leeren span)
     *
     * @returns Der tatsächliche Knoten und Offset, der dem übergebenen Knoten und Offset entspricht
     */
    public getTargetAndOffset(node: Node | ChildNode, offset: number, exclude?: string | Element | (string | Element)[]): { node: Node, offset: number } {
        if (exclude && !Array.isArray(exclude))
            exclude = [exclude];

        const elementNode = node.nodeType !== Node.ELEMENT_NODE ? node.parentElement : node as Element;
        // Fall 1: Wenn der Elementknoten > elementNode < nicht berücksichtigt werden soll, dann den Knoten und Offset zurückgeben
        if (exclude && Array.isArray(exclude) && exclude.some(selector => this.isSelectorOrNodeMatch(elementNode, selector) || (typeof selector === "string" && this.closest(elementNode, selector))))
            return { node, offset };

        // Fall 2: Wenn der Knoten ein Textknoten ist, dann den Textknoten und den Offset zurückgeben
        if (node.nodeType === Node.TEXT_NODE)
            return { node, offset };

        // Fall 3: Wenn der Knoten ein Elementknoten ist und amm Offset kein Knoten existiert, dann den letzten Kindknoten und Offset zurückgeben
        if (node.nodeType === Node.ELEMENT_NODE && node.childNodes.length > 0 && !node.childNodes[offset])
            return this.getTargetAndOffset(node.lastChild!, this.getLastOffsetOfNode(node.lastChild!));

        // Fall 4: Wenn der Knoten ein Elementknoten ist und am Offset ein Knoten existiert, dann den Kindknoten und Offset zurückgeben
        if (node.nodeType === Node.ELEMENT_NODE && node.childNodes.length > 0 && node.childNodes[offset])
            return this.getTargetAndOffset(node.childNodes[offset], 0);

        // Fall 5: Wenn der Knoten ein Void-Element ist oder keine Kindknoten hat und ein Elternknoten existiert, dann das Elternelement und den Offset des Elements zurückgeben
        if (node.nodeType === Node.ELEMENT_NODE && ( node.childNodes.length === 0 || DomTool.VoidElements.has((node as Element).nodeName)) && node.parentElement)
            return {
                node: node.parentElement,
                offset: Array.from(node.parentElement.childNodes).indexOf(node as ChildNode)
            };

        return { node, offset };
    }

    /**
     * Prüft, ob der Knoten ein Block-Element ist
     *
     * @param node Der Knoten
     *
     * @returns Das Ergebnis, ob der Knoten ein Block-Element ist
     */
    public isBlockElement(node: Node): boolean {
        return node.nodeType === Node.ELEMENT_NODE && DomTool.BlockElements.has((node as Element).nodeName);
    }

    /**
     * Prüft ob der Inhalt des editierbaren Elements {@link editable} leer ist.
     *
     * @param editable Das editierbare Element
     *
     * @returns Das Ergebnis, ob der Text leer ist
     */
    public isEmptyEditable(editable: HTMLElement) {
        if (!editable) return false;

        if (editable.childNodes.length === 1 && editable.firstChild?.nodeName !== "BR")
            return false;

        const elementNodes = Array.from(editable.childNodes).filter(node => node.nodeName !== "BR" && node.nodeType !== Node.COMMENT_NODE && node.nodeType !== Node.TEXT_NODE);
        if (elementNodes.length > 0) return false;

        if ((editable.textContent ?? "").length > 0)
            return false;

        return true;
    }

    /**
     * Prüft, ob eine Liste leer ist. Eine Liste ist leer, wenn sie keine gültigen Kindknoten besitzt.
     *
     * @param node Der zu prüfende Knoten
     *
     * @returns Das Ergebnis, ob die Liste leer ist
     */
    public isEmptyList(node: Node, selectorOrNode?: string | Node | (string | Node)[] | null | undefined) {
        if (node.nodeType !== Node.ELEMENT_NODE || !["UL", "OL"].includes(node.nodeName)) return false;
        if (!selectorOrNode) selectorOrNode = [];
        if (!Array.isArray(selectorOrNode)) selectorOrNode = [selectorOrNode];

        const nodes = this.getNodes(node, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, {
            acceptNode: node => {
                if (node.nodeName === "LI") return NodeFilter.FILTER_SKIP;
                if (this.isEmptyNode(node) && !this.matchSelector(node, selectorOrNode)) return NodeFilter.FILTER_SKIP;

                return NodeFilter.FILTER_ACCEPT;
            },
        });

        return nodes.size === 0;
    }

    /**
     * Prüft, ob das übergebene Listenelement leer ist. Ein Listenelement ist leer, wenn es keine Kindknoten besitzt oder nur
     * ein Zeilenumbruchknoten besitzt.
     *
     * @param element Das zu prüfende Element
     * @param lineBreakAsEmptyNode Gibt an, ob ein Zeilenumbruchknoten als leerer Knoten betrachtet werden soll
     * @param ignoreSelectorOrElement Ein Selektor oder Element oder Array von Selektoren oder Elementen, die nicht berücksichtigt werden sollen
     */
    public isEmptyListItem(element: Element, lineBreakAsEmptyNode = true, ignoreSelectorOrElement: string | Element | (string | Element)[] = []) {
        if (element.nodeName !== "LI") return false;
        if (element.childNodes.length === 0)
            return true;

        ignoreSelectorOrElement = (!Array.isArray(ignoreSelectorOrElement) ? [ignoreSelectorOrElement] : ignoreSelectorOrElement);

        const childNodes = Array
            .from(element.childNodes)
            .filter(node => {
                if ((ignoreSelectorOrElement as (string | Element)[]).some(selector => this.isSelectorOrNodeMatch(node as HTMLElement, selector))) return false;
                if (!lineBreakAsEmptyNode && node.nodeName === "BR") return true;
                if (!this.isValidNode(node)) return false;
                if (node.nodeType === Node.ELEMENT_NODE) return true;
                if (node.nodeType === Node.TEXT_NODE && (node.textContent?.length ?? 0) > 0) return true;

                return false;
            });

        return childNodes.length === 0;
    }

    /**
     * Prüft, ob ein Knoten leer ist
     *
     * @param node Der zu prüfende Knoten
     * @param ingnore Ein Array von Selektoren oder Knoten, die nicht berücksichtigt werden sollen
     *
     * @returns Das Ergebnis, ob der Knoten leer ist
     */
    public isEmptyNode(node: Node | null, ingnore: (string | Node)[] = []): boolean {
        if (!node) return true;

        const typeGuard = Toolkit.tool("typeGuard");

        if (!this.isValidNode(node)) return true;
        if (this.isVoidElement(node)) return true;
        if (typeGuard.isElement(node) && node.childNodes.length === 0) return true;
        if (typeGuard.isText(node) && (node.textContent?.length ?? 0) === 0) return true;
        if (typeGuard.isText(node) && (node.textContent?.length ?? 0) > 0) return false;
        if (this.matchSelector(node, ingnore)) return true;

        const isEmptyElement = (element: Element): boolean => {
            const childNodes = Array
                .from(element.childNodes)
                .filter(childNode => {
                    if (!this.isValidNode(childNode)) return false;
                    if (this.isVoidElement(childNode)) return true;
                    if (this.matchSelector(childNode, ingnore)) return false;
                    if (typeGuard.isText(childNode) && (childNode.textContent?.length ?? 0) === 0) return false;
                    if (typeGuard.isText(childNode) && (childNode.textContent?.length ?? 0) > 0) return true;
                    if (typeGuard.isElement(childNode)) return !isEmptyElement(childNode);

                    return false;
                });

            return childNodes.length === 0;
        };

        if (typeGuard.isElement(node)) return isEmptyElement(node);

        return true;
    }

    /**
     * Prüft, ob ein Knoten ein Inline-Block-Element ist
     *
     * @param node
     * @returns
     */
    public isInlineBlock(node?: Node | null): boolean {
        if (!node)
            return false;

        const element = node.nodeType !== Node.ELEMENT_NODE
            ? node.parentElement
            : node as HTMLElement;

        if (!element)
            return false;

        return window.getComputedStyle(element).display === "inline-block";
    }

    /**
     * Prüft, ob ein Knoten einem Selektor entspricht.
     *
     * @param node Der Knoten
     * @param selectorOrNode Der Selektor
     *
     * @returns Das Ergebnis, ob der {@link node} dem {@link selectorOrNode} entspricht
     */
    public isSelectorOrNodeMatch(node: Node | null | undefined, selectorOrNode: string | Node | null | undefined): node is HTMLElement {
        if (!selectorOrNode) return false;
        if (!node) return false;
        if (!(node instanceof HTMLElement)) return false;
        if (selectorOrNode instanceof Node) return node === selectorOrNode;

        const matchTag = node instanceof Element && node.nodeName === selectorOrNode.toUpperCase();
        if (matchTag) return true;

        const matchId = node.id === selectorOrNode.replace("#", "");
        if (matchId) return true;

        const matchClass = node.classList.contains(selectorOrNode.replace(".", ""));
        if (matchClass) return true;

        return false;
    }

    /**
     * Prüft, ob die übergebene Bounding-Box gültig ist.
     *
     * @param rect Die zu prüfende Bounding-Box
     */
    public isValidDomRect(rect: DOMRect | DOMRectList) {
        if (rect instanceof DOMRect)
            return rect.width > 0 || rect.height > 0;

        return rect.length > 0 && (rect[0].width > 0 || rect[0].height > 0)
    }

    /**
     * Prüft, ob ein Knoten ein messbarer Knoten im Dom ist
     *
     * @param node Der Knoten
     *
     * @returns Das Ergebnis, ob der Knoten ein messbarer Knoten im Dom ist
     */
    public isValidNode(node: Node): boolean {
        return this.isValidDomRect(this.nodeBounds(node));
    }

    /**
     * Prüft, ob ein Knoten ein {@link DomTool.VoidElements} ist
     *
     * @param node Der Knoten
     *
     * @returns Das Ergebnis, ob der Knoten ein {@link DomTool.VoidElements} ist
     */
    public isVoidElement(node: Node): boolean {
        return node.nodeType === Node.ELEMENT_NODE && DomTool.VoidElements.has((node as Element).nodeName);
    }

    /**
     * Liefert den letzten Kindknoten (tief) und den Offset des übergebenen Knotens
     *
     * @param node Der Knoten, dessen letztes Kind und Offset ermittelt werden soll
     *
     * @returns Das letzte Kind und Offset des Knotens oder null, wenn kein Kindknoten vorhanden ist
     */
    public lastChildAndOffset(node: Node): { node: Node | null, offset: number } {
        if (this.isValidNode(node) && node.nodeType === Node.TEXT_NODE) return { node, offset: node.textContent?.length ?? 0 };
        if (node.nodeType !== Node.ELEMENT_NODE) return { node, offset: 0 };

        const nodes = this.getNodes(node);
        if (nodes.size === 0) return { node, offset: 0 };

        let lastNode = nodes.tail;
        if (!lastNode) return { node, offset: 0 };

        if (lastNode.value.nodeType === Node.TEXT_NODE)
            return { node: lastNode.value, offset: lastNode.value.textContent?.length ?? 0 };

        return { node, offset: node.childNodes.length };
    }

    /**
     * Ersetzt alle Tags in einem Element durch gültige span-Tags und erweitert diese um den Stil, der durch das Tag definiert wurde
     *
     * @example
     *
     * Aus <b>Text</b> wird <span style="font-weight: bold;">Text</span>
     *
     * @param element Das Element
     */
    public mapTags(element: Node) {
        if (element.childNodes.length === 0) return;

        const typeGuard = Toolkit.tool("typeGuard");

        Array
            .from(element.childNodes)
            .forEach(node => {
                if (typeGuard.isTag(node, "b"))
                    node.outerHTML = `<span style="font-weight: bold;">${node.innerHTML}</span>`;

                if (typeGuard.isTag(node, "i"))
                    node.outerHTML = `<span style="font-style: italic;">${node.innerHTML}</span>`;

                if (typeGuard.isTag(node, "u"))
                    node.outerHTML = `<span style="text-decoration: underline;">${node.innerHTML}</span>`;

                if (typeGuard.isTag(node, "s"))
                    node.outerHTML = `<span style="text-decoration: line-through;">${node.innerHTML}</span>`;

                this.mapTags(node);
            });
    }

    /**
     * Prüft, ob ein Knoten einem Selektor im Array entspricht.
     *
     * @param Node Der Knoten
     * @param match Die Selektoren
     *
     * @returns Das Ergebnis, ob das Element einem Selektor entspricht
     */
    public matchSelector(node: Node, match: (string | Node | null | undefined)[] | string | Node | null | undefined = []) {
        if (!match) match = [];
        if (!Array.isArray(match)) match = [match];

        return match.some(selector => this.isSelectorOrNodeMatch(node, selector));
    }

    /**
     * Ermittelt die Bounding-Box eines Knotens.
     *
     * @param node Der Knoten
     * @param draw Gibt an, ob die Bounding-Box gezeichnet werden soll
     *
     * @returns Die Bounding-Box des Knotens
     */
    public nodeBounds(node: Node, draw: boolean = false): DOMRect {
        if (!document.body.contains(node)) return new DOMRect();

        if (node.nodeType === Node.ELEMENT_NODE) {
            const boundingBox = (node as Element).getBoundingClientRect();

            if (!draw) return boundingBox;

            this.debugContainer(1)
                .setSize(boundingBox.width, boundingBox.height)
                .setPosition(boundingBox.left, boundingBox.top);

            return boundingBox;
        }

        const range = document.createRange();
        range.selectNode(node);

        const boundingBox = range.getBoundingClientRect();

        if (!draw) return boundingBox;

        this.debugContainer(1)
            .setSize(boundingBox.width, boundingBox.height)
            .setPosition(boundingBox.left, boundingBox.top);

        return boundingBox;
    }

    /**
     * Ermittelt die Bounding-Box eines Knotens an einem bestimmten Offset.
     *
     * @param node Der Knoten
     * @param offset Der Offset
     * @param root Das Wurzelelement, bis zu dem die Bounding-Box ermittelt werden darf
     *
     * @returns Die Bounding-Box des Knotens an dem Offset
     */
    public nodeOffsetBounds(node: Node, offset: number, root?: HTMLElement): DOMRect {
        const range = new Range();

        const { node: focusNode, offset: focusOffset } = this.getTargetAndOffset(node, offset);

        if (focusNode.nodeType === Node.ELEMENT_NODE && focusNode.childNodes.length === 0)
            return (focusNode as Element).getBoundingClientRect();

        // Fall: Element mit Kind-Element am gegebenen Offset
        if (focusNode.nodeType === Node.ELEMENT_NODE && focusNode.childNodes.length > 0 && focusNode.childNodes[focusOffset]?.nodeType === Node.ELEMENT_NODE)
            return (focusNode.childNodes[focusOffset] as Element).getBoundingClientRect();

        // Strategie 1: Exakte Range
        try {
            range.setStart(focusNode, focusOffset);
            range.setEnd(focusNode, focusOffset);

            // ClientRects statt BoundingClientRect für bessere Browser-Kompatibilität
            const rects = range.getClientRects();
            if (this.isValidDomRect(rects)) return rects[0];

            const rect = range.getBoundingClientRect();
            if (this.isValidDomRect(rect)) return rect;
        } catch {
            // Nächste Strategie versuchen
        }

        // Strategie 2: Erweiterte Range - ein Zeichen vorwärts
        if (focusNode.nodeType === Node.TEXT_NODE && focusOffset < (focusNode.textContent?.length || 0))
            try {
                range.setStart(focusNode, focusOffset);
                range.setEnd(focusNode, focusOffset + 1);

                const rect = range.getBoundingClientRect();

                if (this.isValidDomRect(rect))
                    return new DOMRect(rect.left, rect.top, 0, rect.height);
            } catch {
                // Nächste Strategie versuchen
            }

        // Strategie 3: Erweiterte Range - ein Zeichen rückwärts
        if (focusNode.nodeType === Node.TEXT_NODE && focusOffset > 0)
            try {
                range.setStart(focusNode, focusOffset);
                range.setEnd(focusNode, focusOffset - 1);

                const rect = range.getBoundingClientRect();

                if (this.isValidDomRect(rect))
                    return new DOMRect(rect.right, rect.top, 0, rect.height);
            } catch {
                // Nächste Strategie versuchen
            }

        // Strategie 4: Aus zwei benachbarten Ranges interpolieren
        if (focusNode.nodeType === Node.TEXT_NODE)
            try {
                // Position aus umliegenden Zeichen interpolieren
                const prevOffset = Math.max(0, focusOffset - 1);
                const nextOffset = Math.min(focusNode.textContent?.length || 0, focusOffset + 1);

                const prevRange = new Range();
                prevRange.setStart(focusNode, prevOffset);
                prevRange.setEnd(focusNode, prevOffset);

                const nextRange = new Range();
                nextRange.setStart(focusNode, nextOffset);
                nextRange.setEnd(focusNode, nextOffset);

                const prevRect = prevRange.getBoundingClientRect();
                const nextRect = nextRange.getBoundingClientRect();

                if (this.isValidDomRect(prevRect) && this.isValidDomRect(nextRect)) {
                    // Lineare Interpolation zwischen den beiden Positionen
                    const factor = (focusOffset - prevOffset) / (nextOffset - prevOffset);
                    const x = prevRect.left + (nextRect.left - prevRect.left) * factor;

                    return new DOMRect(x, prevRect.top, 0, prevRect.height);
                }
            } catch (e) {
                // Nächste Strategie versuchen
            }

        // Strategie 5: Erweiterte Range - ganzer Text des Knotens
        if (focusNode.nodeType === Node.TEXT_NODE && focusNode.textContent)
            try {
                const range = new Range();
                range.selectNodeContents(focusNode);
                const fullRect = range.getBoundingClientRect();

                if (!this.isValidDomRect(fullRect)) return new DOMRect();

                // Proportionale Position im Text basierend auf Offset
                const textLength = focusNode.textContent.length;
                if (textLength === 0) return fullRect;

                const factor = focusOffset / textLength;
                const x = fullRect.left + fullRect.width * factor;

                return new DOMRect(x, fullRect.top, 0, fullRect.height);
            } catch (e) {
                // Nächste Strategie versuchen
            }

        let previousSibling = focusNode.previousSibling;
        let parentNode = focusNode.parentNode;

        while (!previousSibling && parentNode && parentNode !== root) {
            previousSibling = parentNode.previousSibling;
            parentNode = parentNode.parentNode;
        }

        if (!previousSibling) return new DOMRect();

        if (previousSibling.nodeType !== Node.ELEMENT_NODE && previousSibling.nodeType !== Node.TEXT_NODE)
            return new DOMRect();

        if (previousSibling.nodeType === Node.TEXT_NODE && (!previousSibling.textContent || previousSibling.textContent.length === 0))
            return new DOMRect();

        if (previousSibling.nodeType === Node.TEXT_NODE)
            return this.nodeOffsetBounds(previousSibling, previousSibling.textContent!.length, root);

        let lastChild = previousSibling.lastChild;
        let newOffset = previousSibling.childNodes.length;

        while (lastChild?.lastChild && lastChild.childNodes.length > 0) {
            lastChild = lastChild.lastChild;
            newOffset = lastChild.nodeType === Node.TEXT_NODE
                ? lastChild.textContent?.length || 0
                : lastChild.childNodes.length;
        }

        return lastChild
            ? this.nodeOffsetBounds(lastChild, newOffset, root)
            : this.nodeOffsetBounds(previousSibling, previousSibling.childNodes.length, root);
    }
}

export default DomTool;
