import type { API } from "@editorjs/editorjs";
import type { ScopedRange } from "@/utils/Editor/utils/selectionManager";
import type EditorJS from "@editorjs/editorjs";
import Toolkit from "..";
import ToolkitTool from "../ToolkitTool";

type CaretTrapPosition = "LEFT_FROM_TRAP" | "RIGHT_FROM_TRAP" | false

export type MacroInfo = {
    leftTrap: Node | null
    rightTrap: Node | null
    label: Element | null
    rangeInLabel: boolean
    startOfMacro: CaretTrapPosition
    endOfMacro: CaretTrapPosition
    macro: HTMLElement | null
}

export type MacroInfos = {
    start: MacroInfo,
    end: MacroInfo
}


/**
 * Der Inhalt einer Makrofalle
 */
export const trapContent = '\u200B';

/**
 * Ein Tool, das Makro-Hilfsmethoden bereitstellt.
 */
export default class MacroTool extends ToolkitTool {
    /**
     * Die Instanz des Macro-Tools.
     */
    private static Instance: MacroTool;

    /**
     * C'tor.
     */
    private constructor() {
        super();
    }

    /**
     * Erzeugt eine Instanz des Macro-Tools, falls noch keine existiert
     *
     * @returns Die Instanz des Macro-Tools
     */
    public static getInstance() {
        if (!MacroTool.Instance)
            MacroTool.Instance = new MacroTool();

        return MacroTool.Instance;
    }

    /**
     * Passt eine Range so an, dass Makros korrekt berücksichtigt werden
     *
     * @param range Die anzupassende Range
     * @param start Makro-Informationen am Start der Range
     * @param end Makro-Informationen am Ende der Range
     */
    private adjustRangeForMacros(range: Range, start: MacroInfo, end: MacroInfo): Range {
        // Am Anfang eines Makros: Range vor dem Makro positionieren
        if (start.startOfMacro) range.setStartBefore(start.macro!);
        else if (start.endOfMacro) range.setStartAfter(start.macro!);

        // Am Ende eines Makro-Startteils: Range nach dem Makro positionieren
        if (start.endOfMacro) range.setEndAfter(start.macro!);

        // Am Ende eines ganzen Makros: Range nach dem Makro positionieren
        if (end.endOfMacro) range.setEndAfter(end.macro!);

        return range;
    }

    /**
     * Hängt ein Makro an der aktuellen Fokusposition ein
     *
     * @param params Die Parameter
     * @param params.api Die EditorJs API
     * @param params.blockFocusTracker Der Block-Fokus-Tracker
     * @param params.macroSelection Die Makroauswahl
     */
    public async appendMacro({ api, blockFocusTracker, macroSelection }: { api: API | EditorJS, blockFocusTracker: IBlockFocusTracker, macroSelection: () => Promise<TMacroResult> }) {
        api.toolbar.close();

        const focused = blockFocusTracker.getSelection();
        const focusedBlock = focused && api.blocks.getBlockByIndex(focused.focusIndex);
        const affectedBlock = api.blocks.getBlockByIndex(api.blocks.getCurrentBlockIndex())!;
        const holder = affectedBlock?.holder.querySelector(".cdx-block");

        let shortcode: string | null = null;
        let parameters: TMacroSettingsResult | undefined;

        let focusNode: Node | null | undefined = focusedBlock?.id === affectedBlock.id
            ? focused?.focusNode!
            : holder as Node


        let focusOffset = focusedBlock?.id === affectedBlock.id
            ? focused?.focusOffset || 0
            : 0;

        /**
         * Setzt die Auswahl zurück
         *
         * @param node Der Knoten
         * @param offset Die Position
         *
         * @returns Die Range
         */
        const resetSelection = (node: Node, offset: number): Range => {
            const selection = getSelection();

            selection?.removeAllRanges();

            const range = new Range();

            range.setStart(node, offset);
            range.setEnd(node, offset);

            selection?.addRange(range);

            return range;
        };

        try {
            const result = await macroSelection();

            shortcode = result.shortcode;
            parameters = result.settings;
        } catch (e) {
            return resetSelection(focusNode, focusOffset);
        }

        if (!document.body.contains(focusNode))
            focusNode = api.blocks.getBlockByIndex(focused.focusIndex)?.holder.querySelector(".cdx-block");

        if (!focusNode)
            return;

        const macro = this.generateMacro({
            shortcode: shortcode!,
            parameters
        });

        const range = resetSelection(focusNode, focusOffset);

        range.insertNode(macro);

        setTimeout(() => {
            const selection = getSelection();

            selection?.removeAllRanges();

            const range = new Range();

            range.setStartAfter(macro);
            range.setEndAfter(macro);

            selection?.addRange(range);

            blockFocusTracker.updateSelection();
        }, 1);
    }

    /**
     * Ummantelt ein Makro mit den notwendigen Stilelementen.
     */
    private applyWrappers (macro: Element) {
        const style = macro.getAttribute("style");
        const label = macro.querySelector(".label")?.cloneNode(true);

        if (!label)
            return;

        const firstTrap = macro.querySelector(".trap") as Element | null;

        if (!firstTrap)
            return;

        const children = Array.from(firstTrap.querySelectorAll("*")) as Element[] | null;
        const firstChild = children?.at(0);
        const lastChild = children?.at(-1);

        if (!firstChild || !lastChild)
            return;

        const newMacro = document.createElement("span");
        newMacro.setAttribute("class", macro.getAttribute("class") || "");
        newMacro.setAttribute("style", style || "");

        const traps = {
            left: document.createElement("span"),
            right: document.createElement("span"),
        };

        traps.left.classList.add("trap");
        traps.right.classList.add("trap");

        traps.left.innerHTML = trapContent;
        traps.right.innerHTML = trapContent;

        newMacro.appendChild(traps.left);
        newMacro.appendChild(label);
        newMacro.appendChild(traps.right);

        lastChild.innerHTML = "";

        lastChild.appendChild(newMacro);

        macro.parentElement?.insertBefore(firstChild, macro);

        macro.remove();
    }

    /**
     * Repariert ein Makro bei dem nur das Label durch einen Kopier- und Einfügevorgang
     * eingefügt wurde
     *
     * Das passiert, wenn nur das Makro für den Kopier- und Einfügevorgang auswählt wurde
     *
     * @param label Das Label, das repariert werden soll
     */
    public fixLabel(label: Element) {
        if (!label.parentElement)
            return;

        const macro = document.createElement("span");
        const trap = {
            left: document.createElement("span"),
            right: document.createElement("span"),
        };

        macro.classList.add("macro");

        trap.left.classList.add("trap");
        trap.right.classList.add("trap");

        trap.left.textContent = trapContent;
        trap.right.textContent = trapContent;

        macro.appendChild(trap.left);
        macro.appendChild(trap.right);

        label.parentElement.insertBefore(macro, label);
        macro.insertBefore(label, macro.querySelector(".trap")!.nextSibling);
    }

    /**
     * Repariert ein Makro.
     *
     * Hintergrund: Firefox und Chrome handhaben Stiländerungen
     * eines Makros unterschiedlich und führt zu kaputten Makros.
     *
     * @param macro Das Makro, das repariert werden soll
     */
    public fixMacro(macro: Element){
        const traps = Array
            .from(macro.querySelectorAll(".trap"))
            .filter(trap => trap.firstChild && trap.firstChild.nodeType === Node.ELEMENT_NODE);

        if (!traps.length) return;

        this.applyWrappers(macro);
    }

    /**
     * Erzeugt ein Makro mit den angegebenen Parametern
     *
     * @param {object} options - Die Optionen
     * @param {string} options.shortcode - Der Typ des Makros
     * @param {[key:string]:any} options.parameters - Die Parameter des Makros
     */
    public generateMacro({ shortcode, parameters }: { shortcode: string, parameters?: { [key: string]: any } }): HTMLSpanElement {
        const macro = document.createElement("span");
        const label = document.createElement("span");
        const trap = {
            left: document.createElement("span"),
            right: document.createElement("span"),
        };

        macro.classList.add("macro");

        trap.left.classList.add("trap");
        trap.right.classList.add("trap");

        trap.left.textContent = trapContent;
        trap.right.textContent = trapContent;

        label.classList.add("label");
        label.contentEditable = "false";

        label.setAttribute("data-macro", shortcode);
        label.textContent = shortcode;

        if (parameters && Object.keys(parameters).length)
            Object.entries(parameters).forEach(([key, value]) =>
                label.setAttribute(`data-parameter-${key}`, value)
            );

        macro.appendChild(trap.left);
        macro.appendChild(label);
        macro.appendChild(trap.right);

        return macro;
    }

    /**
     * Liefert die Position des Cursors in der Makrofalle
     *
     * @param macro Das Makro
     * @param trapA Eine Makrofalle (links oder rechts) im Makro
     * @param trapB Eine Makrofalle (links oder rechts) im Makro
     *
     * @returns Die Position des Cursors in der Makrofalle
     */
    private getCaretPositionInTrap(macro: Element | null, trapA: Element | null, trapB: Element | null, range: ScopedRange | Range): { caretInLeftTrap: CaretTrapPosition, caretInRightTrap: CaretTrapPosition } {
        if (!macro) return { caretInLeftTrap: false, caretInRightTrap: false };

        let caretInLeftTrap: CaretTrapPosition = false;

        if (macro.firstChild === trapA)
            caretInLeftTrap = range.startOffset === 0 ? "LEFT_FROM_TRAP" : "RIGHT_FROM_TRAP";
        else if (macro.firstChild === trapB)
            caretInLeftTrap = range.endOffset === 0 ? "LEFT_FROM_TRAP" : "RIGHT_FROM_TRAP";
        else if (trapA?.closest(".label") || trapB?.closest(".label"))
            caretInLeftTrap = "RIGHT_FROM_TRAP";

        let caretInRightTrap: CaretTrapPosition = false;

        if (macro.lastChild === trapA)
            caretInRightTrap = range.startOffset === 0 ? "LEFT_FROM_TRAP" : "RIGHT_FROM_TRAP";
        else if (macro.lastChild === trapB)
            caretInRightTrap = range.endOffset === 0 ? "LEFT_FROM_TRAP" : "RIGHT_FROM_TRAP";
        else if (trapA?.closest(".label") || trapB?.closest(".label"))
            caretInRightTrap = "LEFT_FROM_TRAP";

        return {
            caretInLeftTrap,
            caretInRightTrap
        };
    }

    /**
     * Prüft, ob es sich bei dem übergebenen Knoten um einen Trap-Knoten des Makros handelt
     *
     * @param node - Der zu prüfende Knoten
     *
     * @returns Der Wert, ob der Knoten ein magischer Marker ist
     */
    public isTrapContentNode(node: Node) {
        const elementNode = node.nodeType !== Node.ELEMENT_NODE ? node.parentElement : node as Element;

        if (!elementNode?.closest(".trap"))
            return false;

        return node instanceof Text && node.textContent === trapContent;
    }

    /**
     * Prüft, ob es sich bei dem übergebenen Knoten um ein Makro handelt oder sich der Knoten in einem Makro befindet
     *
     * @param node Der zu prüfende Knoten
     *
     * @returns Der Wert, ob der Knoten ein Makro ist oder sich in einem Makro befindet
     */
    public isMacroNode(node?: Node | null) {
        if (!node) return false;

        const elementNode = node.nodeType !== Node.ELEMENT_NODE ? node.parentElement : node as Element;

        return elementNode?.closest(".macro") !== null;
    }

    /**
     * Liefert das Makro, in dem sich der übergebene Knoten befindet
     *
     * @param node Der Knoten
     *
     * @returns Das Makro, in dem sich der Knoten befindet, oder null, wenn der Knoten nicht in einem Makro ist
     */
    public getMacro(node?: Node | null) {
        if (!node) return null;

        const elementNode = node.nodeType !== Node.ELEMENT_NODE ? node.parentElement : node as Element;

        return elementNode?.closest(".macro") ?? null;
    }

    /**
     * Bereitet eine Range für Dom-Manipulationen vor, unter Berücksichtigung von Makros
     *
     * @param selectionOrRange Die aktuelle Selektion oder Range
     *
     * @returns Die angepasste Range oder null bei Problemen
     */
    public prepareRangeForManipulation(selectionOrRange: Selection | Range): Range | null {
        if (selectionOrRange instanceof Selection && selectionOrRange.rangeCount === 0) return null;

        const range = selectionOrRange instanceof Selection
            ? selectionOrRange.getRangeAt(0)
            : selectionOrRange;

        const { start, end } = this.rangeInMacro(range);

        // Makro-Grenzen berücksichtigen
        const preparedRange = this.adjustRangeForMacros(range, start, end);

        return preparedRange;
    }

    /**
     * Prüft, ob die Range Makros beinhaltet und liefert hierzu Makroinformationen
     *
     * @param range Die Range
     *
     * @returns Die Makroinformationen der Range
     */
    public rangeInMacro(range?: ScopedRange | Range): MacroInfos {
        const nullMacroInfo: MacroInfos = {
            start: {
                leftTrap: null,
                rightTrap: null,
                label: null,
                rangeInLabel: false,
                startOfMacro: false,
                endOfMacro: false,
                macro: null
            },

            end: {
                leftTrap: null,
                rightTrap: null,
                label: null,
                rangeInLabel: false,
                startOfMacro: false,
                endOfMacro: false,
                macro: null
            }
        };

        if (!range) return nullMacroInfo;
        if (!range.startContainer || !(range.startContainer instanceof Node)) return nullMacroInfo;
        if (!range.endContainer || !(range.endContainer instanceof Node)) return nullMacroInfo;
        if (!document.contains(range.startContainer) || !document.contains(range.endContainer)) return nullMacroInfo;

        // Die expliziten Startknoten der Range zuweisen
        const startNode = range.startContainer;
        // Den expliziten Endknoten der Range zuweisen
        const endNode = range.endContainer;

        // Das Element des Startknotens zuweisen
        const startElement = startNode?.nodeType === Node.ELEMENT_NODE ? startNode as Element : startNode?.parentElement;
        // Das Element des Endknotens zuweisen
        const endElement = endNode?.nodeType === Node.ELEMENT_NODE ? endNode as Element : endNode?.parentElement;

        // Die mögliche erste Makrofalle zuweisen
        const possibleTrapA = startNode?.nodeType === Node.ELEMENT_NODE ? (startNode as Element | null) ?? null : startNode?.parentElement ?? null;
        // Die mögliche zweite Makrofalle zuweisen
        const possibleTrapB = endNode?.nodeType === Node.ELEMENT_NODE ? (endNode as Element | null) ?? null : endNode?.parentElement ?? null;

        // Das Makro zuweisen, in dem sich der Startknoten befindet
        const startMacro = startElement?.closest(".macro") ?? null;
        // Das Makro zuweisen, in dem sich der Endknoten befindet
        const endMacro = endElement?.closest(".macro") ?? startMacro;

        // Die Positionen des Cursors in der linken und rechten Makrofalle des Startmakros zuweisen
        const startMacroTrapPosition = this.getCaretPositionInTrap(startMacro, possibleTrapA, possibleTrapB, range);
        // Die Positionen des Cursors in der linken und rechten Makrofalle des Endmakros zuweisen
        const endMacroTrapPosition = this.getCaretPositionInTrap(endMacro, possibleTrapA, possibleTrapB, range);

        // Das Label des Makros zuweisen, in dem sich der Startknoten befindet
        const startLabel = startMacro?.querySelector(".label") ?? null;
        // Das Label des Makros zuweisen, in dem sich der Endknoten befindet
        const endLabel = endMacro?.querySelector(".label") ?? null;

        // Prüfen, ob sich die Range im Label des Startmakros befindet
        const rangeInStartLabel = startLabel?.contains(range.startContainer) ?? startLabel?.contains(range.endContainer) ?? false;
        // Prüfen, ob sich die Range im Label des Endmakros befindet
        const rangeInEndLabel = endLabel?.contains(range.startContainer) ?? endLabel?.contains(range.endContainer) ?? false;

        return {
            start: {
                leftTrap: startMacro?.firstChild ?? null,
                rightTrap: startMacro?.lastChild ?? null,
                label: startLabel,
                rangeInLabel: rangeInStartLabel,
                startOfMacro: startMacroTrapPosition.caretInLeftTrap,
                endOfMacro: startMacroTrapPosition.caretInRightTrap,
                macro: startMacroTrapPosition.caretInLeftTrap || startMacroTrapPosition.caretInRightTrap || rangeInStartLabel
                    ? startMacro as HTMLElement
                    : null
            },

            end: {
                leftTrap: endMacro?.firstChild ?? null,
                rightTrap: endMacro?.lastChild ?? null,
                label: endLabel,
                rangeInLabel: rangeInEndLabel,
                startOfMacro: endMacroTrapPosition.caretInLeftTrap,
                endOfMacro: endMacroTrapPosition.caretInRightTrap,
                macro: endMacroTrapPosition.caretInLeftTrap || endMacroTrapPosition.caretInRightTrap || rangeInEndLabel
                    ? endMacro as HTMLElement
                    : null
            }
        };
    };
}
