import Toolkit from "@/utils/Toolkit";

/**
 * Ein Typ zur Repräsentation von DOM-Markern.
 */
export type Markers = {
    markerStart: HTMLSpanElement;
    markerEnd: HTMLSpanElement;
}

/**
 * Hilfsfunktionen für DOM-Marker bei Text-Operationen
 */
export default class MarkerUtils {
    /**
     * Erstellt und platziert Marker an den Grenzen einer Range
     *
     * @param range Die Range, für die Marker erstellt werden sollen
     */
    private static createAndPlaceMarkers(range: Range): Markers {
        const domTool = Toolkit.tool("dom");
        const macroTool = Toolkit.tool("macro");

        // Marker für Start und Ende erstellen
        const markerStart = document.createElement("span");
        markerStart.textContent = '\u200B';

        const markerEnd = document.createElement("span");
        markerEnd.textContent = '\u200B';

        // Originale Range-Position speichern
        const { startContainer, startOffset, endContainer, endOffset } = range;

        // Marker an Start und Ende platzieren
        this.placeMarkers(range, markerStart, markerEnd, startContainer, startOffset, endContainer, endOffset);

        // Präzise DOM-Nodes und Offsets bestimmen
        let { node: anchorNode, offset: anchorOffset } = domTool.getTargetAndOffset(range.startContainer, range.startOffset);
        let { node: focusNode, offset: focusOffset } = domTool.getTargetAndOffset(range.endContainer, range.endOffset);

        const adjustableRange = new Range();
        adjustableRange.setStart(anchorNode, anchorOffset);
        adjustableRange.setEnd(focusNode, focusOffset);

        macroTool.prepareRangeForManipulation(adjustableRange);

        return {
            markerStart,
            markerEnd
        };
    }

    /**
     * Platziert Marker an den Grenzen der Auswahl
     *
     * @param range Die Range
     * @param markerStart Der Startmarker
     * @param markerEnd Der Endmarker
     * @param startContainer Der Startknoten
     * @param startOffset Der Startoffset
     * @param endContainer Der Endknoten
     * @param endOffset Der Endoffset
     */
    private static placeMarkers(
        range: Range,
        markerStart: HTMLSpanElement,
        markerEnd: HTMLSpanElement,
        startContainer: Node,
        startOffset: number,
        endContainer: Node,
        endOffset: number
    ) {
        // Endmarker einfügen
        range.setStart(endContainer, endOffset);
        range.setEnd(endContainer, endOffset);
        range.insertNode(markerEnd);

        // Startmarker einfügen
        range.setStart(startContainer, startOffset);
        range.setEnd(startContainer, startOffset);
        range.insertNode(markerStart);

        // Range zwischen den Markern setzen
        range.setStartAfter(markerStart);
        range.setEndBefore(markerEnd);
    }

    /**
     * Bereitet Marker für eine Content-Removal-Operation vor
     */
    public static prepareContentRemoval(): Markers | null {
        const selection = getSelection();
        if (!selection || selection.rangeCount === 0) return null;

        const macroTool = Toolkit.tool("macro");
        const range = macroTool.prepareRangeForManipulation(selection);
        if (!range) return null;

        // Marker für Start und Ende erstellen und platzieren
        const markers = this.createAndPlaceMarkers(range);

        return markers;
    }
}
