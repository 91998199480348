import type { Markers } from "./MarkerUtils";
import type ListTool from "../../ListTool";
import type SelectionTool from "..";
import type TypeGuardTool from "../../TypeGuardTool";
import DomTool from "../../DomTool";

/**
 * Eine Klasse zum entfernen von Elementen aus dem DOM.
 */
export default class RemovalUtil {
    /**
     * C'Tor
     *
     * @param root Das Wurzelelement in dem die Löschoperationen stattfinden
     * @param domTool Ein Tool zur Bearbeitung des DOMs
     * @param selectionTool Ein Tool zur Bearbeitung von Selektionen
     * @param typeGuard Ein Tool zur Typprüfung
     */
    constructor(
        private readonly root: HTMLElement,
        private readonly domTool: DomTool,
        private readonly listTool: ListTool,
        private readonly selectionTool: SelectionTool,
        private readonly typeGuard: TypeGuardTool
    ) {}

    /**
     * Entfernt leere Listen/-Blockelelemente zwischen den Markern
     *
     * @param markers Die Marker, die den Start und das Ende markieren
     */
    public cleanupAfterDeletion(markers: Markers) {
        // 1. Listen bereinigen
        this.cleanupListsAfterDeletion(markers);

        // 2. Listenelemente mit Markern behandeln
        this.handleListItems(markers);

        // 3. Leere Knoten entfernen
        this.removeEmptyNodes(markers);

        // 4. Leere Blockelemente bis auf Listen und Listenelemente entfernen
        this.removeEmptyBlockElements(markers);

        // 5. Temporäre Markierungen entfernen
        this.removePreservationMarkersForListItems(markers);
    }

    /**
     * Bereinigt Listen nach dem Löschen von Inhalten
     *
     * @param markers Die Marker, die den Start und das Ende markieren
     */
    private cleanupListsAfterDeletion(markers: Markers) {
        // Leere, nicht markierte Listenelemente entfernen
        this.removeEmptyListItems(markers);

        // Leere Listen entfernen
        this.removeEmptyLists(markers);
    }

    /**
     * Löscht Inhalte zwischen zwei Markern
     *
     * @param markers Die Marker, die den Start und das Ende markieren
     */
    public deleteMarkedContent(markers: Markers) {
        const nodesBetween = this.getNodesBetweenMarkers(markers);

        nodesBetween.forEach(node => {
            // 1. Leere Listenelemente zum Erhalt markieren
            if (this.shouldPreserveListItem(node)) return (node as Element).classList.add("kmanager-preserve-listitem");

            // 2. Block-Elemente und Marker ignorieren
            if (this.shouldSkipNode(node, markers)) return;

            node.parentElement?.removeChild(node);
        });
    }

    /**
     * Liefert alle Knoten zwischen zwei Markern und die Marker selbst
     *
     * @param markers Die Marker, die den Start und das Ende markieren
     *
     * @returns Die Knoten zwischen den Markern
     */
    private getNodesBetweenMarkers(markers: Markers): Node[] {
        const { markerStart, markerEnd } = markers;

        return this.domTool.getNodesBetween({
            startNode: markerStart,
            endNode: markerEnd
        });
    }

    /**
     * Entfernt leere Listen und Listenelemente, die nicht erhalten bleiben sollen
     *
     * @param markers Die Marker, die den Start und das Ende markieren
     */
    private handleListItems(markers: Markers) {
        const { markerStart, markerEnd } = markers;

        let listItemOfStartNode = this.listTool.listItemOf(markerStart);
        let listOfStartNode = this.listTool.listOf(listItemOfStartNode);

        while (listOfStartNode && listItemOfStartNode && this.domTool.isEmptyListItem(listItemOfStartNode, true, markerStart)) {
            const removableListItem = listItemOfStartNode;
            const removableList = listOfStartNode;

            listItemOfStartNode = this.listTool.listItemOf(listOfStartNode);
            listOfStartNode = this.listTool.listOf(listItemOfStartNode);

            if (listItemOfStartNode?.classList.contains("kmanager-preserve-listitem")) continue;

            removableListItem.remove();

            if (this.domTool.isEmptyList(removableList, markerEnd))
                removableList.remove();
        }

        let listItemOfEndNode = this.listTool.listItemOf(markerEnd);
        let listOfEndNode = this.listTool.listOf(listItemOfEndNode);

        while (listOfEndNode && listItemOfEndNode && this.domTool.isEmptyListItem(listItemOfEndNode, true, markerEnd)) {
            const removableListItem = listItemOfEndNode;
            const removableList = listOfEndNode;

            listItemOfEndNode = this.listTool.listItemOf(listOfEndNode);
            listOfEndNode = this.listTool.listOf(listItemOfEndNode);

            if (listItemOfEndNode?.classList.contains("kmanager-preserve-listitem")) continue;

            if (removableListItem.contains(markerEnd)) {
                const { node, offset } = this.selectionTool.nextFocus(markerEnd, 0, this.root);
                if (!node) return;

                const range = new Range();

                if (node.nodeType === Node.TEXT_NODE || node.nodeType === Node.ELEMENT_NODE && node.childNodes.length > 0) {
                    range.setStart(node, offset);
                    range.setEnd(node, offset);
                } else {
                    range.setStartBefore(node);
                    range.setEndBefore(node);
                }

                range.insertNode(markerEnd);
            }

            removableListItem.remove();

            if (this.domTool.isEmptyList(removableList))
                removableList.remove();
        }
    }

    /**
     * Entfernt leere Blockelemente zwischen den Markern
     *
     * @param markers Die Marker, die den Start und das Ende markieren
     */
    private removeEmptyBlockElements(markers: Markers) {
        const nodesBetween = this.getNodesBetweenMarkers(markers);

        nodesBetween.forEach(node => {
            if (!this.domTool.isBlockElement(node)) return;
            if (["UL", "OL", "LI"].includes(node.nodeName)) return;

            const childNodes = this.domTool.getNodes(node).toArray();
            if (childNodes.length > 0) return;

            node.parentElement?.removeChild(node);
        });
    }

    /**
     * Entfernt leere Listenelemente zwischen den Markern, die nicht erhalten bleiben sollen
     *
     * @param markers Die Marker, die den Start und das Ende
     */
    private removeEmptyListItems(markers: Markers) {
        const nodesBetween = this.getNodesBetweenMarkers(markers);

        nodesBetween.forEach(node => {
            if (node.nodeName === "LI" && (node as Element).classList.contains("kmanager-preserve-listitem")) return;
            if (!this.domTool.isEmptyListItem(node as Element, true)) return;

            (node as Element).remove();
        });
    }

    /**
     * Entfernt leere Listen zwischen den Markern
     *
     * @param markers Die Marker, die den Start und das Ende markieren
     */
    private removeEmptyLists(markers: Markers) {
        const nodesBetween = this.getNodesBetweenMarkers(markers);

        nodesBetween.forEach(node => {
            if (!this.domTool.isEmptyList(node)) return;

            (node as Element).remove();
        });
    }

    /**
     * Entfernt alle leeren Knoten im ContendEditable-Element und replatziert ggfs. die Marker nach dem Entfernen
     *
     * @param markers Die Marker, die den Start und das Ende der Auswahl markieren
     */
    private removeEmptyNodes(markers: Markers) {
        if (!this.root.firstChild || !this.root.lastChild) return;

        const { markerStart, markerEnd } = markers;

        const nodesBeforeStartMarker = this.domTool.getNodesBefore({
            root: this.root,
            target: markerStart
        });

        /**
         * Prüft, ob ein Knoten leer ist oder eine Liste oder ein Listenelement ist
         *
         * @param node Der Knoten, der geprüft werden soll
         *
         * @returns Der Wert, der angibt, ob der Knoten leer ist oder eine Liste oder ein Listenelement ist
         */
        const isNotEmptyOrListOrItem = (node: Node) => {
            // Leere Listen und Listenelemente ausschließen (vom Benutzer so gewollt)
            if (["UL", "OL", "LI"].includes(node.nodeName)) return true;

            // Nicht leere Knoten ausschließen
            if (!this.domTool.isEmptyNode(node, [markerStart, markerEnd])) return true;

            return false;
        };

        /**
         * Prüft, ob ein Knoten ein Marker ist
         *
         * @param node Der Knoten, der geprüft werden soll
         *
         * @returns Der Wert, der angibt, ob der Knoten ein Marker ist
         */
        const isMarker = (node: Node) => this.typeGuard.isTag(node, "span") && [markerStart, markerEnd].includes(node);

        // Alle löschbaren Knoten vor dem Startmarker entfernen
        nodesBeforeStartMarker
            .forEach(node => {
                // Marker ausschließen
                if (isMarker(node)) return;

                // Nicht leere Knoten, leere Listen und Listenelemente ausschließen
                if (isNotEmptyOrListOrItem(node)) return;

                // Void-Elemente ausschließen
                if (this.domTool.isVoidElement(node)) return node.parentElement?.removeChild(node);

                if (node.contains(markers.markerStart))
                    node.parentElement?.insertBefore(markers.markerStart, node);

                if (node.contains(markers.markerEnd))
                    node.parentElement?.insertBefore(markers.markerEnd, node.nextSibling);


                node.parentElement?.removeChild(node);
        });

        const nodesBetween = this.getNodesBetweenMarkers(markers);

        // Alle löschbaren Knoten zwischen den Markern entfernen
        nodesBetween
            .forEach(node => {
                // Marker ausschließen
                if (isMarker(node)) return;

                // Nicht leere Knoten, leere Listen und Listenelemente ausschließen
                if (isNotEmptyOrListOrItem(node)) return;

                // Void-Elemente ausschließen
                if (this.domTool.isVoidElement(node)) return node.parentElement?.removeChild(node);

                if (node.contains(markers.markerEnd))
                    node.parentElement?.insertBefore(markers.markerEnd, node.nextSibling);

                node.parentElement?.removeChild(node);
            });

        const nodesAfterEndMarker = this.domTool.getNodesAfter({
            root: this.root,
            source: markerEnd
        });

        nodesAfterEndMarker
            .forEach(node => {
                if (node === markerEnd) return;

                // Nicht leere Knoten, leere Listen und Listenelemente ausschließen
                if (isNotEmptyOrListOrItem(node)) return;

                // Void-Elemente ausschließen
                if (DomTool.VoidElements.has(node.nodeName)) return;


                if (node.contains(markers.markerEnd))
                    node.parentElement?.insertBefore(markers.markerEnd, node.nextSibling);

                node.parentElement?.removeChild(node);
            });
    }

    /**
     * Css-Klassenbezeichner entfernen, die ein Listenelement als erhaltenswert markieren
     *
     * @param markers Die Marker, die den Start und das Ende markieren
     */
    private removePreservationMarkersForListItems(markers: Markers) {
        const nodesBetween = this.getNodesBetweenMarkers(markers);

        nodesBetween
            .filter(node => node.nodeName === "LI" && (node as Element).classList.contains("kmanager-preserve-listitem"))
            .forEach(node => (node as Element).classList.remove("kmanager-preserve-listitem"));
    }

    /**
     * Prüft, ob ein Listenelement erhalten bleiben soll
     *
     * @param node Der Knoten, der geprüft werden soll
     */
    private shouldPreserveListItem(node: Node): boolean {
        return this.typeGuard.isTag(node, "li") && this.domTool.isEmptyListItem(node as Element, true);
    }

    /**
     * Prüft, ob ein Knoten beim Löschen übersprungen werden soll
     *
     * @param node Der Knoten, der geprüft werden soll
     * @param markers Die Marker, die den Start und das Ende markieren
     */
    private shouldSkipNode(node: Node, markers: Markers): boolean {
        const { markerStart, markerEnd } = markers;

        if (DomTool.BlockElements.has(node.nodeName)) return true;
        if (node instanceof HTMLSpanElement && [markerStart, markerEnd].includes(node)) return true;
        if (node.contains(markerStart)) return true;
        if (this.typeGuard.isTag(node, "span") && [markerStart, markerEnd].includes(node)) return true;
        if ([markerStart, markerEnd].some(marker => node.contains(marker))) return true;
        if ([markerStart, markerEnd].some(marker => marker.contains(node))) return true;

        return false;
    }
}
