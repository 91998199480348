import { nextTick } from "vue";
import Logger from "@/utils/Logger";

/**
 * Polyfill für element.matches (IE)
 */
if (!Element.prototype.matches)
    // @ts-ignore
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;

/**
 * Wartet, bis in einem {@linkcode node} keine Änderungen innherhalb des Zeitintervals {@linkcode tti} mehr stattfinden.
 *
 * @param node Der {@linkcode Node}, der überwacht werden soll.
 * @param tti Das Zeitinterval, in dem keine Änderungen mehr stattfinden dürfen.
 *
 * @returns Der Wert, ob keine Änderungen mehr stattgefunden haben.
 */
export function nodeRendered(node: Node, tti: number = 50) {
    return new Promise<boolean>(resolve => {
        let timeout = setTimeout(() => resolve(true), tti);

        const observer = new MutationObserver(async (mutations) => {
            clearTimeout(timeout);

            timeout = setTimeout(() => resolve(true), tti);
        });

        observer.observe(node, {
            childList: true,
            subtree: true,
            characterData: true
        });
    });
}

/**
 * Wartet {@linkcode msec} Millisekunden, bis ein Element im Dom existiert
 *
 * @param options Die Optionen
 * @param options.root Das Wurzelelement, das das gesuchte Element beinhalten soll.
 * @param options.selector Der Node-Selektor
 * @param options.ttl Die Zeit in Millisekunden, die maximal gewartet werden soll. Bei 0 unendlich.
 *
 * @returns Der Wert, ob das Element im Dom existiert.
 */
export function nodeReady({ root, selector, ttl = 3000 }: { root?: HTMLElement, selector: string, ttl?: number }): Promise<boolean> {
    root = root || document.body;

    return new Promise((resolve) => {
        if (root!.querySelector(selector))
            return resolve(true);

        const timObserve = ttl > 0
            ? setTimeout(() => {
                observer.disconnect();

                resolve(false);
            }, ttl)
            : null;

        const observer = new MutationObserver(() => {
            if (!root!.querySelector(selector))
                return;

            if (timObserve)
                clearTimeout(timObserve);

            observer.disconnect();

            resolve(true);
        });

        observer.observe(root!, {
            childList: true,
            subtree: true,
        });
    });
};

/**
 * Wartet, bis Vue den Dom aktualisiert hat.
 *
 * @returns Das Versprechen, dass Vue den Dom aktualisiert hat.
 */
export const vueDomUpdated = async () =>
    new Promise<void>(resolve => nextTick(() => resolve()));

/**
 * Wartet, bis ein {@link Node} im Dom entfernt wurde.
 * Falls nach {@linkcode} Millisekunden der {@link Node} nicht entfernt wurde wird die Promise mit {@linkcode false}
 * abgebrochen.
 *
 * @param node Der {@link Node}, der entfernt werden soll.
 * @param ttl Die Zeit in Millisekunden, die maximal gewartet werden soll. 0 bedeutet unendlich.
 */
export function nodeRemoved(node: Node, ttl: number = 2000): Promise<boolean> {
    return new Promise((resolve) => {
        const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                const removed = Array.from(mutation.removedNodes ?? []).some(m => m === node || m.contains(node));

                if (!removed)
                    continue;

                observer.disconnect();
                resolve(true);
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
            characterData: true
        });

        if (ttl > 0)
            setTimeout(() => {
                observer.disconnect();
                resolve(false);
            }, ttl);
    });
}

/**
 * Ruft eine Methode mit try/catch-Block auf.
 *
 * @param {(params:any, inx: number, reverseInx:number) => any} fn Die aufzurufende Methode.
 * @param {any} params An die aufrufende Funktion zu übergebene Parameter.
 * @param {number|null} inx An die aufrufende Funktion zu übergebeneden Index.
 * @param {number} reversedInx An die aufrufende Funktion zu übergebeneden invertierten Index.
 *
 * @returns {Any} Ein beliebiger Rückgabewert.
 */
const callFn = (fn: (params: any, inx: number | null, reverseInx?: number) => any, params?: any, inx?: number | null, reversedInx?: number): any => {
    var result = true;

    params = params || null;
    inx = inx !== undefined && inx !== null && !isNaN(inx) ? inx : null;

    try {
        result = fn(params, inx, reversedInx);
    } catch (e) {
        Logger.error("Fehler in der Methode: ", e);
    }

    return result;
}

/**
 * Stellt {@linkcode HTMLElement} Hilfsmethoden zur Abfrage und Manipulation
 * zur Verfügung.
 *
 * @param {HTMLElement[]?|HTMLElement?|string?} elements
 */
const $ = (elements?: HTMLElement[] | HTMLElement | string | null) => {
    if (typeof elements === "string") {
        try {
            document.querySelectorAll(elements);
        } catch (e) {
            let container = document.createElement("div");

            container.innerHTML = elements;
            elements = container.childNodes[0] as HTMLElement;
        }
    }

    let $elements: HTMLElement[] | null;

    if (elements && typeof elements === "string")
        $elements = Array.from(document.querySelectorAll(elements));
    else if (elements && typeof elements !== "string" && !Array.isArray(elements))
        $elements = [elements];
    else if (elements && typeof elements !== "string" && Array.isArray(elements))
        $elements = elements;
    else
        $elements = [];

    return {
        /**
         * Fügt einem {@linkcode HTMLElement} eine CSS-Klasse hinzu.
         *
         * @param {string} className
         */
        addClass(className: string) {
            if (!$elements)
                return this;

            if (!$elements.length)
                return this;

            Array
                .from($elements)
                .forEach(element => {
                    if ($(element as HTMLElement).hasClass(className))
                        return;

                    element.classList.add(className);
                });

            return this;
        },

        /**
         * Liefert oder setzt eine CSS-Variable.
         *
         * @param {string} name
         * @param {string|number|null} value
         *
         * @returns {string|number}
         */
        cssVar(name: string, value: string | number | null = null): string | void {
            return !value
                ? getComputedStyle(document.documentElement).getPropertyValue(`--${name}`)
                : document.documentElement.style.setProperty(`--${name}`, `${value}`);
        },

        /**
         * Ein Iterator für die Dom-Elemente.
         *
         * @param {Function} fn Die pro Dom-Element aufzurufende Funktion.
         */
        each(fn: (params: any, inx: number | null, reverseInx?: number) => any): void {
            if (!$elements)
                return;

            const len = $elements.length;

            for (let ii = 0; ii < len; ii++) {
                const result = callFn(fn, $elements[ii], ii, (len - ii - 1));

                if (result === false)
                    break;
            }
        },

        /**
         * Erzeugt ein Dom-Hilfsmittel zur einfachen Manipulation sowie
         * Abfrage von Dom-Elementeigenschaften.
         *
         * @param {HTMLElement|string} elements Der Dom-Selektor oder das Html-Element.
        */
        find(elements: keyof HTMLElementTagNameMap | string) {
            let container: NodeListOf<Element>;
            let foundElements: HTMLElement[] = [];

            if (!$elements)
                return $();

            const eLen = $elements.length;

            for (var ii = 0; ii < eLen; ii++) {
                container = $elements[ii].querySelectorAll(elements);

                for (var jj = 0; jj < container.length; jj++) {
                    foundElements.push(container[jj] as HTMLElement);
                }
            }

            return $(foundElements);
        },

        /**
         * Gibt ein/alle Dom-Element(e) zurück.
         *
         * @param {number|null|undefined} index Das n-te Element oder alle Elemente.
         *
         * @returns {HTMLElement[]|HTMLElement}
        */
        get(index?: number | null): NodeListOf<HTMLElement> | HTMLElement[] | HTMLElement | undefined {
            if (!$elements)
                return;

            if (index === null)
                return $elements;

            if (index === undefined)
                return $elements;

            return $elements[index];
        },

        /**
         * Durchsucht ein Dom-Element nach einem Css-Klassennamen.
         *
         * @param {string} className Der Css-Klassennamen.
         *
         * @returns {boolean} Der Wert, ob die Css-Klasse im Dom-Element gefunden wurde.
         */
        hasClass(className: string) {
            if (!$elements)
                return false;

            if ($elements.length === 0)
                return false;

            for (let ii = 0; ii < $elements.length; ii++)
                if ($elements[ii].classList)
                    return $elements[ii].classList.contains(className);
                else
                    return new RegExp("(^| )" + className + "( |$)", "gi").test($elements[ii].className);
        },

        /**
         * Liefert die Position (index) des Html-Elements.
         *
         * @returns {number} Die Position im Dom oder keine Rückgabe.
         *                   Wenn der Wert "-1" zurückgegeben wurde,
         *                   dann wurde die Position nicht gefunden.
         */
        index(): number {
            if (!$elements)
                return -1;

            let $nodes = [];

            if ($elements.length === 1 && $elements[0].parentNode) {
                let $childNodes = $elements[0].parentNode.childNodes;
                let cLen = $childNodes.length;

                for (let ii = 0; ii < cLen; ii++) {
                    if ($childNodes[ii].nodeType === Node.ELEMENT_NODE)
                        $nodes.push($childNodes[ii]);
                }
            }

            return Array.prototype.indexOf.call($nodes, $elements[0]);
        },

        /**
         * Eine Methode, die nach dem Laden der Seite eine Methode aufruft.
         *
         * @param {(params: any, inx: number | null, reverseInx?: number) => any} fn
         */
        ready(fn: (params: any, inx: number | null, reverseInx?: number) => any): void {
            if (document.readyState !== "loading")
                return callFn(fn);

            document.addEventListener("DOMContentLoaded", () => callFn(fn));
        },

        /**
         * Entfernt aus einem Dom-Element eine Css-Klasse.
         *
         * @param {string} className Die Css-Klasse.
         */
        removeClass(className: string) {
            if (!$elements)
                return this;

            if ($elements.length === 0)
                return this;

            Array
                .from($elements || [])
                .filter($element => $element && ($element.classList || $element.className))
                .forEach($element => {
                    if ($element.classList)
                        return $element.classList.remove(className);

                    $element.className = $element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
                });

            return this;
        },

        /**
         * Alterniert den Css-Klassenamen eines Html-Elements.
         *
         * @param {string} className Der zu alternierende Css-Klassenname.
         */
        toggleClass(className: string) {
            if (!$elements)
                return;

            $elements.forEach($element => {
                if ($($element).hasClass(className))
                    $($element).removeClass(className);
                else
                    $($element).addClass(className);
            });
        },

        /**
         * Liefert die nächstem Geschwisterelemente
         *
         * @param {string} selector
         */
        next(selector?: string) {
            if (!$elements)
                return $();

            let siblings = [];
            let nextSibling = null;

            for (let ii = 0; ii < $elements.length; ii++) {
                if (selector) {
                    nextSibling = $elements[ii] as HTMLElement;

                    while ((nextSibling = nextSibling.nextSibling as HTMLElement)) {
                        if (nextSibling.matches && nextSibling.matches(selector)) {
                            siblings.push(nextSibling as HTMLElement);

                            break;
                        }
                    }
                } else {
                    nextSibling = $elements[ii].nextSibling
                        ? $elements[ii].nextSibling
                        : false;

                    if (nextSibling)
                        siblings.push(nextSibling as HTMLElement);
                }
            }

            return $(siblings);
        },

        /**
         * Liefert die vorherigen Geschwisterelemente
         *
         * @param {string} selector
         */
        prev(selector?: string) {
            if (!$elements)
                return $();

            let siblings = [];
            let prevSibling = null;

            for (let ii = 0; ii < $elements.length; ii++) {
                if (selector) {
                    prevSibling = $elements[ii] as HTMLElement;

                    while ((prevSibling = prevSibling.previousSibling as HTMLElement)) {
                        if (prevSibling.matches && prevSibling.matches(selector)) {
                            siblings.push(prevSibling as HTMLElement);

                            break;
                        }
                    }
                } else {
                    prevSibling = $elements[ii].previousSibling
                        ? $elements[ii].previousSibling
                        : false;

                    if (prevSibling)
                        siblings.push(prevSibling as HTMLElement);
                }
            }

            return $(siblings);
        },

        /**
         * Gibt ein Dom-Html-Objekt zurück.
         *
         * @param {HTMLElement|string|null} element
         */
        parent(element?: HTMLElement | string | null) {
            if (!$elements)
                return $();

            if (!element)
                return $($elements[0].parentNode as HTMLElement);

            let $searchElements = $(element).get();

            if (!$searchElements)
                return $();

            if (($searchElements instanceof HTMLElement))
                return $();

            let sLen = $searchElements.length;

            if (sLen > 0 && $elements.length > 0) {
                let $parent = $elements[0] as HTMLElement;

                while (($parent = $parent.parentNode as HTMLElement)) {
                    for (let ii = 0; ii < sLen; ii++) {
                        if ($parent === $searchElements[ii])
                            return $($parent);
                    }
                }
            }

            return $();
        },

        /**
         * Kehrt die Reihenfolge des HTML-Arrays um.
         */
        reverse() {
            if (!$elements)
                return this;

            if ($elements instanceof HTMLElement)
                return this;

            $elements.reverse();
        },

        /**
         * Liefert das erste Element in der HTMLCollection.
         */
        first() {
            if (!$elements)
                return $(null);

            return $($elements[0]);
        },

        /**
         * Liefert das letzte Element in der HTMLCollection.
         */
        last() {
            if (!$elements)
                return $(null);

            return $($elements[$elements.length - 1]);
        },

        /**
         * Fügt an das Ende eines Dom-Elements aus der Dom-Collection einen
         * beliebigen Html-Inhalt ein.
         *
         * @param {HTMLElement} child Der einzufügende Html-Inhalt.
         */
        append(child: HTMLElement) {
            if (!$elements)
                return;

            $elements.forEach($element => $element.appendChild(child))
        },

        /**
         * Fügt an den Anfang eines Dom-Elements aus der Dom-Collection einen
         * beliebigen Html-Inhalt ein.
         *
         * @param {HTMLElement} child Der einzufügende Html-Inhalt.
         */
        prepend(child: HTMLElement) {
            if (!$elements)
                return;

            $elements.forEach($element => $element.insertBefore(child, $element.firstChild));
        },

        /**
         * Fügt ein HTML-Element hinter ein weiteres HTML-Element ein.
         *
         * @param {string} childString Der einzufügende Html-Inhalt als Zeichenkette.
         */
        after: (childString: string) => {
            if (!$elements)
                return;

            $elements.forEach($element => $element.insertAdjacentHTML("afterend", childString));
        },

        /**
         * Fügt ein HTML-Element vor ein weiteres HTML-Element ein.
         *
         * @param {string} childString Der einzufügende Html-Inhalt als Zeichenkette.
         */
        before(childString: string) {
            if (!$elements)
                return;

            $elements.forEach($element => $element.insertAdjacentHTML("beforebegin", childString));
        },

        /**
         * Entfernt das Html-Element (bzw. Html-Collection) aus dem Dom.
         */
        remove() {
            if (!$elements)
                return;

            $elements.forEach($element => ($element as HTMLElement).parentNode?.removeChild($element));
        },

        /**
         * Schreibt oder gibt den HTML-Inhalt eines Html-Element.
         *
         * @param {string?|null} content Der zu schreibende Inhalt.
         *
         * @returns {string} Den Html-Inhalt oder Leerstring.
         */
        html(content?: string): string {
            if (!$elements)
                return "";

            if (!content || typeof content !== "string")
                return $elements[0].innerHTML;

            $elements.forEach($element => $element.innerHTML = content);

            return "";
        },

        /**
         * Schreibt oder gibt den Text-Inhalt eines Html-Element.
         *
         * @param {string?|null} content Der zu schreibende Inhalt.
         *
         * @returns {string} Den Text-Inhalt oder Leerstring.
         */
        text(content?: string | null): string {
            if (!$elements)
                return "";

            if (!content || typeof content !== "string")
                return $elements[0].innerText;

            $elements.forEach($element => $element.innerText = content);

            return "";
        },

        /**
         * Setzt/Liest den Wert der Html-Element-Eigenschaft "value".
         *
         * @param {string?} content Der zu setzende Wert.
         *
         * @returns {string[]} Die Werte.
         */
        val(content?: string): string[] {
            let result = content ? [content] : [];

            if (!$elements)
                return result;

            const isContent = content !== undefined && content !== null;

            if (isContent)
                $elements.forEach($element => ($element as HTMLInputElement).value = content);
            else
                $elements.forEach($element => result.push(($element as HTMLInputElement).value ? ($element as HTMLInputElement).value : ""));

            return result;
        },

        /**
         * Ersetzt den Html-Inhalt.
         *
         * @param {string} content Der zu ersetzende Html-Inhalt.
         */
        replace(content: string) {
            if (!$elements)
                return this;

            $elements.forEach($element => $element.outerHTML = content);

            return this;
        },

        /**
         * Umschließt einen HTML-Inhalt im Element mit dem übergebenen HTML-Element.
         *
         * @param {string} text Der zu umschließende Text im HTMLObject.
         * @param {HTMLElement} $wrapper Der anzuwendende Wrapper.
         */
        wrap(text: string, $wrapper: { get: (idx: number) => HTMLElement }) {
            if (!$elements)
                return this;

            $wrapper.get(0).innerHTML = text;

            const outerHtml = $wrapper.get(0).outerHTML;

            $elements.forEach($element => $element.innerHTML = $element.innerHTML.replace(text, outerHtml));
        },

        /**
         * Ersetzt einen Wrapper mit dessen Wrapper-Inhalt.
         *
         * @param {{each: (callback: (wrapper: HTMLElement) => void) => void}} $wrappers Die zu ersetzenden Wrapper.
         */
        unwrap($wrappers: { each: (callback: (wrapper: HTMLElement) => void) => void }) {
            $wrappers.each(wrapper => {
                const innerHTML = wrapper.innerHTML;

                wrapper.outerHTML = innerHTML;
            });
        },

        /**
         * Gibt den Wert eines data-attributs eines Html-Elements zurpck.
         *
         * @param {string} dataname Die Data-Bezeichnung
         *
         * @returns {Any} Der Data-Wert.
         */
        data(dataname: string) {
            if (!$elements)
                return null;

            return $elements.at(0)?.getAttribute("data-" + dataname) ?? null;
        },

        /**
         * Filtert Elemente per Css-Selektor aus einer HTMLCollection.
         *
         * @param {string} selector Der Css-Selektor.
         */
        filter(selector: string) {
            if (!$elements)
                return $([]);

            return $elements.filter($element => $element.matches(selector));
        },

        /**
         * Klont ein Element.
         *
         * @param {boolean} deep Ein Wert, der angibt, ob auch Kindelement mitgeklont werden sollen.
         *
         * @returns {HTMLElement} Das geklonte Element.
         */
        clone(deep: boolean = true) {
            let clones: HTMLElement[] = [];

            if (!$elements)
                return $([]);

            $elements.forEach($element => clones.push($element.cloneNode(deep) as HTMLElement));

            return $(clones);
        },

        /**
         * Gibt zurück, ob es sich bei dem Element (oder dem ersten Element der Collection) um ein HTMLElement handelt.
         *
         * @returns {boolean} Der Wert, ob es sich um ein HTMLElement handelt.
         */
        isHTMLElement(): boolean {
            if (!$elements)
                return false;

            let $check = $elements.length > 0 ? $elements.at(0) : $elements;

            return $check instanceof HTMLElement;
        },

        /**
         * Liefert die Index-Position des {@linkcode element} in einem {@linkcode $elements}-Container
         *
         * @param {HTMLElement} element
         */
        indexOf(element: HTMLElement): number {
            if (!$elements)
                return -1;

            if ($elements.length !== 1)
                return -1;

            if (!($elements.at(0) instanceof HTMLElement))
                return -1;

            return Array.from(($elements.at(0) as HTMLElement).childNodes).indexOf(element);
        },

        /**
         * Wartet {@linkcode msec} Millisekunden, ob ein Element im Dom existiert
         * und fährt dann fort.
         *
         * @param {number?} msec
         * @param {string?} selector
         *
         * @returns {boolean} Der Wert, ob das Element im Dom existiert.
         */
        async mounted(msec?: number, selector?: string) {
            if (!elements)
                return;

            if (!msec || isNaN(msec))
                msec = 2000;

            return new Promise((resolve, reject) => {
                let elapsed = 0;
                let intervalId = setInterval(() => {
                    elapsed += 10;

                    if (elapsed > msec!) {
                        clearInterval(intervalId);

                        reject();
                    } else if (selector && (elements as HTMLElement)!.querySelectorAll(selector).length) {
                        clearInterval(intervalId);

                        resolve(true);
                    } else if (!selector && document.querySelectorAll((elements as string)!).length) {
                        clearInterval(intervalId);

                        resolve(true);
                    }
                }, 10);
            });
        },

        /**
         * Die Anzahl der Elemente.
         *
         * @var {Number} [length=0]
         */
        length: $elements.length || 0
    };
};

export default $;
