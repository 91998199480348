import { IChartApi } from "lightweight-charts";
import Toolkit from "@/utils/Toolkit";

/**
 * Ein Tool zur Typüberprüfung.
 */
const typeGuard = Toolkit.tool("typeGuard");

type TActionFactoryParameters<T extends TActionName> = {
    /**
     * Die auszuführende Aktion
     */
    action: TAction<T>

    /**
     * Die aktive Chart-Instanz
     */
    chart?: Highcharts.Chart | IChartApi

    /**
     * Die Spalten der ReportView
     */
    columns: ReportColumn[]

    /**
     * Die Komponente, die den Ereignisbehandler verwendet
     */
    component?: Record<string, unknown>

    /**
     * Der Anzeigemodus der ReportView.
     */
    displayMode: TDisplayMode

    /**
     * Das Ereignis, das die Aktion ausgelöst hat
     */
    eventName: TEventName

    /**
     * Die Hooks, die von Aktionen verwendet werden können
     */
    hooks: TActionHooks

    /**
     * Die zuletzt ausgeführte Aktion
     */
    lastAction: TAction<TActionName>

    /**
     * Das Ergebnis der zuletzt ausgeführten Aktion
     */
    lastResult: unknown

    /**
     * Das Layout der ReportView
     */
    layout: any

    /**
     * Gibt an, ob die Aktionen protokolliert werden sollen
     */
    log: boolean

    /**
     * Der Store der DataView
     */
    store: KeyValuePair<unknown>

    /**
     * Die Plandaten der ReportView
     */
    plan: TReport

    /**
     * Die Plugins die von Aktionen verwendet werden können
     */
    plugins: TPlugins

    /**
     * Die Zeilen der ReportView
     */
    rows: Record<string, unknown>[]

    /**
     * Das Steuerelement, das die Aktion ausgelöst hat
     */
    trigger: TControl

    /**
     * Die Darstellungsart der ReportView.
     */
    visualizationMode: TVisualizationMode

    /**
     * Eine Funktion, die einen Mitgliedsnamen als Parameter erhält und einen unbekannten Wert zur Prüfung der Bedingungen zurückgibt
     */
    conditionValueResolver: TConditionValueResolver
}

/**
 * Eine Klasse zur Erzeugung von Aktionen.
 */
class ActionFactory {
    /**
     * Versucht die Aktion zu importieren und auszuführen.
     *
     * @param options.action Die auszuführende Aktion
     * @param options.chart Die aktive Chart-Instanz
     * @param options.conditionValueResolver Eine Funktion, die einen Mitgliedsnamen als Parameter erhält und einen unbekannten Wert zur Prüfung der Bedingungen zurückgibt
     * @param options.eventName Der Name des Ereignisses, das die Aktion ausgelöst hat
     * @param options.hooks Die Hooks, die von Aktionen verwendet werden können
     * @param options.lastAction Die zuletzt ausgeführte Aktion
     * @param options.lastResult Das Ergebnis der zuletzt ausgeführten Aktion
     * @param options.layout Das Layout der ReportView
     * @param options.log Gibt an, ob die Aktionen protokolliert werden sollen
     * @param options.plugins Die Plugins die von Aktionen verwendet werden können
     * @param options.rows Die Zeilen der ReportView
     * @param options.store Der Store der DataView
     * @param options.trigger Das Steuerelement, das die Aktion ausgelöst hat
     *
     * @returns Das Ergebnis der Aktion
     */
    async execute<T extends TActionName>(options: TActionFactoryParameters<T>): Promise<unknown> {
        const actionName = `${options.action.$type.slice(0, 1).toUpperCase()}${options.action.$type.slice(1)}`;
        const ImportedObject = (await import(`./Actions/${actionName}.ts`)).default;
        const actionInstance = new ImportedObject(options);

        return typeGuard.isPromiseMethod(actionInstance, "execute")
            ? await actionInstance.execute()
            : actionInstance.execute();
    }
}

export default ActionFactory;
