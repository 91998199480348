import Toolkit from "..";
import ToolkitTool from "../ToolkitTool";

/**
 * Ein Typ zur Beschreibung der relativen horizontalen Mausposition zu einem {@link Node}
 */
type RelativeMousePosition = "LEFT_FROM_MOUSE" | "RIGHT_FROM_MOUSE" | null;

/**
 * Ein Typ zur Beschreibung der Mausposition
 */
type MousePosition = { x: number, y: number };

/**
 * Eine Klasse mit Hilfsfunktionen für Maus-Operationen
 */
export default class MouseTool extends ToolkitTool {
    /**
     * Die Instanz des Maus-Tools.
     */
    private static Instance: MouseTool;

    /**
     * C'tor.
     */
    private constructor() {
        super();
    }

    /**
     * Erzeugt eine Instanz des Maus-Tools, falls noch keine existiert
     *
     * @returns Die Instanz des Maus-Tools
     */
    public static getInstance() {
        if (!MouseTool.Instance)
            MouseTool.Instance = new MouseTool();

        return MouseTool.Instance;
    }
    /**
     * Liefert die Position des Mauszeigers in einem Event
     *
     * @param event Das Event, das die Mausposition enthält
     *
     * @returns Die Position des Mauszeigers
     */
    public getMousePosition(event: MouseEvent): MousePosition {
        return { x: event.clientX, y: event.clientY };
    }

    /**
     * Liefert die relative Position des Mauszeigers zu einem Element
     *
     * @param mouseXOrEvent Die X-Position des Mauszeigers oder das Event, das die Mausposition enthält
     * @param node Der Knoten, zu dem die relative Position ermittelt werden soll
     *
     * @returns Die relative Position des Mauszeigers zum Knoten
     */
    public relativeMousePosition (mouseXOrEvent: number | MouseEvent, node: Node): RelativeMousePosition {
        const domTool = Toolkit.tool("dom");

        const rect = domTool.nodeBounds(node);
        if (!domTool.isValidDomRect(rect)) return null;

        const mouseX = typeof mouseXOrEvent === "number" ? mouseXOrEvent : this.getMousePosition(mouseXOrEvent).x;

        const left = rect.left;
        const right = rect.left + rect.width;

        if (mouseX < left) return "LEFT_FROM_MOUSE";
        if (mouseX > right) return "RIGHT_FROM_MOUSE";

        return null;
    };
}
