import { isIOS } from "@/utils/browser";
import $ from "@/utils/dom";
import attachNodes from "./utils/private.attachNodes";
import attachEvents from "./utils/private.attachEvents";
import debounce from "lodash/debounce";
import detachEvents from "./utils/private.detachEvents";
import detachNodes from "./utils/private.detachNodes";
import dragThumbOnMove from "./utils/handler/dragThumbOnMove";
import handleOnBottom from "./utils/handler/handleOnBottom";
import handleScroll from "./utils/handler/handleScroll";
import horizontalMouseDown from "./utils/handler/horizontalMouseDown";
import registerOnBottom from "./utils/events/onBottom";
import ScrollerResizeWatcher from "./utils/handler/watchResize";
import scrollPage from "./utils/handler/scrollPage";
import setupBrowser from "./utils/private.setupBrowser";
import smoothscroll from "smoothscroll-polyfill";
import throttle from "lodash/throttle";
import unsetDragState from "./utils/handler/unsetDragState";
import updateThumbSizes from "./utils/handler/updateThumbSizes";
import verticalMouseDown from "./utils/handler/verticalMouseDown";

import "./style.scss";

smoothscroll.polyfill();

/**
 * Eine Klasse zur Bereitstellung eines proprietären Skrollbalkens
 */
class Scroller {
    /**
     * Das zu wrappende {@linkcode HTMLElement}
     *
     * @type {HTMLElement}
     */
    element;

    /**
     * Gibt an, ob {@linkcode isDisabled} ignoriert werden soll
     */
    ignoreDisabled = false;

    /**
     * Gibt an, ob das Skrollverhalten pausiert ist
     */
    paused = false;

    /**
     * Liefert den Wert, ob ein Eingabefeld das Scrollverhalten deaktiviert
     */
    get isDisabled() {
        if (this.paused)
            return true;

        const focusNode = document.activeElement;

        if (!this.element.contains(focusNode))
            return false;

        return ["INPUT", "TEXTAREA", "SELECT"].includes(focusNode.nodeName)
            || focusNode.getAttribute("contenteditable") === "true"
            || focusNode.closest("[contenteditable='true']");
    }

    /**
     * Die dynamisch erzeugten Skrollbar-{@linkcode HTMLElement}e
     */
    elements = {
        /**
         * Der äußere {@linkcode HTMLElement}-Wrapper
         *
         * @type {HTMLElement}
         */
        scrollContainer: null,

        /**
         * Der {@linkcode HTMLElement}-Container der vertikalen und horizontalen proprietären Skrollbalken
         *
         * @type {HTMLElement}
         */
        barContainer: null,

        /**
         * Der proprietäre horizontale {@linkcode HTMLElement}-Skrollbalken
         *
         * @type {HTMLElement}
         */
        barHoz: null,

        /**
         * Der proprietäre vertikale {@linkcode HTMLElement}-Skrollbalken
         *
         * @type {HTMLElement}
         */
        barVert: null,

        /**
         * Der proprietäre horizontale {@linkcode HTMLElement}-Skrollthumb
         *
         * @type {HTMLElement}
         */
        thumbHoz: null,

        /**
         * Der proprietäre vertikale {@linkcode HTMLElement}-Skrollthumb
         *
         * @type {HTMLElement}
         */
        thumbVert: null
    };

    config = {
        onBottom: {
            isPercent: false,
            distance: 20,
            throttle: 80,
            lastPosition: 0,
            callback: () => {}
        },

        disableScrollOnInputFokus: false
    };

    /**
     * Stellt die Ereignismethoden der registrierten Ereignisse zur Verfügung
     * und dient dem entfernen nach Aufruf der Klassenmethode {@linkcode Scroller.destroy}
     */
    registeredHandler = {
        resize: {
            /**
             * @type {NodeJS.Timeout | undefined}
             */
            timer: undefined,
            destroyed: false
        },

        dragState: () => unsetDragState.call(this),
        thumbMove: e => dragThumbOnMove.call(this, e),
        verticalMouseDown: e => verticalMouseDown.call(this, e),
        horizontalMouseDown: e => horizontalMouseDown.call(this, e),

        iframeScrollReady: () => {
            this.element.contentDocument.removeEventListener("scroll", this.registeredHandler.scroll);
            this.element.contentDocument.addEventListener("scroll", this.registeredHandler.scroll);
        },

        iframeBottomReady: () => {
            this.element.contentDocument.removeEventListener("scroll", this.registeredHandler.onBottom.handler);
            this.element.contentDocument.addEventListener("scroll", this.registeredHandler.onBottom.handler);
        },

        onBottom: {
            handler: throttle(
                () => handleOnBottom.call(this),
                this.config.onBottom.throttle
            )
        },

        scroll: () => handleScroll.call(this),

        /**
         * @param {Event} e
         */
        wheel: (e) => {
            e.preventDefault();

            if (this.isDisabled)
                return;

            /**
             * @type {"up" | "down"}
             */
            const direction = e.deltaY > 0 ? "down" : "up";


            scrollPage.call(this, direction);
        },

        /**
         * speichert die Startzeit- und Position des Touch-Events
         *
         * @param {TouchEvent} e Das Touch-Event
         */
        touchstart: (e) => {
            this.scrollerState.vertical.touch.startTime = Date.now();
            this.scrollerState.vertical.touch.offsetY = e.touches[0].clientY;
            this.scrollerState.vertical.touch.direction = undefined;
        },

        /**
         * Scrollt die Seite in die Richtung des Touch-Events
         */
        touchmove: debounce(() => {
            if (this.isDisabled)
                return;

            if (this.scrollerState.vertical.touch.direction === undefined)
                return;

            this.scrollerState.vertical.touch.offsetY = 0;

            scrollPage.call(this, this.scrollerState.vertical.touch.direction);
        }, 150),

        /**
         * Ermittelt die Richtung des Touch-Events
         *
         * @param {Event} e Das Touch-Event
         */
        determineTouchDirection: (e) => {
            e.preventDefault();

            const diff = Math.abs(e.touches[0].clientY - this.scrollerState.vertical.touch.offsetY);

            if (diff < 25)
                return;

            this.scrollerState.vertical.touch.direction = e.touches[0].clientY > this.scrollerState.vertical.touch.offsetY ? "up" : "down";
            this.registeredHandler.touchmove();
        },

        /**
         * @param {Event} e
         */
        keydown: (e) => {
            if (!["ArrowUp", "ArrowDown", "PageUp", "PageDown", "Home", "End"].includes(e.key))
                return;

            if (this.isDisabled)
                return;

            e.preventDefault();

            if (["ArrowUp", "ArrowDown", "PageUp", "PageDown"].includes(e.key))
                return scrollPage.call(this, ["ArrowDown", "PageDown"].includes(e.key) ? "down" : "up");

            this.scrollerState.page = e.key === "End"
                ? this.scrollerState.pages - 1
                : 1

            scrollPage.call(this, e.key === "End" ? "down" : "up");
        },

        /**
         * @param {Event} e
         */
        mousedown: (e) => {
            if (e.button !== 1)
                return;

            e.preventDefault();
        }
    };

    /**
     * Der Skrollwrapper
     *
     * @type {HTMLElement}
     */
    scrollContainer;

    /**
     * Callback-Methoden
     */
    callbacks = {
        /** Callback-Funktion nach erfolgtem einhängen der {@linkcode HTMLElement}e der proprietären Skrollbar */
        mounted: () => { },

        /** Callback-Funktion nach Änderung der Skrollbarzustände */
        state: () => { },

        /** Callback-Funtion nach Änderung der Größe (bspw. Fenster verkleinern/-vergößern) */
        resize: () => { },

        /**
         * Callback-Funktion bei horizontalem scrollen
         *
         * @type {((scrollLeft: number) => void)[]}
         */
        onScrollHorizontal: [],

        /**
         * Callback-Funktion bei vertikalem scrollen
         *
         * @type {((scrollTop: number, page: number) => void)[]}
         */
        onScrollVertical: []
    };

    /**
     * Zusätzliche Skrollbalkenoptionen
     *
     * @type {{left: Number, top: Number, minSize: Number}}
     */
    bars = {
        /**
         * Der linke Versatz des horizontalen Skrollbalkens
         *
         * @type {Number}
         */
        left: 0,

        /**
         * Der obere Versatz des vertikalen Skrollbalkens
         *
         * @type {Number}
         */
        top: 0,

        /**
         * Die minimale Größe des Skrollthumbs
         *
         * @type {Number}
         */
        minSize: 25
    };

    /**
     * Ein Wert, der angibt, ob horizontale Balken angezeigt werden können
     *
     * @type {Boolean}
     */
    horizontal;

    /**
     * Der aktuelle Zustand der Skrollbar
     */
    scrollerState = {
        /**
         * Der Zustand der horizontalen Skrollbar
         */
        horizontal: {
            /**
             * Gibt an, ob der Thumb gedraggt wird
             *
             * @type {Boolean}
             */
            isDragging: false,

            /**
             * Gibt die Breite (inklusive des nicht sichbaren) Scrollcontainers zurück
             *
             * @type {Number}
             */
            lastScrollSize: 0,

            /**
             * Gibt die aktuelle Translationsposition des proprietären Scrollthumbs zurück
             *
             * @type {Number}
             */
            thumbPosition: 0,

            /**
             * Gibt die letzte Translationsposition des proprietären Scrollthumbs
             * seit dem letzen MouseDown-Ereignis zurück
             *
             * @type {Number}
             */
            lastThumbPosition: 0,

            /**
             * Gibt die letzte Scrollposition des nativen Scrollthumbs zurück
             *
             * @type {Number}
             */
            lastNativeScrollPos: 0,

            /**
             * Liefert die absolute Mauskoordinate des Mauszeigers
             *
             * @type {Number}
             */
            pointerPos: 0,

            /**
             * Der gewünschte Versatz des Thumbs
             *
             * @type {Number}
             */
            left: 0,

            /**
             * Die Breite des Scrollthumbs
             *
             * @type {Number}
             */
            thumbWidth: 0,

            /**
             * Gibt an, ob in die Richtung geskrollt werden kann
             *
             * @type {Boolean}
             */
            scrollable: false
        },

        /**
         * Der Zustand der vertikalen Skrollbar
         */
        vertical: {
            direction: "down",

            /**
             * Gibt an, ob der Thumb gedraggt wird
             *
             * @type {Boolean}
             */
            isDragging: false,

            /**
             * Gibt die Höhe (inklusive des nicht sichbaren) Scrollcontainers zurück
             *
             * @type {Number}
             */
            lastScrollSize: 0,

            /**
             * Gibt die aktuelle Translationsposition des proprietären Scrollthumbs zurück
             *
             * @type {Number}
             */
            thumbPosition: 0,

            /**
             * Gibt die letzte Translationsposition des proprietären Scrollthumbs
             * seit dem letzen MouseDown-Ereeignisses zurück
             *
             * @type {Number}
             */
            lastThumbPosition: 0,

            /**
             * Gibt die letzte Scrollposition des nativen Scrollthumbs zurück
             *
             * @type {Number}
             */
            lastNativeScrollPos: 0,

            /**
             * Liefert die absolute Mauskoordinate des Mauszeigers
             *
             * @type {Number}
             */
            pointerPos: 0,

            /**
             * Der gewünschte Versatz des Thumbs
             *
             * @type {Number}
             */
            top: 0,

            /**
             * Die Höhe des Scrollthumbs
             *
             * @type {Number}
             */
            thumbHeight: 0,

            /**
             * Gibt an, ob in die Richtung geskrollt werden kann
             *
             * @type {Boolean}
             */
            scrollable: false,

            /**
             * Liefert Informationen zum Touch-Event
             */
            touch: {
                /**
                 * Gibt die Startzeit des Touch-Events zurück
                 */
                startTime: 0,

                /**
                 * Gibt die Startposition des Touch-Events zurück
                 */
                offsetY: 0,

                /**
                 * Gibt die Richtung des Skrollens unter Berücksichtigung des Touch-Events zurück
                 */
                direction: undefined
            }
        },

        /**
         * Die Breite des sichtbaren Skrollbereichs
         */
        width: 0,

        /**
         * Die Höhe des sichtbaren Skrollbereichs
         */
        height: 0,

        /**
         * Die aktuelle Seite wenn das Scrollverhalten auf "page" gesetzt ist
         */
        page: 0,

        /**
         * Die Anzahl der Seiten, wenn das Scrollverhalten auf "page" gesetzt ist
         */
        pages: -1,

        /**
         * Die Distanz ziwschen den Hilfsbloöcken zur Seitennavigation in der Scrollbar,
         * wenn eine Seitennavigation eingesteckt ist
         */
        gap: 5
    }

    /**
     * Das Scrollverhalten der proprietären Skrollbar
     *
     * @type {"page" | "normal"}
     */
    scroll = "normal"

    /**
     * Die Instanz des {@linkcode ScrollerResizeWatcher}
     *
     * @type {ScrollerResizeWatcher|null}
     */
    scrollerResizeWatcher = null;

    /**
     * C'tor
     *
     * @param {{element: HTMLElement, width?: String, height?: String, scroll?: "page" | "normal", left?: String, top?: String, cssClasses?: String, gap?: string, horizontal?: Boolean, bars?: {left?: Number, top?: Number, minSize?: Number}, disableScrollOnInputFocus?: boolean}} options
     */
    constructor({element, width = "100%", height = "100%", scroll = "normal", left, top, horizontal = true, cssClasses = "", gap = 5, bars = { left: 0, top: 0, minSize: 25 }, disableScrollOnInputFocus = false}) {
        if (!element)
            return;

        if (!document.body.contains(element))
            return;

        this.disableScrollOnInputFocus = disableScrollOnInputFocus;
        this.horizontal = horizontal;
        this.element = element;
        this.element.tabIndex = 0;
        this.element.style.outline = "none";
        this.bars = bars;
        this.scroll = scroll;
        this.isIFrame = this.element.tagName.toLowerCase() === "iframe";
        this.scrollerState.gap = gap;

        this.scrollerState.vertical.top = bars.top || 0;
        this.scrollerState.vertical.thumbHeight = bars.minSize || 25;

        this.scrollerState.horizontal.left = bars.left || 0;
        this.scrollerState.horizontal.thumbWidth = bars.minSize || 25;

        setupBrowser({ element, scroll });

        this.elements = attachNodes.call(this, {
            element,
            cssClasses,
            width,
            height,
            top,
            left,
            scroll
        });

        updateThumbSizes.call(this);
        attachEvents.call(this);

        this.scrollerResizeWatcher = new ScrollerResizeWatcher(this);
        this.scrollerResizeWatcher.watch();

        setTimeout(() => this.callbacks.mounted(this), 1);
    }

    /**
     * Speichert eine Callback-{@linkcode Function}, um diese nach erfolgreichem einhängen der proprietären Skrollbar
     * aufzurufen.
     *
     * @param {Function} callback
     *
     * @returns {Scroller}
     */
    mounted(callback) {
        this.callbacks.mounted = callback;

        return this;
    }

    /**
     * Speichert eine Callback-{@linkcode Function}, um diese nach Veränderung der Größe des Inhaltsbereichs der proprietären Skrollbar
     * aufzurufen.
     *
     * @param {Function} callback
     *
     * @returns {Scroller}
     */
    resize(callback) {
        this.callbacks.resize = callback;

        return this;
    }

    /**
     * Pauserit das Scrollverhalten
     *
     * @returns {Scroller}
     */
    pause() {
        this.paused = true;


        this.element.style.overflow = "hidden";

        if (!isIOS)
            return this;

        this.element.style.WebkitOverflowScrolling = "hidden";

        return this;
    }

    /**
     * Setzt das Scrollverhalten fort
     *
     * @returns {Scroller}
     */
    resume() {
        this.paused = false;

        if (this.scroll === "page")
            return this;

        this.element.style.overflow = "scroll";


        if (!isIOS)
            return this;

        this.element.style.WebkitOverflowScrolling = "touch";

        return this;
    }

    /**
     * Speichert eine Callback-{@linkcode Function}, um diese nach Verschiebung des Skrollinhalts auf der horizontalen Achse
     * aufzurufen.
     *
     * @param {Function} callback
     */
    onScrollHorizontal(callback) {
        this.callbacks.onScrollHorizontal.push(callback);

        return this;
    }

    /**
     * Speichert eine Callback-{@linkcode Function}, um diese nach Verschiebung des Skrollinhalts auf der vertikalen Achse
     * aufzurufen.
     *
     * @param {(top: number, page: number)} callback
     *
     * @returns {Scroller}
     */
    onScrollVertical(callback) {
        this.callbacks.onScrollVertical.push(callback);

        return this;
    }

    /**
     * Speichert eine Callback-{@linkcode Function}, um diese nach erreichen des unteren Skrollbereichs der vertikalen Achse
     * aufzurufen.
     *
     * @param {{distance?: String|Number, throttle?: Number }} options
     * @param {Function} callback
     *
     * @returns {Scroller}
     */
    onBottom({distance = 20, throttle = 80} = {}, callback = () => {}) {
        const rxPercentage = new RegExp("([0-9]*)(%)");
        const isPercent = typeof distance === "string"
            ? rxPercentage.test(distance)
            : false;

        distance = !isPercent
            ? parseInt(distance)
            : parseInt(distance) / 100;

        this.config.onBottom = {
            isPercent,
            distance,
            throttle,
            callback,
            lastPosition: !this.isIFrame
                ? this.element.scrollTop
                : this.this.element.contentDocument.body.scrollTop
        };

        registerOnBottom.attach.call(this);

        return this;
    }

    /**
     * Speichert einer Callback-{@linkcode Function}, um diese aufzurufen, sobald sich die Möglichkeit zum skrollen
     * geändert hat (bspw. Fenstergröße wurde derart verändert, dass kein skrollen mehr notwendig ist)
     *
     * @param {Function} callback
     *
     * @returns {Scroller}
     */
    state(callback) {
        this.callbacks.state = callback;

        return this;
    }

    /**
     * Liefert die vertikale oder horizontale Skrollposition der proprietären Skrollbar
     *
     * @param {"vertical"|"horizontal"} direction
     *
     * @returns {Number}
     */
    scrollPosition(direction = "vertical") {
        const axis = {
            vertical: "scrollTop",
            horizontal: "scrollLeft"
        }[direction];

        if (!axis)
            return 0;

        return this.element[axis];
    }

    /**
     * Skrollt den Inhaltsbereich auf der vertikalen Achse
     *
     * @param {Number?} top
     * @param {Boolen?} smooth
     *
     * @returns {Scroller}
     */
    scrollVertical(top = 0, smooth = false) {
        smooth
            ? this.element.scroll({ top: top, behavior: "smooth" })
            : this.element.scroll({ top: top });

        return this;
    }

    /**
     * Skrollt den Inhaltsbereich auf der horizontalen Achse
     *
     * @param {Number?} left
     * @param {Boolen?} smooth
     *
     * @returns {Scroller}
     */
    scrollHorizontal(left = 0, smooth = false) {
        smooth
            ? this.element.scroll({ left: left, behavior: "smooth" })
            : this.element.scroll({ left: left });

        return this;
    }

    /**
     * Skrollt zu einem Element unter Berücksichtigung eines übergebenen Versatzes auf der vertikalen Achse
     *
     * @param {HTMLElement} element
     * @param {Number?} offset
     * @param {Boolean?} smooth
     *
     * @returns {Scroller}
     */
    scrollTo(element, offset = 0, smooth = false) {
        if (!element)
            return this;

        this.scrollVertical(element.offsetTop - offset, smooth);

        return this;
    }

    /**
     * Wenn der das Skrollverhalten auf "page" gesetzt ist, wird der Inhaltsbereich
     * zu der angegebenen Seite geskrollt
     *
     * @param {number} page Die Seite, zu der geskrollt werden soll
     */
    scrollToPage(page) {
        this.scrollerState.page = page;

        this.element.scrollTo({
            top: (this.scrollerState.page * (this.element.scrollHeight - this.element.getBoundingClientRect().height)) / (this.scrollerState.pages - 1),
            behavior: "instant"
        });

        return this;
    }

    /**
     * Liefert die Höhe des sichtbaren Skrollbereichs
     *
     * @returns {Number}
     */
    width() {
        return this.element.clientWidth;
    }

    /**
     * Liefert die Breite des sichtbaren Skrollbereichs
     *
     * @returns {Number}
     */
    height() {
        return this.element.clientHeight;
    }

    /**
     * Liefert die Höhe inkklusive des nich sichtbaren Bereich des skrollbaren Inhalts
     *
     * @returns {Number}
     */
    scrollHeight() {
        return this.element.scrollHeight;
    }

    /**
     * Prüft, ob der Inhaltsbereich skrollbar ist
     *
     * @param {"vertical"|"horizontal"} direction
     *
     * @returns {Boolean}
     */
    isScrollable(direction) {
        if (!this.isIFrame)
            return direction === "vertical"
                ? this.element.scrollHeight > this.element.clientHeight
                : this.element.scrollWidth > this.element.clientWidth && this.horizontal;

        return direction === "vertical"
            ? this.element.contentDocument.body.scrollHeight > this.element.clientHeight
            : this.element.contentDocument.body.scrollWidth > this.element.clientWidth && this.horizontal;
    }

    /**
     * Zeigt oder blendet Skrollthumbs in Abhängigkeit des sichbaren Inhalts an bzw. aus
     *
     * @returns {Scroller}
     */
    recalculate() {
        !this.isScrollable("vertical")
            ? $(this.scrollContainer).removeClass("scrollcontainer--no-vert")
            : $(this.scrollContainer).addClass("scrollcontainer--no-vert");

        !this.isScrollable("horizontal")
            ? $(this.scrollContainer).removeClass("scrollcontainer--no-hoz")
            : $(this.scrollContainer).addClass("scrollcontainer--no-hoz");

        return this;
    }

    /**
     * Entfernt die proprietäre Skrollbar
     */
    destroy() {
        detachEvents.call(this);
        detachNodes.call(this);
        this.scrollerResizeWatcher && this.scrollerResizeWatcher.destroy();

        this.scrollerResizeWatcher = null;
        this.callbacks.onScrollVertical.length = 0;
        this.callbacks.onScrollHorizontal.length = 0;
    }
}

export default Scroller;
