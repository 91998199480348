
/**
 * Ein Dekorator, der sicherstellt, dass eine Methode nur einmal ausgeführt wird.
 *
 * @param target Das auslösende Objekt.
 * @param propertyKey Der Name der Methode.
 * @param descriptor Die Beschreibung der Methode.
 *
 * @returns Der zurückgegebene Wert der ausgeführten Methode.
 */
const once = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const method = descriptor.value;
    const key = Symbol();

    descriptor.value = function (...args: unknown[]) {
        if (this.hasOwnProperty(key))
            return;

        Object.defineProperty(this, key, { value: true, writable: true });

        return method.apply(this, args);
    };

    return descriptor;
}

// Wir erstellen eine Map für die Symbole
const stateMap = new WeakMap<object, Map<symbol, boolean>>();

/**
 * Ein Dekorator zur einmaligen Ausführung einer Methode pro Instanz und der Möglichkeit, den Status zurückzusetzen.
 *
 * @example
 *
 * // Interface für TypeScript
 * interface MyClass {
 *   resetGreet(): void;
 * }
 *
 * // Beispielklasse
 * class MyClass {
 *   constructor(private name: string) {}
 *
 *   \@resettableOnce
 *   greet() {
 *       console.log(`Hello, ${this.name}!`);
 *   }
 * }
 *
 * const myClass = new MyClass("World");
 * myClass.greet(); // Hello, World!
 * myClass.greet(); // (keine Ausgabe)
 * myClass.resetGreet();
 * myClass.greet(); // Hello, World!
 * myClass.greet(); // (keine Ausgabe)
 *
 * @param target Der Zielobjekt
 * @param propertyKey Der Schlüssel der Eigenschaft
 * @param descriptor Die Beschreibung der Eigenschaft
 *
 * @returns Der zurückgegebene Wert der ausgeführten Methode
 */
function resettableOnce(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;
    const stateKey = Symbol(propertyKey);

    descriptor.value = function(this: any, ...args: unknown[]) {
        // Initialisiere die Map für diese Instanz, falls noch nicht vorhanden
        if (!stateMap.has(this))
            stateMap.set(this, new Map());

        const instanceMap = stateMap.get(this)!;

        // Prüfe, ob die Methode bereits ausgeführt wurde
        if (instanceMap.get(stateKey))
            return;

        // Markiere die Methode als ausgeführt
        instanceMap.set(stateKey, true);

        return method.apply(this, args);
    };

    // Füge die Reset-Methode hinzu
    const resetMethodName = `reset${propertyKey.charAt(0).toUpperCase() + propertyKey.slice(1)}`;

    target[resetMethodName] = function(this: any) {
        const instanceMap = stateMap.get(this);

        if (!instanceMap || !instanceMap.get(stateKey))
            return;

        // Setze den Status zurück
        instanceMap.delete(stateKey);
    };

    return descriptor;
}

export {
    once,
    resettableOnce
};
