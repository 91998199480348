import type MacroTool from "./MacroTool"
import type SelectionTool from "./SelectionTool"
import DomTool from "./DomTool"

/**
 * Kontexttyp für den Fall, dass sich der Anker- und Fokusknoten nicht in einem Listenelement befinden
 */
type NotInListContext = {
    type: "not-in-list"
}

/**
 * Kontexttyp für den Fall, dass sich der Anker- und Fokusknoten im selben Listenelement befinden
 */
type SingleListItemContext = {
    type: "single-listitem"
    listItem: HTMLElement  // Hier garantiert ein HTMLElement, nicht null
    anchorNode: Node,
    anchorOffset: number,
    focusNode: Node,
    focusOffset: number
}

/**
 * Kontexttyp für den Fall, dass sich der Anker- und Fokusknoten in verschiedenen Listenelementen befinden
 */
type MultipleListItemsContext = {
    type: "multiple-listitems"
    anchorListItem: HTMLElement  // Hier garantiert ein HTMLElement
    focusListItem: HTMLElement  // Hier garantiert ein HTMLElement
    anchorNode: Node,
    anchorOffset: number,
    focusNode: Node,
    focusOffset: number
}

/**
 * Kontexttyp für den Fall, dass sich der Anker- und Fokusknoten in einem Listenelement
 * und ausserhalb der Wurzel-Liste befinden
 */
type MixedSelectionContext = {
    type: "mixed-selection"
    listItem: HTMLElement  // Hier auch garantiert ein HTMLElement
    anchorNode: Node,
    anchorOffset: number,
    focusNode: Node,
    focusOffset: number
}

/**
 * Ein Type zur Beschreibung der Auswahl im Kontext einer Liste.
 */
type ListContext = NotInListContext | SingleListItemContext | MultipleListItemsContext | MixedSelectionContext;

/**
 * Ein Klasse mit Hilfsmethoden für Listenoperationen.
 *
 * @param root Das Wurzelelement
 * @param domTool Das DomTool-Objekt
 * @param selectionTool Das SelectionTool-Objekt
 */
export default class ListTool {
    constructor(
        private readonly root: HTMLElement,
        private readonly domTool: DomTool,
        private readonly macroTool: MacroTool,
        private readonly selectionTool: SelectionTool,
    ) {}

    /**
     * Korrigiert die Liste, in der sich der übergebene Knoten befindet.
     *
     * @param list Die Liste
     */
    public adjustList(list: Element) {
        const selection = getSelection();
        if (!selection || selection.rangeCount === 0) return;

        const range = selection.getRangeAt(0);

        const { anchorNode, anchorOffset, focusNode, focusOffset } = selection;
        if (!anchorNode || !focusNode) return;

        const commonAncestorContainer = range.commonAncestorContainer;

        const isBackward = this.selectionTool.getSelectionDirection({
            anchorNode,
            anchorOffset,
            focusNode,
            focusOffset,
            commonAncestorContainer
        }) === "RIGHT_TO_LEFT";

        const rootList = this.listOf(list, true);
        const emptyListItems = Array
            .from(rootList?.querySelectorAll("li") ?? [])
            .filter(listItem => this.domTool.isEmptyListItem(listItem));

        let listItemOfList = this.listItemOf(list);
        let firstChild: Node | undefined | null = Array
            .from(listItemOfList?.childNodes ?? [])
            .filter(node => {
                if (node.nodeType !== Node.TEXT_NODE && node.nodeType !== Node.ELEMENT_NODE)
                    return false;

                if (node.nodeType !== Node.TEXT_NODE)
                    return true;

                return this.domTool.isEmptyNode(node);
            }).at(0) ?? null;

        while (firstChild && firstChild === list && this.root?.contains(firstChild)) {
            listItemOfList?.parentElement?.insertBefore(list, listItemOfList);

            const listOfList = this.listOf(list);

            if (listOfList?.firstChild === list)
                list = listOfList;

            listItemOfList = this.listItemOf(list);
            firstChild = listItemOfList?.firstChild;
        }

        // Neu entstandene leere Listenelemente entfernen
        Array.from(rootList?.querySelectorAll("li") ?? [])
            .filter(listItem => this.domTool.isEmptyListItem(listItem) && !emptyListItems.includes(listItem))
            .forEach((listItem: Element) => listItem.remove());

        range.setStart(anchorNode, anchorOffset);
        range.setEnd(focusNode, focusOffset);

        selection.removeAllRanges();
        selection.addRange(range);

        if (!isBackward) return;

        selection.collapseToEnd();
        selection.extend(focusNode, focusOffset);
    }

    /**
     * Entfernt leere Listen
     *
     * @param element Das Wurzelelement, ab dem die leeren Listen entfernt werden sollen
     */
    public clearList(element: Element | null) {
        if (!element)
            return;

        Array
            .from(element.querySelectorAll("br"))
            .forEach(br => br.classList.remove("listitem-br-custom"));

        // Alle durch Einrückung/Ausrückung entstandenen leeren Listenelemente filtern
        const emptyListListems = Array
            .from(element.querySelectorAll("li:not(.empty)"))
            .filter(listItem => this.domTool.isEmptyListItem(listItem))

        // Alle durch Einrückung/Ausrückung entstandenen leeren Listen filtern
        const emptyLists = Array
            .from(element.querySelectorAll("ul, ol"))
            .filter(list => this.domTool.isEmptyListItem(list));

        const hasEmptyListItems = emptyListListems.length > 0;
        const hasEmptyLists = emptyLists.length > 0;

        // Alle durch Einrückung/Ausrückung entstandenen leeren Listenelemente entfernen
        emptyListListems
            .forEach(listItem => listItem.remove());

        // Alle durch Einrückung/Ausrückung entstandenen leeren Listen entfernen
        emptyLists
            .forEach(list => {
                let nextNode = list.nextSibling;

                while (nextNode?.nodeType === Node.TEXT_NODE && this.domTool.isEmptyNode(nextNode))
                    nextNode = nextNode.nextSibling;

                if (nextNode && !["UL", "OL", "LI"].includes(nextNode?.nodeName ?? ""))
                    list.parentElement?.insertBefore(document.createElement("br"), list);

                list.remove();
            });

        // Css-Klassenmarker 'empty' aller leeren Listenelemente entfernen, die nicht durch Einrückung/Ausrückung entstanden sind
        if (!hasEmptyListItems && !hasEmptyLists)
            return Array
                .from(element.querySelectorAll("li.empty"))
                .forEach(listItem => listItem.classList.remove("empty"));

        this.clearList(element);
    }

    /**
     * Kombiniert Listenelemente, die zusammengehören
     *
     * @param startNode Der Startknoten
     * @param moveListItem Das zu verschiebende Listenelement
     * @param indent Der Einrückungsstatus
     */
    public combineList(startNode: ChildNode | Element | null, moveListItem: ChildNode | null, indent = true) {
        if (!startNode) return;
        if (!this.root) return;

        const rootLists = Array.from(this.root.querySelectorAll(":scope > ul, :scope > ol"));

        const isMergeable = (node: ChildNode | null): node is Element => {
            if (!node)
                return false;

            if (rootLists.includes(node as Element) && !(node as Element).classList.contains("list-root-0"))
                return false;

            if (!["UL", "OL", "LI"].includes(node.nodeName))
                return false;

            if (indent && moveListItem && node === moveListItem)
                return true;

            const element = node as Element;

            let previousNode = element.previousSibling;

            while (previousNode?.nodeType === Node.TEXT_NODE && this.domTool.isEmptyNode(previousNode))
                previousNode = previousNode.previousSibling;

            if (!previousNode)
                return false;

            if (previousNode.nodeName !== element.nodeName)
                return false;

            if (previousNode.nodeName === element.nodeName && ["UL", "OL"].includes(element.nodeName))
                return true;

            const previousElement = previousNode as Element;

            const previsousClassName = Array
                .from(previousElement.classList)
                .find(className => className.includes("listitem-") || className.includes("list-"));

            if (!previsousClassName)
                return true;

            const commonClassName = Array
                .from(element.classList)
                .find(className => className.includes("listitem-") || className.includes("list-"));

            if (!commonClassName)
                return true;

            if (element.classList.contains("listitem-mergeable") || element.querySelector(".listitem-mergeable"))
                return true;

            return previousElement.classList.contains(commonClassName);
        };

        const fragment = document.createDocumentFragment();

        while (isMergeable(startNode)) {
            let previousElement = (startNode as Element).previousSibling;

            while (previousElement?.nodeType === Node.TEXT_NODE && this.domTool.isEmptyNode(previousElement))
                previousElement = previousElement.previousSibling;

            if (!previousElement)
                break;

            if (previousElement.lastChild?.nodeType === Node.TEXT_NODE || ["UL", "Ol"].includes(previousElement.lastChild?.nodeName ?? "")) {
                let firstBr = startNode.firstChild;

                while (firstBr && firstBr.nodeType === Node.TEXT_NODE && this.domTool.isEmptyNode(firstBr))
                    firstBr = firstBr.nextSibling;

                if (firstBr && firstBr.nodeName === "BR" && (firstBr as Element).classList.contains("listitem-br-custom"))
                    firstBr.parentElement?.insertBefore(document.createElement("br"), firstBr);
            }

            Array.from(startNode.childNodes).forEach(node => fragment.appendChild(node));
            const firstChild = fragment.firstChild;
            previousElement.appendChild(fragment);

            const currentNode = startNode;

            startNode = startNode.nextSibling;

            currentNode.remove();

            this.combineList(firstChild, moveListItem, indent);
        }
    }

    /**
     * Erstellt ein neues Listenelement aus dem Inhalt ab der Cursor-Position
     */
    private createNewListItemFromContent(selection: Selection, listItem: HTMLElement, listOfNode: Element): void {
        const range = selection.getRangeAt(0);
        const { start } = this.macroTool.rangeInMacro(range);
        const newListItem = document.createElement("li");

        // Range anpassen und Inhalt extrahieren
        if (start.startOfMacro) range.setStartBefore(start.macro!);

        range.setEndAfter(listItem.lastChild!);
        const fragment = range.extractContents();
        newListItem.appendChild(fragment);

        // Neues Listenelement einfügen
        listOfNode.insertBefore(newListItem, listItem.nextSibling);

        // Selektion setzen
        if (this.domTool.isEmptyListItem(newListItem, false)) {
            const lineBreak = document.createElement("br");
            newListItem.appendChild(lineBreak);
            this.setSelectionAfter(selection, lineBreak);

            return;
        }

        const { node, offset } = this.domTool.firstChildAndOffset(newListItem);
        if (node) this.setSelectionAt(selection, node, offset);
    }

    /**
     * Hängt Hilfsknoten an den Anfang und das Ende des übergebenen Bereichs an.
     *
     * @param range Der Bereich
     *
     * @returns Die Hilfsknoten
     */
    private createStartEndMarker(range: Range) {
        const startRange = range.cloneRange();
        startRange.setEnd(startRange.startContainer, startRange.startOffset);

        const startMarker = document.createElement("span");
        startRange.insertNode(startMarker);

        const endRange = range.cloneRange();
        endRange.setStart(endRange.endContainer, endRange.endOffset);

        const endMarker = document.createElement("span");
        endRange.insertNode(endMarker);

        return { startMarker, endMarker };
    }

    /**
     * Erzeugt eine flache Liste von Knoten aus einer Liste oder einem Listenelement
     *
     * @param node Der Startknoten
     *
     * @returns Die flache Liste von Knoten
     */
    private flatList(node: Node) {
        const items: Node[][] = [];

        let startNode: Node | null = node;

        while (startNode) {
            if (["UL", "OL", "LI"].includes(startNode.nodeName))
                items.push(...this.flatList(startNode.firstChild!));
            else {
                if (items.length === 0)
                    items.push([]);

                items.at(-1)?.push(startNode);
            }

            startNode = startNode.nextSibling;
        }

        return items;
    }

    /**
     * Ermittelt den Kontext für die Listenbearbeitung einer Auswahl
     *
     * @param selection Die aktuelle Auswahl
     *
     * @returns Der Kontext für die Listenbearbeitung einer Auswahl
     */
    public getSelectionListContext(selection: Selection): ListContext {
        if (!selection || !selection.anchorNode || !selection.focusNode) return { type: "not-in-list" };

        const { node: anchorNode, offset: anchorOffset } = this.domTool.getTargetAndOffset(selection.anchorNode, selection.anchorOffset, [".macro"]);
        const { node: focusNode, offset: focusOffset } = this.domTool.getTargetAndOffset(selection.focusNode, selection.focusOffset, [".macro"]);

        if (!anchorNode || !focusNode) return { type: "not-in-list" }

        const anchorListItem = this.listItemOf(anchorNode);
        const focusListItem = this.listItemOf(focusNode);

        // Keiner der beiden Knoten in einer Liste
        if (!anchorListItem && !focusListItem)
            return { type: "not-in-list" };

        // Beide Knoten im selben Listenelement
        if (anchorListItem && focusListItem && anchorListItem === focusListItem) {
            return {
                type: "single-listitem",
                listItem: anchorListItem,
                anchorNode,
                anchorOffset,
                focusNode,
                focusOffset
            };
        }

        // Beide Knoten in verschiedenen Listenelementen
        if (anchorListItem && focusListItem && anchorListItem !== focusListItem) {
            return {
                type: "multiple-listitems",
                anchorListItem,
                focusListItem,
                anchorNode,
                anchorOffset,
                focusNode,
                focusOffset
            };
        }

        // Ein Knoten in Liste, der andere nicht
        return {
            type: "mixed-selection",
            listItem: (anchorListItem ?? focusListItem) as HTMLElement,
            anchorNode,
            anchorOffset,
            focusNode,
            focusOffset
        };
    }

    /**
     * Behandelt das Drücken der Backspace-Taste am Anfang eines Listenelements.
     * Dabei werden folgende Fälle berücksichtigt:
     *
     * 1) Ist das Listenelement leer, dann wird das Listenelement entfernt
     * 2) Ist das Listenelement nicht leer, dann werden alle Kindknoten bis zum ersten Vorkommen einer Liste aus dem Listenelement rausgeholt und davor eingefügt
     *
     * @param event
     */
    public handleBackspaceAtStartOfListitem(event: KeyboardEvent) {
        const selection = getSelection();
        if (!selection || selection.rangeCount === 0) return;

        const range = selection.getRangeAt(0);
        if (!range) return;

        const listItem = this.listItemOf(range.startContainer);
        if (!listItem) return;

        // Ist das Listenelement leer, dann wird das Listenelement entfernt und die Selektion angepasst
        if (this.domTool.isEmptyListItem(listItem)) return this.removeListItem(listItem, event);

        // Ist das Listenelement nicht leer, dann werden alle Kindknoten bis zum ersten Vorkommen einer Liste aus dem Listenelement rausgeholt und davor eingefügt
        this.prependListContent(listItem, event);
    }

    /**
     * Verarbeitet ein leeres Listenelement
     */
    private handleEmptyListItem(selection: Selection, listItem: HTMLElement, listOfNode: Element, childNodes: Node[]): void {
        const isLastListItem = childNodes.at(-1) === listItem;
        if (!isLastListItem) return;

        const parentList = this.listOf(listOfNode.parentElement);
        const isOutdentable = parentList !== null;

        if (isOutdentable) {
            // Listenelement ausrücken
            const lineBreak = listItem.querySelector("br") ?? document.createElement("br");
            if (!listItem.querySelector("br")) listItem.appendChild(lineBreak);

            parentList.appendChild(listItem);
            this.setSelectionAfter(selection, lineBreak);

            return;
        }

        // Listenelement entfernen
        listItem.remove();
        this.setSelectionAfter(selection, listOfNode);
    }

    /**
     * Veranstaltet das Drücken der Enter-Taste bei einer Auswahl über mehrere Listenelemente hinweg
     *
     * @param selection Die aktuelle Auswahl
     */
    public handleEnterOverMultipleListItems(selection: Selection) {
        if (!selection.anchorNode || !selection.focusNode) return;

        const { node: anchorNode } = this.domTool.getTargetAndOffset(selection.anchorNode, selection.anchorOffset);
        const { node: focusNode } = this.domTool.getTargetAndOffset(selection.focusNode, selection.focusOffset);

        const listItemOfAnchorNode = this.listItemOf(anchorNode);
        if (!listItemOfAnchorNode) return;

        const listItemOfFocusNode = this.listItemOf(focusNode);
        if (!listItemOfFocusNode) return;

        const range = selection.getRangeAt(0);

        let macroInfo = this.macroTool.rangeInMacro(range);
        if (macroInfo.start.startOfMacro) range.setStartBefore(macroInfo.start.macro!);

        macroInfo = this.macroTool.rangeInMacro(range);
        if (macroInfo.end.endOfMacro) range.setEndAfter(macroInfo.end.macro!);

        range.deleteContents();

        const { node, offset } = this.domTool.firstChildAndOffset(listItemOfFocusNode);

        if (!node) return;

        range.setStart(node, offset);
        range.setEnd(node, offset);

        selection.removeAllRanges();
        selection.addRange(range);
    }


    /**
     * Prüft, ob der Cursor am Anfang eines Listenelements oder am Anfang eines Textknotens steht,
     *
     * @example
     *
     * <ul>
     *     <li><span>Text</span></li>
     *     <li><span>|Text</span></li>
     * </ul>
     *
     * @returns Das Ergebnis, ob der Cursor am Anfang eines Listenelements steht
     */
    public isCaretAtStartOfListItem() {
        const selection = getSelection();
        if (!selection) return false;
        if (selection.rangeCount === 0 || !selection.isCollapsed) return false;

        const range = selection.getRangeAt(0);
        const initialContainer = range.startContainer;
        const initialOffset = range.startOffset;

        if (range.startContainer.nodeName === "LI" && range.startOffset === 0) return true;

        const node = range.startContainer;

        const listItemOfNode = this.listItemOf(node);
        if (!listItemOfNode) return false;

        if (!listItemOfNode)
            return false;

        const span = document.createElement("span");
        span.textContent = '\u200C';

        listItemOfNode.parentElement?.insertBefore(span, listItemOfNode);

        selection.modify("move", "backward", "character");

        const isAtStart = listItemOfNode && !listItemOfNode?.contains(selection.anchorNode);

        selection.collapse(initialContainer, initialOffset);

        span.remove();

        return isAtStart;
    }

    /**
     * Rückt das Listen-Element an der aktuellen Cursor-Position ein.
     */
    public indentListItem() {
        const scrollPosition = this.root.scrollTop;
        const result = this.prepareLevelAdjustment(true);
        if (!result) return;

        const { cursorMarker, listBetween, movedListItem } = result;
        if (!movedListItem) return;

        const listOfMovedListItem = this.listOf(movedListItem);
        const newList = listOfMovedListItem?.nodeName === "OL"
            ? document.createElement("ol")
            : document.createElement("ul");

        const newListItem = movedListItem.cloneNode() as Element;

        if (newListItem.classList.contains("empty"))
            newListItem.classList.remove("empty");

        newListItem.appendChild(newList);
        listOfMovedListItem?.insertBefore(newListItem, movedListItem);
        newList.appendChild(movedListItem);

        this.combineList(listBetween, newListItem);

        const rootList = this.root?.querySelector(".list-root-0") ?? null;

        this.clearList(rootList);
        this.setListStyle(rootList);

        if (!cursorMarker)
            return this.root.scrollTop = scrollPosition;

        const selection = getSelection();
        if (!selection) return this.root.scrollTop = scrollPosition;

        const range = new Range();

        range.setStartAfter(cursorMarker);
        range.setEndAfter(cursorMarker);

        cursorMarker.remove();

        selection.removeAllRanges();
        selection.addRange(range);

        this.root.scrollTop = scrollPosition;
    }

    /**
     * Fügt ein Listenelement unter Berücksichtigung der aktuellen Auswahl hinzu
     *
     * @param selection Die aktuelle Auswahl
     */
    public insertListItem(selection: Selection): void {
    }

    /**
     * Prüft, ob der Anker- oder Fokusknoten in einem Listenelement ist.
     *
     * @param selection Die Auswahl mit dem Anker- und Fokusknoten
     *
     * @returns Das Ergebnis, ob der Anker- oder Fokusknoten in einem Listenelement ist
     */
    public isInListItem(selection: Selection) {
        if (!selection || !selection.anchorNode || !selection.focusNode) return false;

        const { node: anchorNode } = this.domTool.getTargetAndOffset(selection.anchorNode, selection.anchorOffset);
        const { node: focusNode } = this.domTool.getTargetAndOffset(selection.focusNode, selection.focusOffset);

        return this.listItemOf(anchorNode) || this.listItemOf(focusNode);
    }

    /**
     * Liefert das Listenelement, in dem sich der übergebene Knoten befindet.
     *
     * @param node Der zu prüfende Knoten
     *
     * @returns Das Listenelement, in dem sich der Knoten befindet oder null, wenn der Knoten nicht in einem Listenelement ist
     */
    public listItemOf(node: Node) {
        const element = node.nodeType !== Node.ELEMENT_NODE
            ? node.parentElement as Element
            : node as Element;

        return element.closest("LI") as HTMLElement | null;
    }

    /**
     * Liefert die Liste, in der sich der übergebene Knoten befindet.
     *
     * @param node Der zu prüfende Knoten
     * @param rootList Gibt an, ob das Wurzel-Listenelement des Knotens {@link node} im {@link node} zurückgegeben werden soll
     *
     * @returns Das Listenelement, in dem sich der Knoten befindet
     */
    public listOf(node?: Node | null, rootList = false): Element | null {
        if (!node)
            return null;

        return Array
            .from(this.root?.querySelectorAll("ul, ol") ?? [])
            .filter(list => list.contains(node))
            .at(rootList ? 0 : -1) ?? null;
    }

    /**
     * Markiert die Wurzel-Listen des übergebenen Bereichs
     * zur nachträglichen Korrektur der Listenstruktur.
     *
     * @param range Der Bereich, der die Wurzel-Listen markiert
     */
    private markLists(range: Range) {
        const rootListOfStartContainer = this.listOf(range.startContainer, true);
        const rootListOfEndContainer = this.listOf(range.endContainer, true);

        if (rootListOfEndContainer)
            rootListOfEndContainer.classList.add("list-root-0");

        if (rootListOfStartContainer)
            rootListOfStartContainer.classList.add("list-root-0");
    }

    /**
     * Rückt das aktuelle Listenelement aus.
     */
    public outdentListItem() {
        const scrollTop = this.root.scrollTop;
        const result = this.prepareLevelAdjustment(false);
        if (!result) return;

        const { listBetween, movedListItem } = result;
        if (!movedListItem) return;

        const destList = this.listOf(this.listOf(movedListItem)?.parentNode);
        const clonedListItem = movedListItem.cloneNode(true) as Element;

        Array.from(destList?.childNodes ?? []).forEach(node => node.remove());
        destList?.appendChild(clonedListItem);

        this.combineList(listBetween, clonedListItem, false);

        const rootList = this.root?.querySelector(".list-root-0") ?? null;
        const cursorMarker = rootList?.querySelector(".list-cursor-marker");

        this.clearList(rootList);
        this.setListStyle(rootList);

        if (!cursorMarker)
            return this.root.scrollTop = scrollTop;

        const selection = getSelection();

        if (!selection)
            return this.root.scrollTop = scrollTop;

        const range = new Range();

        range.setStartAfter(cursorMarker);
        range.setEndAfter(cursorMarker);

        selection.removeAllRanges();
        selection.addRange(range);

        cursorMarker.remove();

        this.root.scrollTop = scrollTop;
    }

    /**
     * Bereitet die Liste für die (Ein-)Ausrückung vor.
     *
     * @param indent Vorbereitung für Einrückung oder Ausrückung
     */
    public prepareLevelAdjustment(indent: boolean) {
        const selection = getSelection();
        if (!selection || selection.rangeCount === 0) return;

        const range = selection.getRangeAt(0);

        const listItem = this.listItemOf(range.startContainer);
        if (!listItem) return;

        const listOfListItem = this.listOf(listItem);

        if (!listOfListItem) return;

        const rootOfListItem = this.listOf(listItem, true);

        if (!rootOfListItem) return;

        rootOfListItem
            .querySelectorAll("br")
            .forEach(br => {
                const listItem = this.listItemOf(br);

                if (listItem && this.domTool.isEmptyListItem(listItem))
                    return br.remove();

                br.classList.add("listitem-br-custom");
            });

        const cursorMarker = document.createElement("span");
        cursorMarker.textContent = '\u200C';
        cursorMarker.classList.add("list-cursor-marker");

        listItem.insertBefore(cursorMarker, listItem.firstChild);


        // Alle leeren Listenelemente markieren, da nach Einrückung neue leere Listenelemente
        // entstehen können, die entfernt werden müssen.
        rootOfListItem
            .querySelectorAll("li")
            .forEach((listItem, idx) => {
                let firstChild = listItem.firstChild;

                while (firstChild?.nodeType === Node.TEXT_NODE && this.domTool.isEmptyNode(firstChild))
                    firstChild = firstChild.nextSibling;

                if (firstChild && listItem.style.listStyleType === "none" && !["OL", "UL", "BR"].includes(firstChild.nodeName))
                    listItem.insertBefore(document.createElement("br"), firstChild);

                listItem.style.removeProperty("list-style-type");

                listItem.classList.add(`listitem-${idx}`);

                if (!this.domTool.isEmptyListItem(listItem))
                    return listItem.classList.remove("empty");

                listItem.classList.add("empty");
            });

        rootOfListItem.classList.add("list-root-0");
        rootOfListItem
            .querySelectorAll("ul, ol").forEach((list, idx) => list.classList.add(`list-${idx + 1}`));

        range.setStartBefore(rootOfListItem);
        range.setEndBefore(listItem);

        // Extrahiert den Inhalt vor dem aktuellen Listenelement
        const contentsBefore = range.extractContents();

        // Hole alle relevanten Kindknoten von listItem bis zum ersten Vorkommen einer Liste
        const childNodes = this.domTool.filterNodesBeforeNode(listItem.childNodes, listItem.querySelector(":scope > ul, ol, br"));

        const br = listItem.querySelector(":scope > br");

        if (br && !indent) {
            const brMarker = document.createElement("span");
            brMarker.classList.add("listitem-style-none");

            br.parentElement?.insertBefore(brMarker, br);
            br.remove();
        }
        else if (br) {
            let firstChild = listItem.firstChild;

            while (firstChild && (firstChild?.nodeType === Node.TEXT_NODE && this.domTool.isEmptyNode(firstChild) || (firstChild?.nodeType === Node.ELEMENT_NODE && (firstChild as Element).classList.contains("list-cursor-marker"))))
                firstChild = firstChild.nextSibling;

            if (firstChild !== br)
                br.remove();
        }

        range.setEndAfter(childNodes.length > 0 ? childNodes.at(-1)! : listItem);

        // Extrahiert den Inhalt, der eingerückt werden soll
        const contentsBetween = range.extractContents();
        const listBetween = contentsBetween.firstElementChild;

        range.setEndAfter(rootOfListItem);

        // Extrahiert den Inhalt der nach dem einzurückenden Inhalt folgt
        const contentsAfter = range.extractContents();
        const movedListItem = Array.from(contentsBetween.firstElementChild?.querySelectorAll("li") ?? []).at(-1);

        listItem?.classList.add("listitem-mergeable")
        listItem?.querySelectorAll("ol, ul").forEach(element => element.classList.add("listitem-mergeable"));

        range.deleteContents();

        range.insertNode(contentsAfter);
        range.insertNode(contentsBetween);
        range.insertNode(contentsBefore);

        return {
            listBetween,
            cursorMarker,
            movedListItem
        };
    }

    /**
     * Nimmt alle Kindknoten bis zum ersten Vorkommen einer Liste aus dem Listenelement raus und fügt diese vor dem Listenelement ein.
     * Ist das Listenelement leer, dann wird das Listenelement entfernt.
     *
     * @param listItem Das Listenelement
     * @param event Das Tastatur-Ereignis
     */
    public prependListContent(listItem: Element, event: KeyboardEvent) {
        const selection = getSelection();

        if (!selection || selection.rangeCount === 0)
            return;

        const range = selection.getRangeAt(0);

        if (!range)
            return;

        event.preventDefault();
        event.stopPropagation();

        let childNodes = Array.from(listItem.childNodes);
        const indexOfFirstList = childNodes.findIndex(node => node.nodeName === "UL" || node.nodeName === "OL");

        if (indexOfFirstList > -1)
            childNodes = childNodes.slice(0, indexOfFirstList);

        const firstChild = childNodes.at(0);
        const fragment = document.createDocumentFragment();
        fragment.append(...childNodes);

        listItem.parentElement?.insertBefore(fragment, listItem);

        if (["UL", "OL"].includes(listItem.firstChild?.nodeName ?? ""))
            listItem.parentElement?.insertBefore(listItem.firstChild!, listItem);

        const emptyListItem = this.domTool.isEmptyListItem(listItem);

        if (listItem.nextSibling && listItem.nextSibling.nodeName !== "BR" && listItem.nextSibling.nodeName !== "LI" && !this.domTool.isEmptyNode(listItem.nextSibling))
            listItem.parentElement?.insertBefore(document.createElement("br"), listItem.nextSibling);

        if (emptyListItem)
            listItem.remove();

        if (!firstChild)
            return;

        const parentElement = firstChild.parentElement;

        range.setStart(firstChild, 0);
        range.setEnd(firstChild, 0);

        selection.removeAllRanges();
        selection.addRange(range);

        selection.modify("move", "backward", "character");

        const newParentElement = selection.focusNode?.parentElement;

        selection.modify("move", "forward", "character");

        if (newParentElement !== parentElement)
            return;

        firstChild.parentElement?.insertBefore(document.createElement("br"), firstChild);

        range.setStart(firstChild, 0);
        range.setEnd(firstChild, 0);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    /**
     * Entfernt den Inhalt der Auswahl ohne die Listenstruktur zu verändern.
     *
     * @param selection Die Auswahl
     */
    public removeContent(selection: Selection) {
        const listContext = this.getSelectionListContext(selection);

        if (listContext.type === "not-in-list") return;
        if (listContext.type === "single-listitem") return this.removeContentInListItem(listContext);
        if (listContext.type === "multiple-listitems") return this.removeContentInMultipleListItems(listContext);
    }

    /**
     * Entfernt den Inhalt zwischen Anker- und Fokusknoten in einem Listenelement.
     *
     * @param listContext Der Kontext für die Listenbearbeitung einer Auswahl in einem Listenelement
     */
    private removeContentInListItem(listContext: SingleListItemContext) {
        const selection = getSelection();
        if (!selection) return;

        const { anchorNode, anchorOffset, focusNode, focusOffset } = listContext;

        let range: Range | null = new Range();
        range.setStart(anchorNode, anchorOffset);
        range.setEnd(focusNode, focusOffset);

        range = this.macroTool.prepareRangeForManipulation(range);
        if (!range) return;

        const { node, offset } = this.selectionTool.previousFocus(range.startContainer, range.startOffset, this.root);

        range.deleteContents();

        if (!node) return;

        range.setStart(node, offset);
        range.setEnd(node, offset);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    /**
     * Entfernt den Inhalt zwischen Anker- und Fokusknoten wobei sich der Ankerknoten in einem
     * anderen Listenelement als der Fokusknoten befindet.
     *
     * @param listContext Der Kontext für die Listenbearbeitung einer Auswahl über mehrere Listenelemente
     */
    private removeContentInMultipleListItems(listContext: MultipleListItemsContext) {
        const selection = getSelection();
        if (!selection) return;

        const { anchorListItem, focusListItem, anchorNode, anchorOffset, focusNode, focusOffset } = listContext;

        let range: Range | null = new Range();
        range.setStart(anchorNode, anchorOffset);
        range.setEnd(focusNode, focusOffset);

        range = this.macroTool.prepareRangeForManipulation(range);
        if (!range) return;

        const anchorRange = new Range();
        const { node: lastAnchorNode, offset: lastAnchorOffset } = this.domTool.lastChildAndOffset(anchorListItem);
        if (!lastAnchorNode) return;

        anchorRange.setStart(anchorNode, anchorOffset);
        anchorRange.setEnd(lastAnchorNode, lastAnchorOffset);

        const focusRange = new Range();
        const { node: firstFocusNode, offset: firstFocusOffset } = this.domTool.firstChildAndOffset(focusListItem);
        if (!firstFocusNode) return;

        focusRange.setStart(firstFocusNode, firstFocusOffset);
        focusRange.setEnd(focusNode, focusOffset);

        anchorRange.deleteContents();
        focusRange.deleteContents();
    }

    /**
     * Entfernt das übergebene Listenelement und passt die Selektion an.
     *
     * @param listItem Das zu entfernende Listenelement
     * @param event Das Tastatur-Ereignis
     */
    public removeListItem(listItem: Element, event: KeyboardEvent) {
        const selection = getSelection();

        if (!selection || selection.rangeCount === 0)
            return;

        event.preventDefault();
        event.stopPropagation();

        selection.modify("move", "backward", "character");

        listItem.remove();
    }

    /**
     * Prüft, ob die aktuelle Selektion über mehrere Listenelemente geht.
     *
     * @param selection Die aktuelle Selektion
     *
     * @returns Das Ergebnis, ob die Selektion über mehrere Listenelemente geht
     */
    public selectionOverMultipleListItems(selection: Selection): boolean {
        if (!selection.anchorNode || !selection.focusNode) return false;

        const { node: anchorNode } = this.domTool.getTargetAndOffset(selection.anchorNode, selection.anchorOffset);
        const { node: focusNode } = this.domTool.getTargetAndOffset(selection.focusNode, selection.focusOffset);

        const listItemOfAnchorNode = this.listItemOf(anchorNode);
        const listItemOfFocusNode = this.listItemOf(focusNode);

        return listItemOfAnchorNode !== listItemOfFocusNode;
    }

    /**
     * Aktualisiert den Stile der Listenelemente im übergebenen Listenelement.
     *
     * @param list Das Listenelement
     */
    public setListStyle(list: Element | null) {
        if (!list)
            return

        Array
            .from(list.classList).filter(className => className.includes("list-"))
            .forEach(className => list.classList.remove(className));


        list
            .querySelectorAll("li")
            .forEach(listItem => {
                if (listItem.classList.contains("listitem-mergeable"))
                    listItem.classList.remove("listitem-mergeable");

                if (!["UL", "OL"].includes(listItem.firstChild?.nodeName ?? ""))
                    return listItem.style.removeProperty("list-style-type");

                listItem.style.listStyleType = "none";
            });

        Array.from(list.querySelectorAll("ol, ul, li"))
            .forEach(element => {
                if (element.classList.contains("listitem-mergeable"))
                    element.classList.remove("listitem-mergeable");

                if (!element.className.includes("listitem-") && !element.className.includes("list-"))
                    return;

                element.classList.remove(...Array.from(element.classList).filter(className => className.includes("listitem-") || className.includes("list-")));
            });

        const brMarker = list.querySelector(".listitem-style-none");

        if (!brMarker)
            return;

        const lisItemOfBrMarker = this.listItemOf(brMarker);

        if (!lisItemOfBrMarker)
            return;

        (lisItemOfBrMarker as HTMLElement).style.listStyleType = "none";

        brMarker.remove();
    }

    /**
     * Setzt die Selektion nach einem Element
     */
    private setSelectionAfter(selection: Selection, node: Node): void {
        const range = new Range();
        range.setStartAfter(node);
        range.setEndAfter(node);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    /**
     * Setzt die Selektion vor/nach einem Element
     */
    private setSelectionAt(selection: Selection, node: Node, offset: number): void {
        const range = new Range();
        range.setStart(node, offset);
        range.setEnd(node, offset);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    /**
     * Erzeugt eine Liste aus dem übergebenen Bereich.
     *
     * @param type Der Typ der Liste
     */
    public wrapToList(type: "OL" | "UL") {
        if (!this.root) return;

        const selection = getSelection();
        if (!selection || selection.rangeCount === 0) return;

        // Startknoten am Anfang der Zeile holen
        const lineStart = this.selectionTool.getLineStart();
        if (!lineStart) return;
        if (lineStart !== this.root && !this.root.contains(lineStart)) return;

        // Endknoten am Ende der Zeile holen
        const lineEnd = this.selectionTool.getLineEnd();
        if (!lineEnd) return;

        if (lineEnd !== this.root && !this.root.contains(lineEnd)) return;

        const caretRange = selection.getRangeAt(0).cloneRange();
        const range = new Range();

        if (lineStart === this.root) {
            range.setStart(lineStart, 0);
            range.setEnd(lineEnd, 0);
        } else {
            range.setStartBefore(lineStart);
            range.setEndAfter(lineEnd);
        }

        this.markLists(range);

        const { startMarker, endMarker } = this.createStartEndMarker(range);
        const { startMarker: startCaret, endMarker: endCaret } = this.createStartEndMarker(caretRange);

        range.setStartBefore(startMarker);
        range.setEndAfter(endMarker);

        const contents = range.extractContents();
        const list = document.createElement(type);

        const listItemGroups = Array
            .from(contents.childNodes)
            .reduce((listItemGroups, node) => {
                const containsList = ["UL", "OL"].includes(node.nodeName)
                    || (node.nodeType === Node.ELEMENT_NODE && (node as Element).querySelectorAll("li").length > 0);

                if (containsList) {
                    const nodes = this.flatList(node as Element);

                    nodes.forEach(item => listItemGroups.push(item));

                    return listItemGroups;
                }

                if (DomTool.BlockElements.has(node.nodeName)) {
                    listItemGroups.push([node]);

                    return listItemGroups;
                }

                if (listItemGroups.length === 0)
                    listItemGroups.push([node]);

                listItemGroups.at(-1)!.push(node);

                return listItemGroups;
            }, [] as Node[][]);

        const listItems = listItemGroups.map(listItemGroup => {
            const listItem = document.createElement("li");

            listItemGroup.forEach(node => listItem.appendChild(node));

            return listItem;
        });

        if (listItems.length === 0) {
            const listItem = document.createElement("li");
            listItem.classList.add("empty");
            listItems.push(listItem);
            listItem.appendChild(startCaret);
            endCaret.remove();
        }

        const listFragment = document.createDocumentFragment();
        listItems.forEach(listItem => listFragment.appendChild(listItem));

        range.insertNode(list);
        list.appendChild(listFragment);

        this.clearList(list);

        startMarker.remove();
        endMarker.remove();

        range.setStartBefore(startCaret);

        if (this.root.contains(endCaret))
            range.setEndBefore(endCaret);
        else
            range.setEndBefore(startCaret);

        selection.removeAllRanges();
        selection.addRange(range);

        startCaret.remove();

        if (this.root.contains(endCaret))
            endCaret.remove();

        this.root.querySelectorAll(".list-root-0").forEach(list => {
            this.clearList(list);
            this.setListStyle(list);

            list.classList.remove("list-root-0");
        });
    }
}
